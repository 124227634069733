"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PreferredPronouns;

(function (PreferredPronouns) {
  PreferredPronouns["feminine"] = "feminine";
  PreferredPronouns["masculine"] = "masculine";
  PreferredPronouns["neutral"] = "neutral";
})(PreferredPronouns = exports.PreferredPronouns || (exports.PreferredPronouns = {}));