"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

exports.bootstrapApplicationActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: core_1.ActionTypes.BOOTSTRAP_APPLICATION
  });
};

exports.accessTokenExpiredActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: core_1.ActionTypes.ACCESS_TOKEN_EXPIRED
  });
};

exports.submitContactPageMessageActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: core_1.ActionTypes.DYNAMYND_SUBMIT_CONTACT_PAGE_MESSAGE
  });
};

exports.setContactPageMessageStatusActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: core_1.ActionTypes.DYNAMYND_CONTACT_PAGE_MESSAGE_STATUS
  });
};

exports.userIsIdleActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: core_1.ActionTypes.USER_IS_IDLE
  });
};

exports.userIsActiveActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: core_1.ActionTypes.USER_IS_ACTIVE
  });
};