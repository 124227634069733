"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../enums"); /// todo typings


function actionCreatorDefaultMetaType(baseAction) {
  return __assign(__assign({}, baseAction), {
    meta: {
      type: enums_1.ActionMetaTypeEnum.default
    }
  });
}

exports.actionCreatorDefaultMetaType = actionCreatorDefaultMetaType;

function actionCreatorApiWithCredentialsMetaType(baseAction) {
  return __assign(__assign({}, baseAction), {
    meta: {
      type: enums_1.ActionMetaTypeEnum.apiWithCredentials
    }
  });
}

exports.actionCreatorApiWithCredentialsMetaType = actionCreatorApiWithCredentialsMetaType;

function actionCreatorApiWithoutCredentialsMetaType(baseAction) {
  return __assign(__assign({}, baseAction), {
    meta: {
      type: enums_1.ActionMetaTypeEnum.apiWithoutCredentials
    }
  });
}

exports.actionCreatorApiWithoutCredentialsMetaType = actionCreatorApiWithoutCredentialsMetaType;