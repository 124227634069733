"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var reselect_1 = require("reselect");

var services_1 = require("../../services");

var getPageContent = function getPageContent(state) {
  return state.content.pages.content;
};

exports.pageContentSelector = reselect_1.createSelector(getPageContent, function (pageContent) {
  return pageContent;
});

var getAvatars = function getAvatars(state) {
  return state.content.avatars;
};

exports.avatarsRandomizedSelector = reselect_1.createSelector(getAvatars, function (collection) {
  var defaultAvatarKey = services_1.ContentService.defaultAvatarKey;
  var avatars = collection.entities.avatars;
  var defaultAvatar = avatars[defaultAvatarKey] ? __assign({}, avatars[defaultAvatarKey]) : services_1.contentService.getDefaultAvatar();
  var valuesRandomized = utilities_1.shuffle(utilities_1.values(avatars));
  return __spreadArrays([defaultAvatar], valuesRandomized.filter(function (avatar) {
    return avatar.id !== defaultAvatarKey;
  }));
});
exports.avatarsEntitiesSelector = reselect_1.createSelector(getAvatars, function (avatars) {
  return avatars.entities.avatars;
});

var getSharingContent = function getSharingContent(state) {
  return state.content.sharing;
};

exports.sharingContentSelector = reselect_1.createSelector(getSharingContent, function (sharingContent) {
  return sharingContent;
});