"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var Icon_1 = require("../Icon/Icon");

var ProgressBar_1 = require("../ProgressBar/ProgressBar");

var styled_1 = require("./styled");

var AudioPlayerComponent =
/** @class */
function (_super) {
  __extends(AudioPlayerComponent, _super);

  function AudioPlayerComponent() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      percentComplete: 0,
      timeAvailable: false
    };

    _this.formatTime = function (duration) {
      // Hours, minutes and seconds
      var hrs = Math.floor(duration / 3600);
      var mins = Math.floor(duration % 3600 / 60);
      var secs = Math.floor(duration % 60); // Output like "1:01" or "4:03:59" or "123:03:59"

      var ret = '';

      if (hrs > 0) {
        ret += hrs + ":" + (mins < 10 ? '0' : '');
      }

      ret += mins + ":" + (secs < 10 ? '0' : '');
      ret += secs;
      return ret;
    };

    _this.handleMetadataLoaded = function (e) {
      _this.setState({
        duration: _this.player.duration,
        timeAvailable: true
      });
    };

    _this.handleTimeUpdate = function (e) {
      var _a = e.target,
          currentTime = _a.currentTime,
          duration = _a.duration;
      var percentComplete = currentTime / duration * 100;

      _this.setState({
        currentTime: currentTime,
        duration: duration,
        percentComplete: percentComplete
      });
    };

    _this.stop = function () {
      _this.setState({
        isPlaying: false
      });
    };

    _this.togglePlay = function () {
      _this.setState(function (prevState) {
        return {
          isPlaying: !prevState.isPlaying
        };
      });
    };

    _this.hexToRgb = function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    };

    _this.renderPlayButton = function () {
      var isPlaying = _this.state.isPlaying;
      var color = _this.props.color;
      var iconName = isPlaying ? 'pause' : 'play';
      return react_1.default.createElement(styled_1.IconContainer, null, react_1.default.createElement(react_native_1.TouchableOpacity, {
        onPress: _this.togglePlay
      }, react_1.default.createElement(Icon_1.Icon, {
        name: iconName,
        size: Icon_1.IconSizeEnum.medium,
        color: color
      })));
    };

    _this.renderProgressBar = function () {
      var _a = _this.state,
          timeAvailable = _a.timeAvailable,
          currentTime = _a.currentTime,
          duration = _a.duration,
          percentComplete = _a.percentComplete;
      var activeBreakpoint = _this.props.activeBreakpoint;
      return react_1.default.createElement(styled_1.AudioPlayerProgressBarContainer, null, react_1.default.createElement(ProgressBar_1.ProgressBar, {
        activeBreakpoint: activeBreakpoint,
        value: percentComplete,
        backgroundColor: '#FF5348'
      }), timeAvailable && react_1.default.createElement(styled_1.AudioPlayerProgressBarMetaContainer, null, react_1.default.createElement(styled_1.Label, null, _this.formatTime(currentTime)), react_1.default.createElement(styled_1.Label, null, _this.formatTime(duration))));
    };

    _this.handleRef = function (ref) {
      _this.player = ref;
    };

    return _this;
  }

  AudioPlayerComponent.prototype.componentDidMount = function () {
    this.player.addEventListener('timeupdate', this.handleTimeUpdate);
    this.player.addEventListener('loadedmetadata', this.handleMetadataLoaded);
    this.player.addEventListener('ended', this.stop);
  };

  AudioPlayerComponent.prototype.componentDidUpdate = function (prevProps, prevState) {
    var isPlaying = this.state.isPlaying;

    if (prevState.isPlaying !== isPlaying) {
      if (isPlaying) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
  };

  AudioPlayerComponent.prototype.componentWillUnmount = function () {
    this.player.removeEventListener('timeupdate', this.handleTimeUpdate);
    this.player.removeEventListener('loadedmetadata', this.handleMetadataLoaded);
    this.player.removeEventListener('ended', this.stop);
  };

  AudioPlayerComponent.prototype.render = function () {
    var _a = this.props,
        uri = _a.uri,
        color = _a.color;
    var rgbVal = this.hexToRgb(color);
    if (!uri) return null;
    return react_1.default.createElement(styled_1.AudioPlayerOuterContainer, {
      backgroundColor: "rgba(" + (rgbVal === null || rgbVal === void 0 ? void 0 : rgbVal.r) + "," + (rgbVal === null || rgbVal === void 0 ? void 0 : rgbVal.g) + "," + (rgbVal === null || rgbVal === void 0 ? void 0 : rgbVal.b) + ",0.5)"
    }, react_1.default.createElement(styled_1.AudioPlayerContainer, {
      backgroundColor: color
    }, react_1.default.createElement(styled_1.AudioPlayerInnerContainer, null, react_1.default.createElement("audio", {
      ref: this.handleRef,
      src: uri,
      controls: false,
      preload: 'auto'
    }), this.renderPlayButton(), this.renderProgressBar())));
  };

  return AudioPlayerComponent;
}(react_1.PureComponent);

exports.AudioPlayer = responsive_1.withResponsiveMeta(AudioPlayerComponent);