"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ChangeQuestionTypeEnum;

(function (ChangeQuestionTypeEnum) {
  ChangeQuestionTypeEnum["prev"] = "prev";
  ChangeQuestionTypeEnum["next"] = "next";
  ChangeQuestionTypeEnum["score"] = "score";
})(ChangeQuestionTypeEnum = exports.ChangeQuestionTypeEnum || (exports.ChangeQuestionTypeEnum = {}));