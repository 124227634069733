"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Title_1 = require("./Title");

exports.Title = Title_1.default;

var TitleWithMargin_1 = require("./TitleWithMargin");

exports.TitleWithMargin = TitleWithMargin_1.TitleWithMargin;