"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AnalyticsActionTypes;

(function (AnalyticsActionTypes) {
  AnalyticsActionTypes["RECORD_ANALYTICS_DATA"] = "[analytics] record analytics data";
  AnalyticsActionTypes["END_ALL_ACTIVE_ANALYTICS_SESSIONS_ON_USER_IDLE"] = "[analytics] end all active analytics sessions";
  AnalyticsActionTypes["RESUME_ALL_LAST_ACTIVE_ANALYTICS_SESSIONS"] = "[analytics] resume all last active analytics sessions";
})(AnalyticsActionTypes = exports.AnalyticsActionTypes || (exports.AnalyticsActionTypes = {}));