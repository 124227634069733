"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentPagesEnum;

(function (ContentPagesEnum) {
  ContentPagesEnum["terms"] = "terms";
  ContentPagesEnum["privacy"] = "privacy";
  ContentPagesEnum["faq"] = "faq";
  ContentPagesEnum["home"] = "home";
  ContentPagesEnum["payment"] = "payment";
})(ContentPagesEnum = exports.ContentPagesEnum || (exports.ContentPagesEnum = {}));