"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldLabels;

(function (FieldLabels) {
  FieldLabels["avatar"] = "Change Avatar";
  FieldLabels["signInOptions"] = "Sign In Options";
  FieldLabels["email"] = "Email";
  FieldLabels["userName"] = "Username";
  FieldLabels["phone"] = "Phone Number";
  FieldLabels["personalInformation"] = "Personal Information";
  FieldLabels["firstName"] = "First Name";
  FieldLabels["lastName"] = "Last Name";
  FieldLabels["nickName"] = "Nickname";
  FieldLabels["country"] = "Country";
  FieldLabels["zipCode"] = "Zip Code";
  FieldLabels["gender"] = "gender";
  FieldLabels["ethnicity"] = "Ethnicity";
  FieldLabels["specialNeeds"] = "Special Needs";
  FieldLabels["educationalGoal"] = "Educational Goal";
  FieldLabels["dob"] = "DOB";
  FieldLabels["changePassword"] = "Change Password";
  FieldLabels["updateChanges"] = "Update Changes";
  FieldLabels["currentPassword"] = "Current Password";
  FieldLabels["password"] = "Password";
  FieldLabels["confirmPassword"] = "Confirm Password";
})(FieldLabels = exports.FieldLabels || (exports.FieldLabels = {}));