"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.bootstrapAvatarsEpic = function (actions$, state, _a) {
  var contentApiService = _a.contentApiService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.BOOTSTRAP_APPLICATION), operators_1.mergeMap(bootstrapAvatarsEpicHandler(contentApiService)), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var bootstrapAvatarsEpicHandler = function bootstrapAvatarsEpicHandler(contentApiService) {
  return function () {
    return contentApiService.getAvatars().pipe(operators_1.mergeMap(function (response) {
      return rxjs_1.of(content_1.setAvatarActionCreator(mapAvatarsData(response.data)));
    }), operators_1.catchError(core_1.defaultErrorHandler));
  };
};

function mapAvatarsData(data) {
  var keys = Object.keys(data);
  if (!keys.length) return [];
  return keys.reduce(function (accumulator, currentValue) {
    accumulator.push({
      id: currentValue,
      source: data[currentValue]
    });
    return accumulator;
  }, []);
}