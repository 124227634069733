"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SectionNameEnum;

(function (SectionNameEnum) {
  SectionNameEnum["factFinder"] = "Fact Finder";
  SectionNameEnum["followThru"] = "Follow Thru";
  SectionNameEnum["quickStart"] = "Quick Start";
  SectionNameEnum["implementor"] = "Implementor"; // yellow
})(SectionNameEnum = exports.SectionNameEnum || (exports.SectionNameEnum = {}));