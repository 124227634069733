"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var react_native_popup_menu_1 = require("react-native-popup-menu");

var react_native_web_1 = require("react-native-web");

var SlideInMenuTop_1 = require("./SlideInMenuTop");

var styles_1 = require("./styles");

var ContextMenu = react_native_popup_menu_1.renderers.ContextMenu,
    Popover = react_native_popup_menu_1.renderers.Popover,
    SlideInMenu = react_native_popup_menu_1.renderers.SlideInMenu;
var renderers = {
  ContextMenu: ContextMenu,
  Popover: Popover,
  SlideInMenuTop: SlideInMenuTop_1.SlideInMenuTop,
  SlideInMenuBottom: SlideInMenu
};
var RenderersEnum;

(function (RenderersEnum) {
  RenderersEnum["ContextMenu"] = "ContextMenu";
  RenderersEnum["Popover"] = "Popover";
  RenderersEnum["SlideInMenuBottom"] = "SlideInMenu";
  RenderersEnum["SlideInMenuTop"] = "SlideInMenuTop";
})(RenderersEnum || (RenderersEnum = {}));

var Popup =
/** @class */
function (_super) {
  __extends(Popup, _super);

  function Popup(props) {
    var _this = _super.call(this, props) || this;

    _this.onMenuSelect = function (option) {
      var onSelect = _this.props.onSelect;

      if (typeof onSelect === 'function') {
        onSelect(option);
      }
    };

    _this.onSelectOption = function (index) {
      _this.selectedOptionIndex = index;
    };

    _this.renderOptions = function () {
      var options = _this.props.options;
      return options.map(function (_a, idx) {
        var disabled = _a.disabled,
            _onSelect = _a.onSelect,
            render = _a.render;
        return react_1.default.createElement(react_native_popup_menu_1.MenuOption, {
          disabled: disabled,
          onSelect: function onSelect() {
            _onSelect === null || _onSelect === void 0 ? void 0 : _onSelect();

            _this.onSelectOption(idx);
          },
          key: idx
        }, react_1.default.createElement(react_native_web_1.View, {
          testID: _this.menuOptionTestIdPrefix + "-" + idx
        }, render()));
      });
    };

    _this.focusedIndex = 0;
    _this.domElements = null;
    _this.menuRef = null;

    _this.getFirstFocusableElementIndex = function () {
      var index = _this.props.options.findIndex(function (_, i) {
        return i !== _this.selectedOptionIndex;
      }); // for (let i = 0; i < this.props.options.length; i++) {
      //     if (i !== this.selectedOptionIndex) {
      //         return i;
      //     }
      // }


      return index === -1 ? 0 : index;
    };

    _this.onKeyDown = function (event) {
      var incIndex = function incIndex(amount) {
        if (amount === void 0) {
          amount = 1;
        }

        return (_this.focusedIndex + amount) % _this.props.options.length;
      };

      var decIndex = function decIndex(amount) {
        if (amount === void 0) {
          amount = 1;
        }

        return (_this.focusedIndex - amount + _this.props.options.length) % _this.props.options.length;
      };

      switch (event.key) {
        case 'ArrowDown':
          _this.focusedIndex = incIndex();

          if (_this.focusedIndex === _this.selectedOptionIndex) {
            _this.focusedIndex = incIndex();
          }

          _this.focusElementOnKeyDown();

          break;

        case 'ArrowUp':
          _this.focusedIndex = decIndex();

          if (_this.focusedIndex === _this.selectedOptionIndex) {
            _this.focusedIndex = decIndex();
          }

          _this.focusElementOnKeyDown();

          break;

        case 'Escape':
          _this.menuRef.close();

          break;

        case 'Tab':
          setTimeout(function () {
            var _a, _b;

            (_b = (_a = _this.domElements) === null || _a === void 0 ? void 0 : _a[_this.getFirstFocusableElementIndex()]) === null || _b === void 0 ? void 0 : _b.focus({
              focusVisible: true
            });
          }, 100);
          break;

        default:
          break;
      }
    };

    _this.focusElementOnKeyDown = function () {
      var element = _this.domElements[_this.focusedIndex];
      element.focus({
        focusVisible: true
      });
    };

    _this.onOpen = function () {
      if (!_this.menuOptionTestIdPrefix) {
        return;
      }

      setTimeout(function () {
        var elements = _this.props.options.map(function (_, i) {
          var e = document.querySelector("[data-testid=\"" + _this.menuOptionTestIdPrefix + "-" + i + "\"]");
          var focusableNode = e.parentElement.parentElement;
          focusableNode.setAttribute('tabindex', '-1');
          return focusableNode;
        });

        _this.domElements = elements;

        var element = elements[_this.getFirstFocusableElementIndex()]; // @ts-ignore


        element.focus({
          focusVisible: true
        });
        document.addEventListener('keydown', _this.onKeyDown);
      }, 225);
    };

    _this.onClose = function () {
      var _a, _b;

      var element = document.querySelector("[data-testid=\"" + _this.menuName + "\"]");

      if (element) {
        var focusInput = (_b = (_a = element.firstChild) === null || _a === void 0 ? void 0 : _a.firstChild) === null || _b === void 0 ? void 0 : _b.firstChild; // @ts-ignore

        focusInput === null || focusInput === void 0 ? void 0 : focusInput.focus({
          focusVisible: true
        });
      }

      document.removeEventListener('keydown', _this.onKeyDown);
    };

    var generateRandomId = function generateRandomId() {
      return "popup-" + Math.random().toString(36).substring(2, 9);
    }; // Generate random IDs for menuOptionTestIdPrefix and menuName if not provided


    _this.menuOptionTestIdPrefix = props.menuOptionTestIdPrefix || generateRandomId();
    _this.menuName = props.menuName || generateRandomId();
    return _this;
  }

  Popup.prototype.componentWillUnmount = function () {
    document.removeEventListener('keydown', this.onKeyDown);
  };

  Popup.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        rendererProp = _a.renderer,
        renderTrigger = _a.renderTrigger,
        disabled = _a.disabled,
        optionsCustomStyles = _a.optionsCustomStyles,
        triggerCustomStyles = _a.triggerCustomStyles,
        rendererPropsCustom = _a.rendererProps; // @ts-ignore

    var renderer = renderers[rendererProp];
    var triggerStyles = {};
    var rendererProps = {};
    var menuOptions = {};

    if (optionsCustomStyles) {
      menuOptions.customStyles = optionsCustomStyles;
    }

    if (triggerStyles) {
      triggerStyles = __assign(__assign({}, triggerCustomStyles), {
        triggerDefaultStyles: styles_1.triggerDefaultStyles
      });
    }

    if (rendererProps) {
      rendererProps = __assign({}, rendererPropsCustom);
    }

    return react_1.default.createElement(react_native_web_1.View, {
      testID: this.menuName
    }, react_1.default.createElement(react_native_popup_menu_1.Menu, {
      ref: function ref(m) {
        var _a, _b;

        (_b = (_a = _this.props).onMenuRef) === null || _b === void 0 ? void 0 : _b.call(_a, m);
        _this.menuRef = m;
      },
      name: this.menuName,
      onOpen: this.onOpen,
      onClose: this.onClose,
      renderer: renderer,
      onSelect: this.onMenuSelect,
      rendererProps: rendererProps
    }, react_1.default.createElement(react_native_popup_menu_1.MenuTrigger, {
      customStyles: triggerStyles,
      disabled: disabled
    }, renderTrigger()), react_1.default.createElement(react_native_popup_menu_1.MenuOptions, __assign({
      testId: "menu-options"
    }, menuOptions), this.renderOptions())));
  };

  Popup.Renderers = RenderersEnum;
  return Popup;
}(react_1.Component);

exports.Popup = Popup;