"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var routes_1 = require("@dynamynd/routes");

var query_string_1 = require("query-string");

var LocationWebService =
/** @class */
function () {
  function LocationWebService() {
    this.authRoutesWithoutRedirect = [routes_1.RoutesKeysEnum.editUserProfile, routes_1.RoutesKeysEnum.dashboard, routes_1.RoutesKeysEnum.sampleDashboard, routes_1.RoutesKeysEnum.moduleView, routes_1.RoutesKeysEnum.results, routes_1.RoutesKeysEnum.contact, routes_1.RoutesKeysEnum.sharedModule, routes_1.RoutesKeysEnum.sharedResultsModule, routes_1.RoutesKeysEnum.sampleModuleView];
    this.anonymousRoutesWithoutRedirect = [routes_1.RoutesKeysEnum.login, routes_1.RoutesKeysEnum.signup, routes_1.RoutesKeysEnum.contact, routes_1.RoutesKeysEnum.passwordReset, routes_1.RoutesKeysEnum.sharedModule, routes_1.RoutesKeysEnum.sharedResultsModule, routes_1.RoutesKeysEnum.sampleDashboard, routes_1.RoutesKeysEnum.sampleResults, routes_1.RoutesKeysEnum.sampleModuleView];
    this.routesWithCustomAnalytics = [routes_1.RoutesKeysEnum.results, routes_1.RoutesKeysEnum.moduleView, routes_1.RoutesKeysEnum.take];
    this.location = window.location;
  }

  LocationWebService.prototype.getActivePathName = function () {
    var pathname = this.location.pathname;
    var values = pathname.split('/');
    return values[values.length - 1];
  };

  LocationWebService.prototype.getPageUrl = function () {
    return "" + this.location.pathname + this.location.search;
  };

  LocationWebService.prototype.parseQueryParams = function (search) {
    if (!search) return {};
    return query_string_1.parse(search);
  };

  LocationWebService.prototype.isOnPageWithAnalytics = function () {
    var activePathName = this.getActivePathName();
    var routesWithCustomAnalytics = this.routesWithCustomAnalytics;
    return routesWithCustomAnalytics.includes(activePathName);
  };

  LocationWebService.prototype.getOrigin = function () {
    return this.location.origin;
  };

  return LocationWebService;
}();

exports.locationWebService = new LocationWebService();