"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Http_1 = require("./Http");

var HttpApiWithAuth =
/** @class */
function (_super) {
  __extends(HttpApiWithAuth, _super);

  function HttpApiWithAuth(baseUrl, authService) {
    var _this = _super.call(this, baseUrl) || this;

    _this.authService = authService;
    return _this;
  }

  HttpApiWithAuth.prototype.getAccessToken = function () {
    return this.authService.getAccessToken();
  };

  return HttpApiWithAuth;
}(Http_1.HttpApi);

exports.HttpApiWithAuth = HttpApiWithAuth;