"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

var retryLimit = 3;
var failedRequestsMap = new Map();

exports.recordAnalyticsDataEpic = function (actions$, state, _a) {
  var analyticsApiService = _a.analyticsApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.AnalyticsActionTypes.RECORD_ANALYTICS_DATA), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return recordAnalyticsDataHandler(payload, analyticsApiService);
  }));
}; //////////


var recordAnalyticsDataHandler = function recordAnalyticsDataHandler(payload, analyticsApiService) {
  return analyticsApiService.recordData(payload).pipe(operators_1.mergeMap(function (response) {
    if (!response.success) {
      return recordAnalyticsDataErrorHandler(payload);
    }

    return rxjs_1.empty();
  }), operators_1.catchError(function () {
    return recordAnalyticsDataErrorHandler(payload);
  }));
};

function recordAnalyticsDataErrorHandler(payload) {
  var _a;

  var id = payload.id;

  if (!failedRequestsMap.has(id)) {
    failedRequestsMap.set(id, {
      payload: payload,
      numberOfFailures: 1
    });
    return rxjs_1.of(actions_1.recordAnalyticsDataActionActionCreator(payload));
  }

  var value = failedRequestsMap.get(id);
  var numberOfFailures = (_a = value === null || value === void 0 ? void 0 : value.numberOfFailures) !== null && _a !== void 0 ? _a : 0;

  if (numberOfFailures + 1 > retryLimit) {
    // todo save to a DB or LS -> create a feature story
    failedRequestsMap.delete(id);
    return rxjs_1.empty();
  }

  failedRequestsMap.set(id, {
    payload: payload,
    numberOfFailures: numberOfFailures + 1
  });
  return rxjs_1.of(actions_1.recordAnalyticsDataActionActionCreator(payload));
}

exports.recordAnalyticsDataErrorHandler = recordAnalyticsDataErrorHandler;