"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

var gutterSize_enum_1 = require("../enums/gutterSize.enum");

function getActiveGutterSize(activeBreakpoint) {
  return activeBreakpoint === responsive_1.ActiveBreakpointEnum.small ? gutterSize_enum_1.GutterSizeEnum.small : gutterSize_enum_1.GutterSizeEnum.wide;
}

exports.getActiveGutterSize = getActiveGutterSize;