"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

exports.surveyResultsErrorFetchingModuleEpic = function (actions$) {
  return actions$.pipe(redux_observable_1.ofType(modules_1.ModulesActionTypes.ERROR_FETCHING_MODULE), operators_1.mergeMap(function () {
    return surveyResultsErrorFetchingModuleEpicHandler();
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var surveyResultsErrorFetchingModuleEpicHandler = function surveyResultsErrorFetchingModuleEpicHandler() {
  return rxjs_1.of(actions_1.updateSurveyResultsErrorStateActionCreator(core_1.ErrorStateEnum.error));
};