"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var SharingFacade =
/** @class */
function () {
  function SharingFacade() {
    // todo add initializer based on the os when the case is viable
    this.locationService = core_1.locationWebService;
  }

  SharingFacade.getInstance = function () {
    if (!SharingFacade.instance) {
      SharingFacade.instance = new SharingFacade();
    }

    return SharingFacade.instance;
  };

  SharingFacade.prototype.getSharingBaseUri = function () {
    return this.locationService.getOrigin();
  };

  return SharingFacade;
}();

exports.SharingFacade = SharingFacade;
exports.sharingFacade = SharingFacade.getInstance();