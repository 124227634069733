"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var survey_1 = require("@dynamynd/survey");

var user_1 = require("@dynamynd/user");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

function withScoringValidation(WrappedComponent) {
  var WithScoringValidationHOC =
  /** @class */
  function (_super) {
    __extends(WithScoringValidationHOC, _super);

    function WithScoringValidationHOC() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.routesWithoutRedirectToDashboard = [routes_1.RoutesKeysEnum.results, routes_1.RoutesKeysEnum.dashboard, routes_1.RoutesKeysEnum.moduleView];
      _this.state = {
        ready: false
      };

      _this.hasUser = function () {
        var PersonId = _this.props.userProfile.PersonId;
        return PersonId !== '';
      };

      _this.handleScreenAccess = function () {
        var MO = _this.props.userProfile.MO;
        var isScored = survey_1.surveyService.isSurveyScored(MO);

        if (isScored) {
          _this.handleScored();
        } else {
          _this.handleNotScored();
        }

        _this.handleReadyState();
      };

      _this.handleReadyState = function () {
        if (!_this.state.ready && _this.hasUser()) {
          _this.setState({
            ready: true
          });
        }
      };

      _this.handleScored = function () {
        var currentRoute = core_1.locationWebService.getActivePathName();
        var state = _this.props.navigation.state;
        var nextRoute = state.routeName;
        if (currentRoute === routes_1.RoutesKeysEnum.dashboard && currentRoute === nextRoute) return;

        if (currentRoute !== nextRoute) {
          // tslint:disable-next-line: no-unused-expression
          !_this.routesWithoutRedirectToDashboard.includes(nextRoute) && _this.props.navigateTo(routes_1.RoutesKeysEnum.dashboard);
          return;
        }

        if (!_this.routesWithoutRedirectToDashboard.includes(currentRoute)) {
          _this.props.navigateTo(routes_1.RoutesKeysEnum.dashboard);
        }
      };

      _this.handleNotScored = function () {
        var currentRoute = core_1.locationWebService.getActivePathName();

        if (currentRoute !== routes_1.RoutesKeysEnum.take) {
          _this.props.navigateTo(routes_1.RoutesKeysEnum.take);
        }
      };

      return _this;
    }

    WithScoringValidationHOC.prototype.componentDidMount = function () {
      if (this.hasUser()) {
        this.handleScreenAccess();
      }
    };

    WithScoringValidationHOC.prototype.shouldComponentUpdate = function (nextProps, nextState) {
      var nextUserProfile = nextProps.userProfile;
      var currentUserProfile = this.props.userProfile;
      var nextReady = nextState.ready;
      var currentReady = this.state.ready;
      return nextReady !== currentReady || nextUserProfile.PersonId !== currentUserProfile.PersonId;
    };

    WithScoringValidationHOC.prototype.componentDidUpdate = function (prevProps) {
      var prevUserProfile = prevProps.userProfile;
      var currentUserProfile = this.props.userProfile;

      if (prevUserProfile.PersonId !== currentUserProfile.PersonId) {
        this.handleScreenAccess();
      }
    };

    WithScoringValidationHOC.prototype.render = function () {
      var ready = this.state.ready;
      if (!ready) return null;

      var _a = this.props,
          userProfile = _a.userProfile,
          resultsModuleMetaData = _a.resultsModuleMetaData,
          navigateTo = _a.navigateTo,
          rest = __rest(_a, ["userProfile", "resultsModuleMetaData", "navigateTo"]);

      return react_1.default.createElement(WrappedComponent, __assign({}, rest));
    };

    return WithScoringValidationHOC;
  }(react_1.Component);

  var mapStateToProps = function mapStateToProps(state) {
    return {
      userProfile: user_1.userProfileSelector(state),
      resultsModuleMetaData: modules_1.resultsModuleMetaDataSelector(state)
    };
  };

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      navigateTo: redux_1.bindActionCreators(core_1.navigateActionCreator, dispatch)
    };
  };

  var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
  return hoc(WithScoringValidationHOC);
}

exports.withScoringValidation = withScoringValidation;