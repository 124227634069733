"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../enums");

var patterns_1 = require("./patterns");

exports.textStylesParseOrder = [{
  style: enums_1.StylesEnum.colorAndFontSize,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.colorAndFontSize]
}, {
  style: enums_1.StylesEnum.underline,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.underline].underscorePattern
}, {
  style: enums_1.StylesEnum.bold,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.bold].underscorePattern
}, {
  style: enums_1.StylesEnum.italic,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.italic].underscorePattern
}, {
  style: enums_1.StylesEnum.underline,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.underline].asteriskPattern
}, {
  style: enums_1.StylesEnum.bold,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.bold].asteriskPattern
}, {
  style: enums_1.StylesEnum.italic,
  pattern: patterns_1.textStylePatterns[enums_1.StylesEnum.italic].asteriskPattern
}];
exports.elementsParseOrder = [enums_1.ElementsEnum.listItem, enums_1.ElementsEnum.paragraph];