"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("./../enums");

var matchFunctions_1 = require("./matchFunctions");

var patterns_1 = require("./patterns");

function parseElements(content) {
  var style = enums_1.ElementsEnum.listItem;
  var pattern = patterns_1.elementPatterns[style][0];
  var shouldBeParsed = content && (content.includes('|') || content.includes('[]'));

  if (shouldBeParsed) {
    var elementNodes = matchFunctions_1.matchListItemsAndParagraphs(content, pattern).filter(function (element) {
      var textContent = element.textContent;
      return !!textContent.trim();
    });
    return elementNodes;
  }

  return [];
}

exports.parseElements = parseElements;