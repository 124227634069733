"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var utilities_1 = require("@dynamynd/utilities");

var formik_1 = require("formik");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var components_1 = require("../../components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var validation_1 = require("../../validation");

var Login =
/** @class */
function (_super) {
  __extends(Login, _super);

  function Login(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {
      account: '',
      password: '',
      isNumber: true,
      selected: false
    };

    _this.handleSubmit = function (values, actions) {
      var _a;

      var setSubmitting = actions.setSubmitting;
      var isNumber = _this.state.isNumber;
      var account = isNumber ? 'phone' : 'email';
      var phone = values.phone ? values.phone : '';
      var accountValue = isNumber ? phone.replace(/^\D+/g, '') : values.email;

      _this.props.loginAttemptAction((_a = {}, _a[account] = accountValue, _a.password = values.password, _a));

      setSubmitting(true);
    };

    _this.loginMethod = function (type) {
      return function () {
        _this.setState(function (prevState) {
          return __assign(__assign({}, prevState), {
            isNumber: type === enums_1.FieldNames.phone ? true : false,
            selected: true
          });
        });
      };
    };

    return _this;
  }

  Login.prototype.isSubmitButtonDisabled = function (props) {
    var isValid = props.isValid,
        isSubmitting = props.isSubmitting;
    var updating = this.props.updating;
    return !isValid || updating || isSubmitting;
  };

  Login.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        screenProps = _a.screenProps,
        updating = _a.updating,
        navigation = _a.navigation;
    var _b = this.state,
        isNumber = _b.isNumber,
        selected = _b.selected;
    var WebViewComponent = screenProps.components.WebViewComponent;
    var navigationParams = utilities_1.navigateWithAccessCodeQueryParam(navigation.getParam);
    return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var height = _a.height,
          activeBreakpoint = _a.activeBreakpoint;
      return react_1.default.createElement(content_1.WithAdditionalContent, {
        WebViewComponent: WebViewComponent,
        includeFooter: true,
        navigationExtraParams: navigationParams
      }, function (_a) {
        var showContent = _a.showContent;
        var small = responsive_1.isSmall(activeBreakpoint);
        return react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
          activeBreakpoint: activeBreakpoint,
          color: "purple",
          version: {
            sm: 'image',
            lg: 'image'
          },
          navigationExtraParams: navigationParams
        }), !small && react_1.default.createElement(components_lib_1.Button, {
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: function onPress() {
            return navigation.navigate(routes_1.RoutesKeysEnum.signup, navigationParams);
          },
          text: "Create Account",
          uppercase: true
        }), small && react_1.default.createElement(components_lib_1.ContentItemsPopup, {
          navigation: navigation,
          showPrivacy: function showPrivacy() {
            return showContent(content_1.ContentPagesEnum.privacy);
          },
          showTerms: function showTerms() {
            return showContent(content_1.ContentPagesEnum.terms);
          },
          disableLogin: true
        })), react_1.default.createElement(components_lib_1.ContentContainerScrollable, {
          height: height,
          activeBreakpoint: activeBreakpoint
        }, react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_1.TitleWrapper, {
          activeBreakpoint: activeBreakpoint
        }, react_1.default.createElement(components_lib_1.Title, {
          activeBreakpoint: activeBreakpoint,
          centered: true
        }, "Log In")), react_1.default.createElement(components_1.TileGroupRow, {
          activeBreakpoint: activeBreakpoint
        }, react_1.default.createElement(components_lib_1.RowWrapper, {
          wrap: true,
          activeBreakpoint: activeBreakpoint
        }, react_1.default.createElement(components_lib_1.TileButton, {
          activeBreakpoint: activeBreakpoint,
          text: enums_1.FieldPlaceHolders.logInWithPhone,
          action: _this.loginMethod(enums_1.FieldNames.phone),
          isSelected: isNumber && selected,
          accessibilityLabel: "Log In With Phone Number"
        }), react_1.default.createElement(components_lib_1.TileButton, {
          activeBreakpoint: activeBreakpoint,
          text: enums_1.FieldPlaceHolders.logInWithEmail,
          action: _this.loginMethod(enums_1.FieldNames.email),
          isSelected: !isNumber && selected,
          accessibilityLabel: "Log In With Email"
        }))), selected && react_1.default.createElement(formik_1.Formik, {
          enableReinitialize: true,
          initialValues: _this.state,
          onSubmit: function onSubmit(values, actions) {
            return _this.handleSubmit(values, actions);
          },
          validationSchema: isNumber ? validation_1.PhoneValidation : validation_1.EmailValidation,
          render: function render(props) {
            var isSubmitting = props.isSubmitting; // set submitting status of the form

            if (isSubmitting && !updating) {
              props.setSubmitting(false);
            }

            var submitButtonProps = {
              disabled: _this.isSubmitButtonDisabled(props)
            };
            return react_1.default.createElement(components_1.FormInputGroup, {
              activeBreakpoint: activeBreakpoint
            }, isNumber ? react_1.default.createElement(components_1.PhoneInput, __assign({
              activeBreakpoint: activeBreakpoint,
              placeholder: enums_1.FieldPlaceHolders.phoneNumber,
              label: enums_1.FieldLabels.phoneNumber,
              name: enums_1.FieldNames.phone,
              isDisabled: isSubmitting,
              accessibilityLabel: "Enter your phone number"
            }, props)) : react_1.default.createElement(components_lib_1.FormInput, __assign({
              placeholder: enums_1.FieldLabels.email,
              name: enums_1.FieldNames.email,
              label: enums_1.FieldLabels.email,
              isSecure: false,
              isDisabled: isSubmitting,
              accessibilityLabel: "Enter your email"
            }, props)), react_1.default.createElement(components_lib_1.FormInput, __assign({
              placeholder: enums_1.FieldPlaceHolders.password,
              name: enums_1.FieldNames.password,
              isSecure: true,
              isDisabled: isSubmitting,
              accessibilityLabel: "Enter your password"
            }, props)), react_1.default.createElement(components_1.AuthButtonsContainer, null, react_1.default.createElement(components_lib_1.Button, __assign({
              type: components_lib_1.ButtonTypeEnum.primary,
              text: enums_1.FieldLabels.continue,
              uppercase: true,
              onPress: props.handleSubmit,
              accessibilityLabel: "Login"
            }, submitButtonProps, {
              loading: isSubmitting || updating
            }))));
          }
        }), react_1.default.createElement(components_lib_1.RowCentered, null, react_1.default.createElement(components_1.ForgotPasswordLink, {
          navigation: navigation
        })))));
      });
    });
  };

  return Login;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    updating: store_1.authUpdatingSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    loginAttemptAction: redux_1.bindActionCreators(store_1.loginAttemptActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme, sharing_1.withAddThisSharing);
exports.default = hoc(Login);