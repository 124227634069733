"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var enums_1 = require("../enums");

var instructions = 'instructions';

var SurveyService =
/** @class */
function () {
  function SurveyService() {}

  SurveyService.prototype.questionToArray = function (surveyConfig) {
    return __assign(__assign({}, surveyConfig), {
      Questions: utilities_1.values(surveyConfig.Questions)
    });
  };

  SurveyService.prototype.transformQuestionsOptions = function (question) {
    return __assign(__assign({}, question), {
      options: utilities_1.values(question.options)
    });
  };

  SurveyService.prototype.getResponseByQuestionId = function (responses, questionId) {
    var result = utilities_1.find(responses, function (response) {
      return response.QuestionnaireQuestionId === questionId;
    });
    return result;
  };

  SurveyService.prototype.getOptionIdByValue = function (options, value) {
    var option = utilities_1.find(options, function (o) {
      return o.value === value;
    });
    return option ? option.optionId : '';
  };

  SurveyService.prototype.getAnsweredQuestionsIds = function (responses) {
    return responses.map(function (response) {
      return response.QuestionnaireQuestionId;
    });
  };

  SurveyService.prototype.getOrderedQuestionsIds = function (questions) {
    var ordered = utilities_1.orderBy(questions, function (question) {
      return Number(question.questionOrder);
    });
    return ordered.map(function (question) {
      return question.questionSampleId;
    });
  };

  SurveyService.prototype.getOpenedInformation = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, usersId, questionId, _b, _c;

      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _c = (_b = core_1.parseStorage).parseData;
            return [4
            /*yield*/
            , core_1.parseStorage.getData(instructions)];

          case 1:
            _a = _c.apply(_b, [_d.sent()]), usersId = _a.usersId, questionId = _a.questionId;
            return [2
            /*return*/
            , {
              usersId: usersId,
              questionId: questionId
            }];
        }
      });
    });
  };

  SurveyService.prototype.setInstructionStatus = function (data) {
    core_1.parseStorage.setData(instructions, data);
  };

  SurveyService.prototype.questionNeedInstruction = function (questionId, question) {
    return question.questionsPool[questionId] && question.questionsPool[questionId].questionOrder === '1' ? true : false;
  };

  SurveyService.prototype.questionAlreadyOpen = function (data, userId, questionId) {
    if (!data.usersId || !data.questionId) {
      return false;
    }

    return data.usersId.includes(userId) && data.questionId.includes(questionId) ? true : false;
  };

  SurveyService.prototype.pickActiveAccessCode = function (activeQuestionId, surveys) {
    var accessCode = '';
    var configs = utilities_1.values(surveys);

    for (var _i = 0, configs_1 = configs; _i < configs_1.length; _i++) {
      var config = configs_1[_i];
      var questions = config.Questions;

      for (var _a = 0, questions_1 = questions; _a < questions_1.length; _a++) {
        var question = questions_1[_a];

        if (question.questionSampleId === activeQuestionId) {
          accessCode = config.AccessCode;
          break;
        }
      }
    }

    return accessCode;
  };

  SurveyService.prototype.pickActiveQuestionId = function (questionsOrder, completedQuestions) {
    if (!completedQuestions.length) {
      return questionsOrder[0];
    }

    var lastCompletedQuestion = utilities_1.last(completedQuestions);
    var idx = questionsOrder.findIndex(function (e) {
      return e === lastCompletedQuestion;
    });

    if (questionsOrder.length === idx + 1) {
      return questionsOrder[idx];
    }

    return questionsOrder[idx + 1];
  };

  SurveyService.prototype.pickConsequentQuestion = function (currentActiveQuestionId, questionsOrder, type) {
    var _a;

    var modifiers = (_a = {}, _a[enums_1.ChangeQuestionTypeEnum.next] = 1, _a[enums_1.ChangeQuestionTypeEnum.prev] = -1, _a[enums_1.ChangeQuestionTypeEnum.score] = 0, _a);
    var idx = questionsOrder.findIndex(function (id) {
      return id === currentActiveQuestionId;
    });
    if (idx === -1) return '';
    var modifier = modifiers[type] || 0;
    return questionsOrder[idx + modifier];
  };

  SurveyService.prototype.isSurveyScored = function (userMO) {
    return userMO !== '';
  };

  return SurveyService;
}();

exports.SurveyService = SurveyService;
exports.surveyService = new SurveyService();