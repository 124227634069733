"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.getAvatarsActionCreator = function () {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.ContentActionTypes.DYNAMYND_GET_AVATARS
  });
};

exports.setAvatarActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ContentActionTypes.DYNAMYND_SET_AVATARS
  });
};

exports.setInstructionsActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ContentActionTypes.DYNAMYND_SET_INSTRUCTIONS
  });
};

exports.getPageContentActionCreator = function (contentItem) {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.ContentActionTypes.DYNAMYND_GET_PAGES_CONTENT,
    payload: contentItem
  });
};

exports.setPageContentActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ContentActionTypes.DYNAMYND_SET_PAGE_CONTENT
  });
};

exports.getContentForSharingActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ContentActionTypes.GET_CONTENT_FOR_SHARING
  });
};

exports.setContentForSharingActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ContentActionTypes.SET_CONTENT_FOR_SHARING
  });
};