"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var components_1 = require("./components");

var Dashboard =
/** @class */
function (_super) {
  __extends(Dashboard, _super);

  function Dashboard() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.description = 'Your personal combination of four powerful modes of mental energy make you a:';

    _this.handleResultsModuleFetch = function () {
      var _a, _b;

      var _c = _this.props,
          resultsModuleName = _c.resultsModuleName,
          getModuleDetails = _c.getModuleDetails,
          availableModules = _c.availableModules; // we always need to have the latest data for the modules (payment wise),
      // that is why we would always refetch the data if the resultsModuleName exist

      var isResultsModuleUnlocked = (_b = (_a = availableModules[resultsModuleName]) === null || _a === void 0 ? void 0 : _a.unlocked) !== null && _b !== void 0 ? _b : false;

      if (resultsModuleName && isResultsModuleUnlocked) {
        getModuleDetails(resultsModuleName);
      }
    };

    _this.handleLinkAddOnPress = function (link) {
      if (react_native_1.Platform.OS === 'web') {
        window.open(_this.prepareLink(link), '_blank');
      }
    };

    _this.prepareLink = function (link) {
      var test = /^http/g;

      if (link.search(test) < 0) {
        return "https://" + link;
      }

      return link;
    };

    _this.modulesHaveData = function () {
      var modulesViewData = _this.props.modulesViewData;
      return modulesViewData.length > 0;
    };

    _this.getDisplayName = function () {
      var user = _this.props.user;
      return user_1.userService.getDisplayName(user);
    };

    _this.isDataReady = function () {
      var naturalAdvantageValue = _this.props.naturalAdvantageValue; // render the screen only when we have all the data

      return _this.modulesHaveData() && naturalAdvantageValue !== '';
    };

    return _this;
  }

  Dashboard.prototype.componentDidMount = function () {
    // fetch the results module data if the resultsModuleName is set on the store
    if (utilities_1.isEmpty(this.props.resultsModuleData)) {
      this.handleResultsModuleFetch();
    }
  };

  Dashboard.prototype.componentDidUpdate = function (prevProps) {
    // todo refetch the data once there is a successful payment
    var prevResultsModuleName = prevProps.resultsModuleName;
    var currentResultsModuleName = this.props.resultsModuleName;

    if (prevResultsModuleName === '' && prevResultsModuleName !== currentResultsModuleName) {
      this.props.getModuleDetails(currentResultsModuleName);
    }
  };

  Dashboard.prototype.render = function () {
    var _this = this;

    var isDataReady = this.isDataReady();
    var _a = this.props,
        _b = _a.screenProps.components,
        WebViewComponent = _b.WebViewComponent,
        IFrameComponent = _b.IFrameComponent,
        modulesViewData = _a.modulesViewData,
        navigateTo = _a.navigateTo,
        naturalAdvantageValue = _a.naturalAdvantageValue,
        purchasingModulesViewState = _a.purchasingModulesViewState;
    var personUUID = this.props.user.ID;
    var link = "https://rest.kolbestudent.com/inc/print_report.cfm/mo/" + personUUID + "/baseFree";
    var naturalAdvantageTitleColor = modules_1.modulesService.getNaturalAdvantageTitleColor(naturalAdvantageValue);
    return react_1.default.createElement(content_1.WithAdditionalContent, {
      WebViewComponent: WebViewComponent,
      IFrameComponent: IFrameComponent,
      includeFooter: true
    }, function (_a) {
      var showContent = _a.showContent;
      return react_1.default.createElement(components_lib_1.BackgroundWithShapes, {
        shapes: {
          topLeft: true,
          topRight: true,
          bottomLeft: true,
          bottomRight: true
        },
        shouldExpand: !isDataReady || purchasingModulesViewState
      }, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
        color: "white",
        version: {
          sm: 'text',
          lg: 'image'
        }
      }), react_1.default.createElement(user_1.UserProfileBox, {
        showPrivacy: function showPrivacy() {
          return showContent(content_1.ContentPagesEnum.privacy);
        },
        showTerms: function showTerms() {
          return showContent(content_1.ContentPagesEnum.terms);
        }
      })), react_1.default.createElement(modules_1.ContentRowResponsive, null, !isDataReady || purchasingModulesViewState ? react_1.default.createElement(components_lib_1.LoadingIndicator, {
        size: "large",
        fullScreen: true
      }) : react_1.default.createElement(components_1.ContentRowFixer, null, react_1.default.createElement(components_1.ColumnFlexStartResponsiveLeftDecorated, {
        xl: 50
      }, react_1.default.createElement(components_lib_1.Label, {
        uppercase: true
      }, "Welcome, ", _this.getDisplayName()), react_1.default.createElement(components_1.StrengthsTitle, null, _this.description), react_1.default.createElement(components_1.NaturalAdvantageTitleDecorated, {
        color: naturalAdvantageTitleColor
      }, naturalAdvantageValue), react_1.default.createElement(components_1.ViewResultsButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
        text: "View My Results",
        uppercase: true,
        type: components_lib_1.ButtonTypeEnum.primary,
        onPress: function onPress() {
          return navigateTo(routes_1.RoutesKeysEnum.results);
        }
      })), react_1.default.createElement(components_1.ViewResultsButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
        text: "Download results",
        uppercase: true,
        type: components_lib_1.ButtonTypeEnum.primary,
        onPress: function onPress() {
          return _this.handleLinkAddOnPress(link);
        }
      })), react_1.default.createElement(components_1.StrengthsSectionImage, {
        source: {
          uri: components_lib_1.appConfig.images.surveyDashboardSectionStrengths
        }
      })), react_1.default.createElement(components_1.ColumnFlexStartResponsiveRightDecorated, {
        xl: 50
      }, react_1.default.createElement(modules_1.AvailableModules, {
        label: "Explore Modules",
        data: modulesViewData,
        displayFilters: true,
        showPaymentModal: showContent
      })))));
    });
  };

  return Dashboard;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: user_1.userProfileSelector(state),
    resultsModuleName: modules_1.resultsModuleNameSelector(state),
    resultsModuleData: modules_1.resultsModuleDetailsViewModelSelector(state),
    naturalAdvantageValue: modules_1.naturalAdvantageValueSelector(state),
    modulesViewData: modules_1.modulesViewDataSelector(state),
    availableModules: modules_1.availableModulesSelector(state),
    purchasingModulesViewState: modules_1.purchasingModulesViewStateSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getModuleDetails: redux_1.bindActionCreators(modules_1.getModuleDetailsActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme, core_1.withNavigateTo, sharing_1.withAddThisSharing);
exports.default = hoc(Dashboard);