"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var user_1 = require("@dynamynd/user");

var redux_observable_1 = require("redux-observable");

var operators_1 = require("rxjs/operators");

exports.loginEpic = function (actions$, state$, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(authentication_1.ActionTypes.DYNAMYND_LOGIN_ATTEMPT), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (_a) {
    var email = _a.email,
        phone = _a.phone,
        password = _a.password;
    return loginAttempt(email, phone, password, authApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var loginAttempt = function loginAttempt(email, phone, password, authApiService) {
  return authApiService.getAuthToken(email || '', phone || '', password).pipe(operators_1.mergeMap(function (_a) {
    var access_token = _a.access_token,
        expires_in = _a.expires_in;
    authentication_1.authService.setCredentials({
      access_token: access_token,
      expires_in: expires_in
    });
    return [modules_1.getAvailableModulesActionCreator(), user_1.getUserProfileActionCreator({
      activeRoute: routes_1.RoutesKeysEnum.login,
      withRedirect: true
    }), authentication_1.loginSuccessActionCreator()];
  }), operators_1.catchError(core_1.defaultErrorHandler));
};