"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_hotjar_1 = require("react-hotjar");

var id = process.env.REACT_APP_HOTJAR_ID;
var version = 6;

function initializeHotjar() {
  react_hotjar_1.hotjar.initialize(id, version);
}

exports.initializeHotjar = initializeHotjar;