"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_1 = require("@dynamynd/content");

var utilities_1 = require("@dynamynd/utilities");

var UserService =
/** @class */
function () {
  function UserService() {
    this.getDisplayName = function (userProfile) {
      var firstName = userProfile.FirstName,
          nickname = userProfile.Nickname;
      return nickname || utilities_1.capitalize(firstName);
    };
  }

  UserService.prototype.mapValuesToUserProfile = function (currentUserProfile, values, withPassword) {
    var Avatar = values.avatar,
        FirstName = values.firstName,
        LastName = values.lastName,
        Nickname = values.nickName,
        Country = values.country,
        Zip = values.zipCode,
        Gender = values.gender,
        PreferredPronoun = values.preferredPronoun,
        Ethnicity = values.ethnicity,
        EducationGoal = values.educationalGoal,
        specialNeeds = values.specialNeeds,
        password = values.password,
        confirmPassword = values.confirmPassword,
        currentPassword = values.currentPassword;

    var result = __assign(__assign({}, currentUserProfile), {
      Avatar: Avatar,
      FirstName: FirstName,
      LastName: LastName,
      Nickname: Nickname,
      Country: Country,
      Zip: Zip,
      Gender: Gender,
      PreferredPronoun: PreferredPronoun,
      Ethnicity: Ethnicity,
      EducationGoal: EducationGoal,
      SpecialNeeds: specialNeeds.join(',')
    });

    if (withPassword) {
      result.Password = password;
      result.Password2 = confirmPassword;
      result.CurrentPassword = currentPassword;
    }

    return result;
  };

  UserService.prototype.mapEditProfileValues = function (userProfile) {
    var avatar = userProfile.Avatar,
        firstName = userProfile.FirstName,
        lastName = userProfile.LastName,
        nickName = userProfile.Nickname,
        country = userProfile.Country,
        zipCode = userProfile.Zip,
        gender = userProfile.Gender,
        preferredPronoun = userProfile.PreferredPronoun,
        ethnicity = userProfile.Ethnicity,
        specialNeedsData = userProfile.SpecialNeeds,
        educationalGoal = userProfile.EducationGoal,
        userName = userProfile.Username,
        phone = userProfile.CellPhone,
        email = userProfile.Email;
    var specialNeeds = specialNeedsData.length ? specialNeedsData.split(',') : [];
    return {
      firstName: firstName,
      lastName: lastName,
      nickName: nickName,
      country: country,
      zipCode: zipCode,
      gender: gender,
      preferredPronoun: preferredPronoun,
      ethnicity: ethnicity,
      educationalGoal: educationalGoal,
      specialNeeds: specialNeeds,
      userName: userName,
      phone: phone,
      email: email,
      avatar: avatar ? avatar : content_1.ContentService.defaultAvatarKey,
      password: '',
      confirmPassword: '',
      currentPassword: ''
    };
  };

  UserService.prototype.prepareUpdateUserApiPayload = function (userProfile, withPassword) {
    var PersonId = userProfile.PersonId,
        FirstName = userProfile.FirstName,
        LastName = userProfile.LastName,
        Avatar = userProfile.Avatar,
        Nickname = userProfile.Nickname,
        Gender = userProfile.Gender,
        PreferredPronoun = userProfile.PreferredPronoun,
        Ethnicity = userProfile.Ethnicity,
        SpecialNeeds = userProfile.SpecialNeeds,
        Country = userProfile.Country,
        Zip = userProfile.Zip,
        EducationGoal = userProfile.EducationGoal,
        Password = userProfile.Password,
        Password2 = userProfile.Password2,
        CurrentPassword = userProfile.CurrentPassword;
    var result = {
      PersonId: PersonId,
      FirstName: FirstName,
      LastName: LastName,
      Avatar: Avatar,
      Nickname: Nickname,
      Gender: Gender,
      PreferredPronoun: PreferredPronoun,
      Ethnicity: Ethnicity,
      SpecialNeeds: SpecialNeeds,
      Country: Country,
      Zip: Zip,
      EducationGoal: EducationGoal
    };

    if (withPassword) {
      return __assign(__assign({}, result), {
        Password: Password,
        Password2: Password2,
        CurrentPassword: CurrentPassword
      });
    }

    return result;
  };

  return UserService;
}();

exports.userService = new UserService();