"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var parseHtmlChars_1 = require("../parseHtmlChars");

function parseResultsIntroContent(content) {
  var contentWithParsedHtmlChars = parseHtmlChars_1.parseHtmlChars(content);

  var _a = contentWithParsedHtmlChars.split('|'),
      titleContent = _a[0],
      rest = _a.slice(1);

  var _b = rest.join('').split('. ').reverse(),
      congratsLinesContent = _b[0],
      paragraphContent = _b.slice(1);

  var _c = congratsLinesContent.split('! '),
      congratsContent = _c[0],
      beYouContent = _c[1];

  return {
    beYouContent: beYouContent,
    titleContent: titleContent.replace('Student Aptitude Quiz.', ''),
    congratsContent: congratsContent + "!",
    paragraphContent: paragraphContent.reverse().join('. ') + "."
  };
}

exports.parseResultsIntroContent = parseResultsIntroContent;