import {withRedirect} from '@dynamynd/core';
import {ContactUsScreen, HomeScreen, shouldRedirectAuth, WithPopupLayout} from '@dynamynd/dynamynd';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';

const EnhancedHomeScreen = compose(
    withAnalyticsTrackerWeb,
    withRedirect<any>(shouldRedirectAuth, RoutesKeysEnum.dashboard)
)(HomeScreen);
const EnhancedContactUsScreen = compose(withAnalyticsTrackerWeb)(ContactUsScreen as any);

export const commonNavigator = createNavigator(
    appView(WithPopupLayout),
    SwitchRouter({
        [RoutesKeysEnum.home]: EnhancedHomeScreen,
        [RoutesKeysEnum.contact]: EnhancedContactUsScreen
    }),
    {}
);
