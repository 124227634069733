"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_1 = require("redux");

var activeModule_reducer_1 = require("./activeModule.reducer");

var availableModules_reducer_1 = require("./availableModules.reducer");

var modulesDetails_reducer_1 = require("./modulesDetails.reducer");

var purchasingContactInfo_reducer_1 = require("./purchasingContactInfo.reducer");

var resultsModule_reducer_1 = require("./resultsModule.reducer");

var sharedModules_reducer_1 = require("./sharedModules.reducer");

var view_reducer_1 = require("./view.reducer"); // todo actions


var reducerMap = {
  activeModule: activeModule_reducer_1.activeModuleReducer,
  resultsModule: resultsModule_reducer_1.resultsModuleReducer,
  purchasingContactInfo: purchasingContactInfo_reducer_1.purchasingContactInfoReducer,
  availableModules: availableModules_reducer_1.availableModulesReducer,
  modulesDetails: modulesDetails_reducer_1.modulesDetailsReducer,
  view: view_reducer_1.viewReducer,
  sharedModules: sharedModules_reducer_1.sharedModulesReducer
};
exports.modulesReducer = redux_1.combineReducers(reducerMap);