"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var enums_1 = require("../../enums");

var styled_1 = require("../styled");

var iconsMap = (_a = {}, _a[enums_1.ToastNotificationTypeEnum.info] = {
  name: 'information',
  backgroundColor: '#58B1A5'
}, _a[enums_1.ToastNotificationTypeEnum.success] = {
  name: 'checkbox-marked-circle',
  backgroundColor: '#58B1A5'
}, _a[enums_1.ToastNotificationTypeEnum.warning] = {
  name: 'alert-circle',
  backgroundColor: '#58B1A5'
}, _a[enums_1.ToastNotificationTypeEnum.error] = {
  name: 'close-circle',
  backgroundColor: '#58B1A5'
}, _a);

var Notification =
/** @class */
function (_super) {
  __extends(Notification, _super);

  function Notification() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  Notification.prototype.render = function () {
    var _a = this.props,
        type = _a.type,
        message = _a.message,
        close = _a.close,
        activeBreakpoint = _a.activeBreakpoint,
        width = _a.width;
    var icon = iconsMap[type];

    if (!icon) {
      icon = iconsMap[enums_1.ToastNotificationTypeEnum.info];
    }

    var backgroundColor = icon.backgroundColor,
        name = icon.name;
    return react_1.default.createElement(styled_1.NotificationContainer, {
      borderColor: backgroundColor,
      activeBreakpoint: activeBreakpoint,
      width: width
    }, react_1.default.createElement(styled_1.NotificationIconContainer, {
      backgroundColor: backgroundColor
    }, react_1.default.createElement(components_lib_1.Icon, {
      color: "white",
      size: components_lib_1.IconSizeEnum.small,
      name: name
    })), react_1.default.createElement(styled_1.NotificationText, {
      numberOfLines: 1
    }, message), react_1.default.createElement(react_native_1.TouchableWithoutFeedback, {
      onPress: close
    }, react_1.default.createElement(styled_1.NotificationAction, null, react_1.default.createElement(components_lib_1.Icon, {
      color: "#262B33",
      size: components_lib_1.IconSizeEnum.small,
      name: "close"
    }))));
  };

  return Notification;
}(react_1.PureComponent);

exports.Notification = Notification;