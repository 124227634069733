"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_1 = require("redux");

var registerUser_reducer_1 = require("./registerUser.reducer");

var view_reducer_1 = require("./view.reducer");

var reducerMap = {
  registerUser: registerUser_reducer_1.registerUserReducer,
  view: view_reducer_1.viewReducer
};
exports.authReducer = redux_1.combineReducers(reducerMap);