"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var toast_notifications_1 = require("@dynamynd/toast-notifications");

var react_1 = __importStar(require("react"));

var SafeView_1 = require("../SafeView/SafeView");

var GeneralLayout =
/** @class */
function (_super) {
  __extends(GeneralLayout, _super);

  function GeneralLayout() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  GeneralLayout.prototype.render = function () {
    var children = this.props.children;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(toast_notifications_1.ToastNotification, null), react_1.default.createElement(SafeView_1.SafeView, null, children));
  };

  return GeneralLayout;
}(react_1.Component);

exports.default = GeneralLayout;