"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var Wizard =
/** @class */
function (_super) {
  __extends(Wizard, _super);

  function Wizard() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      activeStep: 0
    };

    _this.previousHandler = function () {
      _this.setState(function (prevState) {
        if (prevState.activeStep >= 1) {
          return __assign(__assign({}, prevState), {
            activeStep: prevState.activeStep - 1
          });
        }

        return prevState;
      });
    };

    _this.nextHanlder = function () {
      _this.setState(function (prevState) {
        if (prevState.activeStep >= 0) {
          return __assign(__assign({}, prevState), {
            activeStep: prevState.activeStep + 1
          });
        }

        return prevState;
      });
    };

    return _this;
  }

  Wizard.prototype.render = function () {
    var children = this.props.children;
    return children(this.previousHandler, this.nextHanlder, this.state.activeStep);
  };

  return Wizard;
}(React.Component);

exports.Wizard = Wizard;
exports.default = Wizard;