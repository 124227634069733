"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.pageContentEpic = function (actions$, state$, _a) {
  var contentApiService = _a.contentApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ContentActionTypes.DYNAMYND_GET_PAGES_CONTENT), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return pageContentEpicHandler(payload, contentApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

function pageContentEpicHandler(key, contentApiService) {
  var page = services_1.ContentApiService.pages[key];
  return contentApiService.fetchContent(page).pipe(operators_1.mergeMap(function (response) {
    var content = formatContent(key, response.data);
    return rxjs_1.of(actions_1.setPageContentActionCreator({
      key: key,
      content: content
    }));
  }), operators_1.catchError(core_1.defaultErrorHandler));
}

var contentTitleRegex = /Dynamynd,\r\nInc\.\s’s\sPrivacy\sPolicy|^<p.+?<\/p>|<h2.*?Terms\sof\sUse<\/h2>/;
var dateFormat = 'MMMM DD, YYYY';

function formatContent(key, data) {
  var content;
  var subTitle = ''; // todo refactor

  if (key === enums_1.ContentPagesEnum.home) {
    content = data.content.split('|');
    subTitle = data.metaDescription;
  } else {
    content = data.content.replace(contentTitleRegex, '');
  }

  return {
    content: content,
    subTitle: subTitle,
    pageTitle: data.pageTitle,
    lastModified: utilities_1.formatDate(data.ModifiedDate, dateFormat)
  };
}