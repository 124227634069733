"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var accessCodeKey = 's';

function navigateWithAccessCodeQueryParam(getParam) {
  var params = {};
  var accessCode = getParam(accessCodeKey);

  if (accessCode) {
    // tslint:disable-next-line: no-string-literal
    params[accessCodeKey] = accessCode;
  }

  return params;
}

exports.navigateWithAccessCodeQueryParam = navigateWithAccessCodeQueryParam;

function getAccessCodeValue(getParam) {
  return getParam(accessCodeKey);
}

exports.getAccessCodeValue = getAccessCodeValue;