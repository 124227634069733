"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ResponsiveBreakpointsEnum;

(function (ResponsiveBreakpointsEnum) {
  ResponsiveBreakpointsEnum[ResponsiveBreakpointsEnum["small"] = 576] = "small";
  ResponsiveBreakpointsEnum[ResponsiveBreakpointsEnum["medium"] = 768] = "medium";
  ResponsiveBreakpointsEnum[ResponsiveBreakpointsEnum["large"] = 992] = "large";
  ResponsiveBreakpointsEnum[ResponsiveBreakpointsEnum["extraLarge"] = 1200] = "extraLarge";
})(ResponsiveBreakpointsEnum = exports.ResponsiveBreakpointsEnum || (exports.ResponsiveBreakpointsEnum = {}));