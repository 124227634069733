"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var actions_1 = require("../actions");

var state_1 = require("../state");

exports.viewReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialAuthState.view;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_SUBMIT:
    case actions_1.ActionTypes.DYNAMYND_LOGIN_ATTEMPT:
    case actions_1.ActionTypes.RESET_PASSWORD_VALIDATE_CODE:
    case actions_1.ActionTypes.UPDATE_USER_PASSWORD:
      return setUpdatingState(true, state);

    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_RESPONSE:
    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_FAIL:
    case core_1.ActionTypes.DYNAMYND_ERROR:
    case actions_1.ActionTypes.RESET_REGISTER_USER_VIEW_STATE:
      return setUpdatingState(false, state);

    case actions_1.ActionTypes.RESET_PASSWORD_INITIATE_FLOW:
      return resetPasswordInitiateFlowHandler(action, state);

    case actions_1.ActionTypes.RESET_PASSWORD_UPDATE_STAGE:
      return resetPasswordUpdateStageHandler(action, state);

    case actions_1.ActionTypes.SET_INITIAL_VALUES_TO_RESET_PASSWORD_VIEW_STATE:
      return resetPasswordSetInitialState(state);

    default:
      return state;
  }
}; ////


function setUpdatingState(newValue, state) {
  return __assign(__assign({}, state), {
    updating: newValue
  });
}

function resetPasswordUpdateStageHandler(action, state) {
  var stage = action.payload.stage;
  return __assign(__assign({}, state), {
    updating: false,
    passwordReset: __assign(__assign({}, state.passwordReset), {
      stage: stage
    })
  });
}

function resetPasswordInitiateFlowHandler(action, state) {
  var payload = action.payload;
  var username = utilities_1.get(payload, 'email') || utilities_1.get(payload, 'phone');
  return __assign(__assign({}, state), {
    passwordReset: __assign(__assign({}, state.passwordReset), {
      username: username
    }),
    updating: true
  });
}

function resetPasswordSetInitialState(state) {
  return __assign(__assign({}, state), {
    passwordReset: __assign({}, state_1.initialAuthState.view.passwordReset)
  });
} /// todo validate that everything has been reset properly