"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var user_1 = require("@dynamynd/user");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions");

exports.getUserProfileSuccessEpic = function (actions$) {
  return actions$.pipe(redux_observable_1.ofType(user_1.UserActionTypes.GET_USER_PROFILE_SUCCESS), operators_1.mergeMap(function (_a) {
    var payload = _a.payload;
    return getUserProfileSuccessEpicHandler(payload);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

function getUserProfileSuccessEpicHandler(payload) {
  var userProfile = payload.userProfile;
  var isSurveyCompleted = services_1.surveyService.isSurveyScored(userProfile.MO);
  return rxjs_1.of(actions_1.setSurveyCompletionStatusActionCreator(isSurveyCompleted));
}