"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ActionTypeHandlersEnum;

(function (ActionTypeHandlersEnum) {
  // taker
  ActionTypeHandlersEnum["startSurveyTakingSession"] = "startSurveyTakingSession";
  ActionTypeHandlersEnum["endSurveyTakingSession"] = "endSurveyTakingSession";
  ActionTypeHandlersEnum["handleQuestionSession"] = "handleQuestionSession";
  ActionTypeHandlersEnum["setQuestionAnswer"] = "setQuestionAnswer"; // modules

  ActionTypeHandlersEnum["startModuleViewSession"] = "startModuleViewSession";
  ActionTypeHandlersEnum["endModuleViewSession"] = "endModuleViewSession";
  ActionTypeHandlersEnum["startModuleSectionTipsAndTricksViewSession"] = "startModuleSectionTipsAndTricksViewSession";
  ActionTypeHandlersEnum["endModuleSectionTipsAndTricksViewSession"] = "endModuleSectionTipsAndTricksViewSession";
  ActionTypeHandlersEnum["moduleTipsAndTricksInteraction"] = "moduleTipsAndTricksInteraction"; // common

  ActionTypeHandlersEnum["endAllActiveAnalyticsSessionsOnUserIdle"] = "endAllActiveAnalyticsSessionsOnUserIdle";
  ActionTypeHandlersEnum["resumeAllLastActiveAnalyticsSessionsOnUserActive"] = "resumeAllLastActiveAnalyticsSessionsOnUserActive";
})(ActionTypeHandlersEnum = exports.ActionTypeHandlersEnum || (exports.ActionTypeHandlersEnum = {}));