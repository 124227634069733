import {IFrameWeb, MaskedInputDateWeb, WebViewWeb} from '@dynamynd/components-lib-web';
import {IScreenProps} from '@dynamynd/core';

export const screenProps: IScreenProps = {
    components: {
        MaskedInputDate: MaskedInputDateWeb,
        WebViewComponent: WebViewWeb,
        IFrameComponent: IFrameWeb
    }
};
