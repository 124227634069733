"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var Yup = __importStar(require("yup"));

var enums_1 = require("../enums");

var regEx_1 = require("./regEx");

exports.userNameRegistrationValidationSchema = Yup.object().shape({
  userName: Yup.string().min(5, core_1.ValidationErrorMessagesEnum.tooShort).max(20, core_1.ValidationErrorMessagesEnum.tooLong).required(core_1.ValidationErrorMessagesEnum.requiredField).matches(regEx_1.negativeEmailRegEx, core_1.ValidationErrorMessagesEnum.negativeEmail).matches(regEx_1.negativePhoneNumberRegEx, core_1.ValidationErrorMessagesEnum.negativePhone).matches(regEx_1.negativePhoneNumberWithoutParenthesesRegEx, core_1.ValidationErrorMessagesEnum.negativePhone),
  userNameValid: Yup.boolean().required().oneOf([true], core_1.ValidationErrorMessagesEnum.userNameRegistered),
  password: Yup.string().min(8, core_1.ValidationErrorMessagesEnum.tooShort).max(20, core_1.ValidationErrorMessagesEnum.tooLong).required(core_1.ValidationErrorMessagesEnum.requiredField),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password')], core_1.ValidationErrorMessagesEnum.passwordShouldMatch).required(core_1.ValidationErrorMessagesEnum.confirmPasswordRequired),
  firstName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.tooShort).max(50, core_1.ValidationErrorMessagesEnum.tooLong).required(core_1.ValidationErrorMessagesEnum.requiredField),
  lastName: Yup.string().length(1, core_1.ValidationErrorMessagesEnum.lastInitial).required(core_1.ValidationErrorMessagesEnum.requiredField),
  nickName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.tooShort).max(50, core_1.ValidationErrorMessagesEnum.tooLong),
  preferredPronoun: Yup.string().oneOf([enums_1.PreferredPronouns.feminine, enums_1.PreferredPronouns.masculine, enums_1.PreferredPronouns.neutral], core_1.ValidationErrorMessagesEnum.preferredPronoun),
  country: Yup.string().required(core_1.ValidationErrorMessagesEnum.requiredField),
  termsAccepted: Yup.boolean().required().oneOf([true], core_1.ValidationErrorMessagesEnum.termsAccepted),
  loginType: Yup.string().required().oneOf([enums_1.LoginTypeEnum.userName])
});