"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

var content_1 = require("@dynamynd/content");

var features_1 = require("@dynamynd/features");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var toast_notifications_1 = require("@dynamynd/toast-notifications");

var user_1 = require("@dynamynd/user");

var redux_1 = require("redux");

var contactPageMessageStatus_reducer_1 = require("./contactPageMessageStatus.reducer");

var loading_reducer_1 = require("./loading.reducer"); // todo replace any type with app level actions


var reducerMap = {
  loading: loading_reducer_1.loadingReducer,
  contactPageMessageStatus: contactPageMessageStatus_reducer_1.contactPageMessageStatusReducer,
  features: features_1.featuresReducer,
  authentication: authentication_1.authReducer,
  user: user_1.userReducer,
  survey: survey_1.surveyReducer,
  content: content_1.contentReducer,
  modules: modules_1.modulesReducer,
  toastNotifications: toast_notifications_1.toastNotificationsReducer
};
exports.rootReducer = redux_1.combineReducers(reducerMap);