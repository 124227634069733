"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldNames;

(function (FieldNames) {
  FieldNames["avatar"] = "avatar";
  FieldNames["firstName"] = "firstName";
  FieldNames["lastName"] = "lastName";
  FieldNames["lastInitial"] = "lastInitial";
  FieldNames["nickName"] = "nickName";
  FieldNames["loginType"] = "loginType";
  FieldNames["dateOfBirth"] = "dateOfBirth";
  FieldNames["email"] = "email";
  FieldNames["emailConfirm"] = "emailConfirm";
  FieldNames["emailValid"] = "emailValid";
  FieldNames["phone"] = "phone";
  FieldNames["phoneNumber"] = "phoneNumber";
  FieldNames["phoneNumberConfirm"] = "phoneNumberConfirm";
  FieldNames["phoneValid"] = "phoneValid";
  FieldNames["password"] = "password";
  FieldNames["passwordConfirm"] = "passwordConfirm";
  FieldNames["accessCode"] = "accessCode";
  FieldNames["termsAccepted"] = "termsAccepted";
  FieldNames["complianceTermsAccepted"] = "complianceTermsAccepted";
  FieldNames["userName"] = "userName";
  FieldNames["code"] = "code";
  FieldNames["country"] = "country";
  FieldNames["userNameValid"] = "userNameValid";
  FieldNames["zipCode"] = "zipCode";
  FieldNames["preferredPronoun"] = "preferredPronoun";
})(FieldNames = exports.FieldNames || (exports.FieldNames = {})); // temp add