"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var enums_1 = require("../../enums");

var actions_1 = require("../actions");

exports.submitSurveyForScoringEpic = function (actions$, state$, _a) {
  var surveyApiService = _a.surveyApiService,
      modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.SurveyActionTypes.SUBMIT_SURVEY_FOR_SCORING), operators_1.mergeMap(function () {
    return submitSurveyForScoringHandler(actions$, state$, surveyApiService, modulesApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var submitSurveyForScoringHandler = function submitSurveyForScoringHandler(actions$, state$, surveyApiService, modulesApiService) {
  // personQuestionnaireId should be the most/least type - we can't score scale survey!
  var _a = state$.value,
      _b = _a.survey.configs,
      questionnaries = _b.questionnaries,
      surveys = _b.surveys,
      _c = _a.modules,
      availableModules = _c.availableModules,
      resultsModuleKey = _c.resultsModule,
      _d = _a.user.profile,
      origAccessCode = _d.OrigAccessCode,
      personId = _d.PersonId;
  var surveyForScoring = surveys[enums_1.SurveyTypeEnum.mostLeast];

  if (!surveyForScoring) {
    return rxjs_1.of(core_1.errorActionCreator({
      message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: core_1.ApplicationErrorTypeEnum.Navigate
    }));
  }

  var AccessCode = surveyForScoring.AccessCode;
  var questionnarie = questionnaries[AccessCode];

  if (!questionnarie) {
    return rxjs_1.of(core_1.errorActionCreator({
      message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: core_1.ApplicationErrorTypeEnum.Navigate
    }));
  }

  var PersonId = questionnarie.PersonId,
      PersonQuestionnaireId = questionnarie.PersonQuestionnaireId;
  var payload = {
    personId: PersonId,
    accessCode: AccessCode,
    personQuestionnaireId: PersonQuestionnaireId,
    rescore: 0
  };
  return surveyApiService.submitSurveyForScoring(payload).pipe(operators_1.mergeMap(function (response) {
    var _a = response.data,
        success = _a.success,
        message = _a.message;
    var moScore = getMOScore(message);

    if (!success) {
      var customError = {
        message: 'Please review your answers and try again.',
        type: core_1.ApplicationErrorTypeEnum.ToastNotification,
        name: ''
      };
      return scoringErrorHandler(customError);
    }

    var resultsModule = utilities_1.get(availableModules.entities, resultsModuleKey);

    if (!resultsModule) {
      return rxjs_1.of(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.error, {}));
    }

    if (origAccessCode) {
      return modulesApiService.unlockModuleWithAccessCode({
        personId: personId,
        module: resultsModuleKey,
        orgCode: origAccessCode
      }).pipe(operators_1.mergeMap(function (unlockModuleResponse) {
        var isSuccess = utilities_1.get(unlockModuleResponse, 'success');

        if (isSuccess) {
          return [modules_1.updateModulePropsActionCreator({
            module: resultsModuleKey,
            props: {
              unlocked: true
            }
          }), actions_1.setSurveyCompletionStatusActionCreator(true), user_1.setUserProfileMoActionCreator(moScore), actions_1.setSurveyUpdatingActionCreator(false), core_1.navigateActionCreator(routes_1.RoutesKeysEnum.results, {})];
        }

        return handleDefaultScoringSuccessCase(resultsModule, moScore);
      }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))));
    } // navigate to the purchase route where the results module should be purchased first


    return handleDefaultScoringSuccessCase(resultsModule, moScore);
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(scoringErrorHandler));
}; /////


function handleDefaultScoringSuccessCase(resultsModule, moScore) {
  var _a;

  var route = routes_1.RoutesKeysEnum.purchaseModule;
  var props = (_a = {}, _a[modules_1.ModulesService.moduleParam] = resultsModule.OutputGroupId, _a);

  if (resultsModule.unlocked) {
    route = routes_1.RoutesKeysEnum.results;
    props = {};
  }

  return [actions_1.setSurveyCompletionStatusActionCreator(true), user_1.setUserProfileMoActionCreator(moScore), actions_1.setSurveyUpdatingActionCreator(false), core_1.navigateActionCreator(route, props)];
}

function getMOScore(message) {
  var res = message.split(' ');
  return res[res.length - 1];
}

function scoringErrorHandler(error) {
  return [core_1.errorActionCreator(error), actions_1.setSurveyUpdatingActionCreator(false)];
}