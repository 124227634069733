"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var Yup = __importStar(require("yup"));

var fieldsValues_1 = require("./fieldsValues");

var subjectOptions = Object.keys(fieldsValues_1.subjectValues).map(function (key) {
  return fieldsValues_1.subjectValues[key].value;
});
exports.ContactFormValidationSchema = Yup.object().shape({
  email: Yup.string().email(core_1.ValidationErrorMessagesEnum.emailValid).required(core_1.ValidationErrorMessagesEnum.email),
  name: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(60, core_1.ValidationErrorMessagesEnum.nameLengthLong).required(core_1.ValidationErrorMessagesEnum.fullName),
  subject: Yup.string().required(core_1.ValidationErrorMessagesEnum.subject).oneOf(subjectOptions, core_1.ValidationErrorMessagesEnum.subject),
  body: Yup.string().min(8, core_1.ValidationErrorMessagesEnum.bodyShort).max(1000, core_1.ValidationErrorMessagesEnum.bodyLong).required(core_1.ValidationErrorMessagesEnum.bodyRequired)
});