"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var react_1 = __importStar(require("react"));

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var components_1 = require("./components");

var SampleDashboard =
/** @class */
function (_super) {
  __extends(SampleDashboard, _super);

  function SampleDashboard() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.description = 'Your personal combination of four powerful modes of mental energy make you a:';

    _this.getDisplayName = function () {
      return 'Sample';
    };

    return _this;
  }

  SampleDashboard.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        _b = _a.screenProps.components,
        WebViewComponent = _b.WebViewComponent,
        IFrameComponent = _b.IFrameComponent,
        navigateTo = _a.navigateTo;
    var TestsTestArray = {
      name: 'Tests',
      title: 'Tests',
      type: 'purchase',
      link: '',
      priceTag: '$5',
      outputGroupId: '41',
      purchased: true,
      description: "=$20 **Do Your Best on Every Test** = []You’ll do your best on tests when you study and take it your way. Only 20% of students are given test-taking advice that actually works for them. Unlock this content for individualized insights on how you can ace your exams."
    };
    var stressTestArray = {
      name: 'Stress',
      title: 'Stress',
      type: 'purchase',
      link: '',
      priceTag: '$5',
      outputGroupId: '32',
      purchased: true,
      description: '=$20 **Student Stress Reducer** = []We get it. Students have a ton of stress. Most of it comes from people trying to make you do it their way. Unlock this content for 20+ Tricks that help you take charge of you.'
    };
    var parentGuideArray = {
      name: 'ParentGuide',
      title: 'ParentGuide',
      type: 'link',
      link: 'https://app.dynamynd.com/printParentGuide.cfm?student_id=fe306a51-f031-43f1-805a-88b276bbc58c',
      priceTag: '$22',
      outputGroupId: '121',
      purchased: true,
      description: "an easy-to-follow online resource that aids parents and caregivers in supporting their child's Authentic Abilities. Parents learn how to recognize their child’s strengths so that they can provide support with solving problems, communication skills, and increasing success in school!"
    };
    var merchandiseArray = {
      name: 'Official Merchandise Store',
      title: 'Official Merchandise Store',
      type: 'link',
      link: 'https://www.bonfire.com/store/kolbe-youth/',
      priceTag: '$0',
      outputGroupId: '144',
      purchased: true,
      description: 'We have shirts for all Authentic Abilities! Now that you’ve discovered yours, show it off!'
    };
    var careersArray = {
      name: 'Careers',
      title: 'Careers',
      type: 'link',
      link: 'https://www.opgig.com/?dynamynd=yes',
      priceTag: '$0',
      outputGroupId: '57',
      purchased: true,
      description: '=$20 **OPgig Career Program** =[]We’ve partnered with OPgig® career program so you can discover your probability of success in 1,200+ careers. Use the link below to visit OPgig.com and receive an 60% discount.'
    };
    var newData = [TestsTestArray, stressTestArray, parentGuideArray, merchandiseArray, careersArray];
    return react_1.default.createElement(content_1.WithAdditionalContent, {
      WebViewComponent: WebViewComponent,
      IFrameComponent: IFrameComponent,
      includeFooter: true
    }, function (_a) {
      var showContent = _a.showContent;
      return react_1.default.createElement(components_lib_1.BackgroundWithShapes, {
        shapes: {
          topLeft: true,
          topRight: true,
          bottomLeft: true,
          bottomRight: true
        }
      }, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
        color: "white",
        version: {
          sm: 'text',
          lg: 'image'
        }
      })), react_1.default.createElement(modules_1.ContentRowResponsive, null, react_1.default.createElement(components_1.ContentRowFixer, null, react_1.default.createElement(components_1.ColumnFlexStartResponsiveLeftDecorated, {
        xl: 50
      }, react_1.default.createElement(components_lib_1.Label, {
        uppercase: true
      }, "Welcome, ", _this.getDisplayName()), react_1.default.createElement(components_1.StrengthsTitle, null, _this.description), react_1.default.createElement(components_1.NaturalAdvantageTitleDecorated, {
        color: '#6F309F'
      }, 'FACILITATOR'), react_1.default.createElement(components_1.ViewResultsButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
        text: "View My Results",
        uppercase: true,
        type: components_lib_1.ButtonTypeEnum.primary,
        onPress: function onPress() {
          return navigateTo(routes_1.RoutesKeysEnum.sampleResults);
        }
      })), react_1.default.createElement(components_1.StrengthsSectionImage, {
        source: {
          uri: components_lib_1.appConfig.images.surveyDashboardSectionStrengths
        }
      })), react_1.default.createElement(components_1.ColumnFlexStartResponsiveRightDecorated, {
        xl: 50
      }, react_1.default.createElement(modules_1.AvailableModules, {
        label: "Explore Modules",
        data: newData,
        displayFilters: true
      })))));
    });
  };

  return SampleDashboard;
}(react_1.Component);

var hoc = redux_1.compose(styled_components_1.withTheme, core_1.withNavigateTo, sharing_1.withAddThisSharing);
exports.default = hoc(SampleDashboard);