"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.getContentForSharingEpic = function (actions$, state$, _a) {
  var contentApiService = _a.contentApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ContentActionTypes.GET_CONTENT_FOR_SHARING), operators_1.mergeMap(function () {
    return getContentForSharingEpicHandler(contentApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

function getContentForSharingEpicHandler(contentApiService) {
  var getContentCalls = [contentApiService.fetchContent(services_1.ContentApiService.pages.sharedModulesDefaultMessage), contentApiService.fetchContent(services_1.ContentApiService.pages.sharedModulesLockMessage)];
  return rxjs_1.forkJoin(getContentCalls).pipe(operators_1.flatMap(function (_a) {
    var defaultMessageData = _a[0],
        sharedModulesLockMessageData = _a[1];

    var _b, _c, _d, _e;

    return rxjs_1.of(actions_1.setContentForSharingActionCreator({
      defaultShareMessage: (_c = (_b = defaultMessageData === null || defaultMessageData === void 0 ? void 0 : defaultMessageData.data) === null || _b === void 0 ? void 0 : _b.content) !== null && _c !== void 0 ? _c : '',
      moduleLockedMessage: (_e = (_d = sharedModulesLockMessageData === null || sharedModulesLockMessageData === void 0 ? void 0 : sharedModulesLockMessageData.data) === null || _d === void 0 ? void 0 : _d.content) !== null && _e !== void 0 ? _e : ''
    }));
  }), operators_1.catchError(core_1.defaultErrorHandler));
}