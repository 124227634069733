"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.getSurveyResponsesEpic = function (actions$, state$, _a) {
  var surveyApiService = _a.surveyApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.SurveyActionTypes.GET_SURVEY_RESPONSES), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.switchMap(function () {
    return services_1.surveyService.getOpenedInformation();
  }, function (action, response) {
    return [action, response];
  }), operators_1.mergeMap(function (_a) {
    var payload = _a[0],
        instruction = _a[1];
    return getSurveyResponsesEpicHandler(actions$, payload, state$, surveyApiService, instruction);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var getSurveyResponsesEpicHandler = function getSurveyResponsesEpicHandler(actions$, payload, state$, surveyApiService, instruction) {
  var config = payload.config,
      questionsOrder = payload.questionsOrder;
  var getSurveyResponsesCalls = [];
  config.forEach(function (c) {
    var userId = c.userId,
        code = c.code,
        questionnaireId = c.questionnaireId;
    getSurveyResponsesCalls.push(surveyApiService.getSurveyResponses(userId, questionnaireId, code));
  });
  return rxjs_1.forkJoin(getSurveyResponsesCalls).pipe(operators_1.flatMap(function (responses) {
    var payloadResponses = [];
    var questionnaries = [];
    responses.forEach(function (response) {
      var data = response.data;
      payloadResponses.push.apply(payloadResponses, data.answers);
      questionnaries.push(data.questionnaire);
    });
    var completedQuestions = services_1.surveyService.getAnsweredQuestionsIds(payloadResponses);
    /*
     * set the active survey code and the activeQuestionId
     */

    var _a = state$.value,
        _b = _a.survey,
        surveys = _b.configs.surveys,
        entities = _b.questionsState.questionsPool.entities,
        PersonId = _a.user.profile.PersonId;
    var activeQuestionId = services_1.surveyService.pickActiveQuestionId(questionsOrder, completedQuestions);
    var activeAccessCode = services_1.surveyService.pickActiveAccessCode(activeQuestionId, surveys);
    var actions = [];
    var questionAlreadyOpen = services_1.surveyService.questionAlreadyOpen(instruction, PersonId, activeQuestionId); // todo refactor the instructions logic

    var questionNeedInstruction = services_1.surveyService.questionNeedInstruction(activeQuestionId, entities);

    if (questionNeedInstruction && !questionAlreadyOpen) {
      var users = instruction.usersId || [];
      users.push(PersonId);
      var questions = instruction.questionId || [];
      questions.push(activeQuestionId);
      actions.push(actions_1.setInstructionStatusCreator(true));
      services_1.surveyService.setInstructionStatus({
        usersId: users,
        questionId: questions
      });
    }

    actions.push(actions_1.setSurveyActiveAccessCodeActionCreator(activeAccessCode), actions_1.setSurveyResponsesActionCreator({
      responses: payloadResponses
    }), actions_1.setQuestionnariesActionCreator({
      questionnaries: questionnaries
    }), actions_1.setSurveyActiveQuestionIdActionCreator(activeQuestionId), actions_1.updateQuestionTakingSessionActionCreator(activeQuestionId), actions_1.setSurveyCompletedQuestionsActionCreator({
      completedQuestions: completedQuestions
    }));
    return actions;
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(core_1.defaultErrorHandler));
};