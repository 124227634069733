"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var actions_1 = require("../actions");

var initialSurveyState_1 = require("../state/initialSurveyState"); // todo actions


exports.configsReducer = function (state, action) {
  if (state === void 0) {
    state = initialSurveyState_1.initialSurveyState.configs;
  }

  if (!action) return state;
  var type = action.type;

  switch (type) {
    case actions_1.SurveyActionTypes.SET_SURVEY_ACCESS_CODES:
      return setSurveyAccessCodesHandler(state, action);

    case actions_1.SurveyActionTypes.SET_SURVEY:
      return setSurveyHandler(state, action);

    case actions_1.SurveyActionTypes.SET_QUESTIONNARIES:
      return setQuestionnariesHandler(state, action);

    case actions_1.SurveyActionTypes.SET_SURVEY_ACTIVE_ACCESS_CODE:
      return setSurveyActiveAccessCodeHandler(state, action);

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return logoutUserHandler(state);

    default:
      return state;
  }
}; /////


function setSurveyAccessCodesHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    accessCodes: __assign(__assign({}, state.accessCodes), {
      codes: __spreadArrays(payload)
    })
  });
}

function setSurveyHandler(state, action) {
  var _a;

  var payload = action.payload;
  var surveyConfig = payload.surveyConfig;
  return __assign(__assign({}, state), {
    surveys: __assign(__assign({}, state.surveys), (_a = {}, _a[surveyConfig.QuestionnaireType] = __assign({}, surveyConfig), _a))
  });
}

function setQuestionnariesHandler(state, action) {
  var questionnaries = action.payload.questionnaries;
  var itteratee = 'AccessCode';
  var transformed = utilities_1.keyBy(questionnaries, itteratee);
  return __assign(__assign({}, state), {
    questionnaries: transformed
  });
}

function setSurveyActiveAccessCodeHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    accessCodes: __assign(__assign({}, state.accessCodes), {
      active: payload
    })
  });
}

function logoutUserHandler(state) {
  var configs = initialSurveyState_1.initialSurveyState.configs;
  return __assign(__assign({}, state), {
    surveys: __assign({}, configs.surveys),
    questionnaries: __assign({}, configs.questionnaries),
    accessCodes: __assign(__assign({}, state.accessCodes), {
      active: configs.accessCodes.active
    })
  });
}