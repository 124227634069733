"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var context_1 = require("../context");

function withResponsiveMeta(WrappedComponent) {
  return (
    /** @class */
    function (_super) {
      __extends(WithResponsiveMeta, _super);

      function WithResponsiveMeta() {
        return _super !== null && _super.apply(this, arguments) || this;
      }

      WithResponsiveMeta.prototype.render = function () {
        var _this = this;

        return react_1.default.createElement(context_1.ResponsiveContext.Consumer, null, function (_a) {
          var width = _a.width,
              height = _a.height,
              activeBreakpoint = _a.activeBreakpoint;
          return react_1.default.createElement(WrappedComponent, __assign({
            width: width,
            height: height,
            activeBreakpoint: activeBreakpoint
          }, _this.props));
        });
      };

      return WithResponsiveMeta;
    }(react_1.Component)
  );
}

exports.withResponsiveMeta = withResponsiveMeta;