"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var SurveyApiService =
/** @class */
function (_super) {
  __extends(SurveyApiService, _super);

  function SurveyApiService() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  SurveyApiService.prototype.getSurveyByAccessCode = function (accessCode) {
    var url = this.baseUrl + "/survey.cfc";
    return this.postForm(url, {
      AccessCode: accessCode,
      method: 'getSurvey'
    });
  };

  SurveyApiService.prototype.getSurveyResponses = function (personId, questionnaireId, accessCode) {
    var url = this.baseUrl + "/personSurvey.cfc";
    return this.postForm(url, {
      personId: personId,
      method: 'getPartialQuestionnaire',
      QuestionnaireId: questionnaireId,
      AccessCode: accessCode
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  SurveyApiService.prototype.recordScaleQuestionResponse = function (payload) {
    var personId = payload.personId,
        questionnaireId = payload.questionnaireId,
        accessCode = payload.accessCode,
        questionId = payload.questionId,
        personQuestionnaireId = payload.personQuestionnaireId,
        scaleValue = payload.scaleValue,
        personFullName = payload.personFullName,
        questionOrder = payload.questionOrder;
    var url = this.baseUrl + "/personSurvey.cfc";
    return this.postForm(url, {
      personId: personId,
      method: 'setPersonQuestionnaireResponse',
      QuestionnaireId: questionnaireId,
      AccessCode: accessCode,
      QuestionId: questionId,
      PersonQuestionnaireId: personQuestionnaireId,
      Response: scaleValue,
      PersonFullName: personFullName,
      QuestionOrder: questionOrder
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  SurveyApiService.prototype.recordMostLeastQuestionResponse = function (payload) {
    var personId = payload.personId,
        questionnaireId = payload.questionnaireId,
        accessCode = payload.accessCode,
        questionId = payload.questionId,
        personQuestionnaireId = payload.personQuestionnaireId,
        mostValue = payload.mostValue,
        leastValue = payload.leastValue,
        personFullName = payload.personFullName,
        questionOrder = payload.questionOrder;
    var url = this.baseUrl + "/personSurvey.cfc";
    return this.postForm(url, {
      personId: personId,
      method: 'setPersonQuestionnaireResponse',
      QuestionnaireId: questionnaireId,
      AccessCode: accessCode,
      QuestionId: questionId,
      PersonQuestionnaireId: personQuestionnaireId,
      Most: mostValue,
      Least: leastValue,
      PersonFullName: personFullName,
      QuestionOrder: questionOrder
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  SurveyApiService.prototype.submitSurveyForScoring = function (payload) {
    var personId = payload.personId,
        accessCode = payload.accessCode,
        personQuestionnaireId = payload.personQuestionnaireId,
        rescore = payload.rescore;
    var url = this.baseUrl + "/personSurvey.cfc";
    return this.postForm(url, {
      personId: personId,
      rescore: rescore,
      method: 'scoreQuestionnaire',
      AccessCode: accessCode,
      PersonQuestionnaireId: personQuestionnaireId
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  return SurveyApiService;
}(api_1.HttpApiWithAuth);

exports.SurveyApiService = SurveyApiService;