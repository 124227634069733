"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var UserActionTypes;

(function (UserActionTypes) {
  UserActionTypes["GET_USER_PROFILE"] = "[user] get user profile";
  UserActionTypes["GET_USER_PROFILE_SUCCESS"] = "[user] get user profile success";
  UserActionTypes["GET_USER_PROFILE_SUCCESS_WITH_REDIRECT"] = "[user] get user profile success and redirect based on profile data";
  UserActionTypes["SET_USER_PROFILE"] = "[user] set user profile to the store";
  UserActionTypes["UPDATE_USER_PROFILE"] = "[user] update the user profile in the db";
  UserActionTypes["SET_USER_PROFILE_MO"] = "[user] set the user MO";
})(UserActionTypes = exports.UserActionTypes || (exports.UserActionTypes = {}));