"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var ContentService =
/** @class */
function () {
  function ContentService() {}

  ContentService.prototype.mapInstructionsToMatchStateModel = function (content) {
    var surveyInstructions = this.getSurveyInstructions(content);
    var otherInstructions = this.getOtherInstructions(content);
    return __spreadArrays(surveyInstructions, otherInstructions);
  };

  ContentService.prototype.getSurveyInstructions = function (data) {
    var accessCodes = data.AccessCodes;
    var keys = Object.keys(accessCodes);
    var instructions = [];
    keys.forEach(function (id) {
      var _a = accessCodes[id],
          title = _a.QuestionnaireType,
          label = _a.directionsLabel,
          content = _a.directions;
      instructions.push({
        id: id,
        title: title,
        label: label,
        content: content
      });
    });
    return instructions;
  };

  ContentService.prototype.getOtherInstructions = function (data) {
    var signInInstructions = data.signInInstructions,
        signUpInstructions = data.signUpInstructions;
    return [{
      id: 'signInInstructions',
      title: 'Sign In',
      content: signInInstructions,
      label: 'Sign In'
    }, {
      id: 'signUpInstructions',
      title: 'Sign Up',
      content: signUpInstructions,
      label: 'Sign Up'
    }];
  };

  ContentService.prototype.getDefaultAvatar = function () {
    return {
      id: 'Default',
      source: components_lib_1.appConfig.icons.defaultAvatar
    };
  };

  ContentService.defaultAvatarKey = 'Default';
  return ContentService;
}();

exports.ContentService = ContentService;
exports.contentService = new ContentService();