"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.setFeatureFlagsActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.FeaturesActionTypes.DYNAMYND_SET_FEATURE_FLAGS
  });
};