"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isUndefined(value) {
  return typeof value === 'undefined';
}

exports.isUndefined = isUndefined;