"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SurveyQuestionTypeEnum;

(function (SurveyQuestionTypeEnum) {
  SurveyQuestionTypeEnum["mostLeast"] = "Most/Least";
  SurveyQuestionTypeEnum["scale"] = "Scale";
})(SurveyQuestionTypeEnum = exports.SurveyQuestionTypeEnum || (exports.SurveyQuestionTypeEnum = {}));