"use strict";

var _a, _b;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var analytics_1 = require("@dynamynd/analytics");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var redux_1 = require("redux");

var redux_observable_1 = require("redux-observable");

var middleware_1 = require("../middleware");

var root_epic_1 = require("./epics/root.epic");

var setupEpicDependencies_1 = require("./epics/setupEpicDependencies");

var root_reducer_1 = require("./reducers/root.reducer");

var initialState_1 = require("./state/initialState");

var surveyTakerAnalyticsActionsMap = (_a = {}, _a[survey_1.SurveyActionTypes.START_SURVEY_TAKING_SESSION] = analytics_1.ActionTypeHandlersEnum.startSurveyTakingSession, _a[survey_1.SurveyActionTypes.SURVEY_TAKER_INACTIVE] = analytics_1.ActionTypeHandlersEnum.endSurveyTakingSession, _a[survey_1.SurveyActionTypes.UPDATE_QUESTION_TAKING_SESSION] = analytics_1.ActionTypeHandlersEnum.handleQuestionSession, _a[survey_1.SurveyActionTypes.RECORD_QUESTION_RESPONSE] = analytics_1.ActionTypeHandlersEnum.setQuestionAnswer, _a[analytics_1.AnalyticsActionTypes.END_ALL_ACTIVE_ANALYTICS_SESSIONS_ON_USER_IDLE] = analytics_1.ActionTypeHandlersEnum.endAllActiveAnalyticsSessionsOnUserIdle, _a[analytics_1.AnalyticsActionTypes.RESUME_ALL_LAST_ACTIVE_ANALYTICS_SESSIONS] = analytics_1.ActionTypeHandlersEnum.resumeAllLastActiveAnalyticsSessionsOnUserActive, _a);
var modulesViewAnalyticsActionsMap = (_b = {}, _b[modules_1.ModulesActionTypes.START_ACTIVE_MODULE_VIEW_SESSION] = analytics_1.ActionTypeHandlersEnum.startModuleViewSession, _b[modules_1.ModulesActionTypes.REVOKE_ACTIVE_MODULE_VIEW_SESSION] = analytics_1.ActionTypeHandlersEnum.endModuleViewSession, _b[modules_1.ModulesActionTypes.MODULE_SECTION_TIPS_AND_TRICKS_IN_VIEW] = analytics_1.ActionTypeHandlersEnum.startModuleSectionTipsAndTricksViewSession, _b[modules_1.ModulesActionTypes.MODULE_SECTION_TIPS_AND_TRICKS_OUT_OF_VIEW] = analytics_1.ActionTypeHandlersEnum.endModuleSectionTipsAndTricksViewSession, _b[modules_1.ModulesActionTypes.TIPS_AND_TRICKS_INTERACTION] = analytics_1.ActionTypeHandlersEnum.moduleTipsAndTricksInteraction, _b[analytics_1.AnalyticsActionTypes.END_ALL_ACTIVE_ANALYTICS_SESSIONS_ON_USER_IDLE] = analytics_1.ActionTypeHandlersEnum.endAllActiveAnalyticsSessionsOnUserIdle, _b[analytics_1.AnalyticsActionTypes.RESUME_ALL_LAST_ACTIVE_ANALYTICS_SESSIONS] = analytics_1.ActionTypeHandlersEnum.resumeAllLastActiveAnalyticsSessionsOnUserActive, _b); // todo correct Actions interface

function setupStore(baseUrl, authUrl) {
  var surveyTakerAnalyticsManager = new analytics_1.SurveyTakerAnalyticsManager(surveyTakerAnalyticsActionsMap);
  var surveyTakerAnalyticsMiddleware = analytics_1.analyticsCreator(surveyTakerAnalyticsManager);
  var modulesAnalyticsManager = new analytics_1.ModulesAnalyticsManager(modulesViewAnalyticsActionsMap);
  var modulesAnalyticsManagerMiddleware = analytics_1.analyticsCreator(modulesAnalyticsManager);
  var epicMiddleware = redux_observable_1.createEpicMiddleware({
    dependencies: setupEpicDependencies_1.setupEpicDependencies(baseUrl, authUrl)
  });
  var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux_1.compose;
  var enhancers = composeEnhancers(redux_1.applyMiddleware(middleware_1.validateAccessToken, surveyTakerAnalyticsMiddleware, modulesAnalyticsManagerMiddleware, epicMiddleware));
  var store = redux_1.createStore(root_reducer_1.rootReducer, initialState_1.initialState, enhancers);
  epicMiddleware.run(root_epic_1.rootEpic);
  return store;
}

exports.setupStore = setupStore;