"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../../store");

var index_1 = require("./index");

var PurchaseWithAccessCode =
/** @class */
function (_super) {
  __extends(PurchaseWithAccessCode, _super);

  function PurchaseWithAccessCode() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.onSubmitHandler = function (_a) {
      var accessCode = _a.accessCode;
      var _b = _this.props,
          moduleId = _b.moduleId,
          personId = _b.personId,
          moduleName = _b.moduleName,
          disclaimerIsChecked = _b.disclaimerIsChecked; // Prevent the submission if disclaimerIsChecked is false

      if (!disclaimerIsChecked) {
        return;
      }

      _this.props.unlockModuleWithAccessCode({
        accessCode: accessCode,
        moduleId: moduleId,
        personId: personId,
        moduleName: moduleName
      });
    };

    return _this;
  }

  PurchaseWithAccessCode.prototype.render = function () {
    var purchasingModulesViewState = this.props.purchasingModulesViewState;
    return react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(index_1.PurchaseWithAccessCodeForm, {
      onSubmit: this.onSubmitHandler,
      loading: purchasingModulesViewState
    }));
  };

  return PurchaseWithAccessCode;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    purchasingModulesViewState: store_1.purchasingModulesViewStateSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    unlockModuleWithAccessCode: redux_1.bindActionCreators(store_1.unlockModuleWithAccessCodeActionCreator, dispatch)
  };
};

var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.default = hoc(PurchaseWithAccessCode);