"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("./../actions/actions");

exports.submitContactPageMessageEpic = function (actions$, state$, _a) {
  var contactPageApiService = _a.contactPageApiService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_SUBMIT_CONTACT_PAGE_MESSAGE), operators_1.mergeMap(function (action) {
    return contactPageApiService.submitContactPageMessage(action.payload).pipe(operators_1.mergeMap(function (response) {
      var success = response.success;

      if (success) {
        return rxjs_1.of(actions_1.setContactPageMessageStatusActionCreator({
          sent: true,
          updating: false
        }));
      }

      var message = 'Unable to send message.';
      return rxjs_1.of(actions_1.setContactPageMessageStatusActionCreator({
        sent: false,
        updating: false
      }), core_1.errorActionCreator({
        message: message,
        type: core_1.ApplicationErrorTypeEnum.ToastNotification
      }));
    }), operators_1.catchError(core_1.defaultErrorHandler));
  }));
};