"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ScreenOrientation_1 = require("./ScreenOrientation");

exports.ScreenOrientation = ScreenOrientation_1.default;

var screenOrientation_enum_1 = require("./screenOrientation.enum");

exports.ScreenOrientationEnum = screenOrientation_enum_1.ScreenOrientationEnum;