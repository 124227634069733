"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SurveyActionTypes;

(function (SurveyActionTypes) {
  SurveyActionTypes["BOOTSTRAP_SURVEY"] = "[survey] bootstrap the survey";
  SurveyActionTypes["SURVEY_TAKER_INACTIVE"] = "[survey] survey taker becomes inactive and the screen would be unmounted";
  SurveyActionTypes["GET_SURVEY_RESPONSES"] = "[survey] get the survey responses";
  SurveyActionTypes["SET_SURVEY"] = "[survey] set a survey to the store";
  SurveyActionTypes["SET_QUESTIONS_ORDER"] = "[survey] set an ordered collection of questions ids";
  SurveyActionTypes["SET_SURVEY_STATUS"] = "[survey] set completed status of the survey";
  SurveyActionTypes["SET_SURVEY_RESULTS_ACTIVE_MODULE"] = "[survey] set the active module for the survey results";
  SurveyActionTypes["SET_SURVEY_QUESTIONS_TOTAL_COUNT"] = "[survey] set the total amount of question";
  SurveyActionTypes["SET_QUESTIONS_POOL"] = "[survey] set the questions pool to the store";
  SurveyActionTypes["SET_ACTIVE_QUESTION_ID"] = "[survey] set the active question id";
  SurveyActionTypes["SET_COMPLETED_QUESTIONS"] = "[survey] set the questions that have responses";
  SurveyActionTypes["SET_NEED_INSTRUCTION"] = "[survey] set need instruction";
  SurveyActionTypes["SET_SURVEY_ACTIVE_ACCESS_CODE"] = "[survey] set the survey active access code";
  SurveyActionTypes["SET_SURVEY_RESPONSES"] = "[survey] set the responses for the survey questions";
  SurveyActionTypes["UPSERT_SURVEY_RESPONSE"] = "[survey] upsert a survey response";
  SurveyActionTypes["SET_QUESTIONNARIES"] = "[survey] set the questionnaries to the state";
  SurveyActionTypes["CHANGE_ACTIVE_QUESTION"] = "[survey] change the active question";
  SurveyActionTypes["RECORD_QUESTION_RESPONSE"] = "[survey] record question response";
  SurveyActionTypes["START_SURVEY_TAKING_SESSION"] = "[survey] start survey taking analytics session";
  SurveyActionTypes["UPDATE_QUESTION_TAKING_SESSION"] = "[survey] update question taking analytics session";
  SurveyActionTypes["SELECT_QUESTION_OPTION"] = "[survey] user selects an option for particular question";
  SurveyActionTypes["SUBMIT_SURVEY_FOR_SCORING"] = "[survey] submit the survey for scoring";
  SurveyActionTypes["SET_SURVEY_RESULTS_ERROR_STATE"] = "[survey] set the error state of the survey results";
  SurveyActionTypes["SET_SURVEY_UPDATING_STATE"] = "[survey] set the updating state of the survey";
  SurveyActionTypes["SET_SURVEY_ACCESS_CODES"] = "[survey] set the surveys access codes";
  SurveyActionTypes["SET_SURVEY_RESULTS_MODULE_CONTENT"] = "[survey] set module content for active module";
})(SurveyActionTypes = exports.SurveyActionTypes || (exports.SurveyActionTypes = {}));