"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var content_parser_1 = require("@dynamynd/content-parser");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../../components");

var store_1 = require("../../../store");

var index_1 = require("./index");

var PurchaseMethodPicker =
/** @class */
function (_super) {
  __extends(PurchaseMethodPicker, _super);

  function PurchaseMethodPicker() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      isAccessCodeFlowActive: false,
      isChecked: false,
      isValid: false,
      checkboxError: 'Please review and accept the Disclaimer to proceed'
    };

    _this.handleComplianceCheckBoxClick = function () {
      _this.setState(function (prevState) {
        var newCheckedState = !prevState.isChecked;
        return {
          isAccessCodeFlowActive: prevState.isAccessCodeFlowActive,
          isValid: prevState.isValid,
          isChecked: newCheckedState,
          checkboxError: newCheckedState ? '' : 'Please review and accept the Disclaimer to proceed'
        };
      });
    };

    _this.validate = function (newCheckedState) {
      return newCheckedState;
    };

    _this.getActiveModule = function () {
      var _a = _this.props,
          moduleId = _a.moduleId,
          availableModules = _a.availableModules;
      var module = utilities_1.pickBy(availableModules, function (m) {
        return m.OutputGroupId === moduleId;
      });
      if (!module) return null;
      var moduleData = utilities_1.values(module)[0];
      return {
        moduleName: Object.keys(module)[0],
        module: moduleData
      };
    };

    _this.accessCodePurchasePressHandler = function () {
      _this.updateIsAccessCodeFlowActiveState();
    };

    _this.purchaseModulePressHandler = function () {
      var _a = _this.props,
          showPaymentModal = _a.showPaymentModal,
          personId = _a.personId,
          moduleId = _a.moduleId;
      var purchaseData = {
        personId: personId,
        outputGroupId: moduleId
      }; // opens the overlay with the purchase module view

      showPaymentModal(content_1.ContentPagesEnum.payment, purchaseData, _this.purchaseCloseActionHandler);
    };

    _this.purchaseCloseActionHandler = function () {
      _this.props.getAvailableModulesWithPurchase(_this.props.moduleId);
    };

    _this.updateIsAccessCodeFlowActiveState = function () {
      return _this.setState(function (prevState) {
        return {
          isAccessCodeFlowActive: !prevState.isAccessCodeFlowActive
        };
      });
    };

    _this.renderContent = function (moduleModel) {
      var _a = _this.props,
          moduleId = _a.moduleId,
          personId = _a.personId;
      var _b = _this.state,
          isAccessCodeFlowActive = _b.isAccessCodeFlowActive,
          isChecked = _b.isChecked;
      var module = moduleModel.module,
          moduleName = moduleModel.moduleName;

      if (isAccessCodeFlowActive) {
        return react_1.default.createElement(index_1.Fixer, {
          val: 28
        }, react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(index_1.ColumnCenteredWithBackground, {
          style: {
            marginRight: '75px',
            marginLeft: '75px'
          }
        }, react_1.default.createElement(components_lib_1.AlignSelf, {
          alignSelf: "flex-end"
        }, react_1.default.createElement(components_lib_1.Button, {
          onPress: _this.updateIsAccessCodeFlowActiveState,
          text: "Close",
          type: components_lib_1.ButtonTypeEnum.link,
          uppercase: true,
          icon: {
            name: 'close',
            position: components_lib_1.ButtonIconPositionEnum.right,
            size: components_lib_1.IconSizeEnum.small
          }
        })), react_1.default.createElement(index_1.PurchaseWithAccessCode, {
          moduleId: moduleId,
          personId: personId,
          moduleName: moduleName,
          disclaimerIsChecked: isChecked
        }))), react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(components_lib_1.RowWrapper, {
          wrap: true,
          justify: 'center'
        }, react_1.default.createElement(components_lib_1.CheckBox, {
          isChecked: _this.state.isChecked,
          action: _this.handleComplianceCheckBoxClick
        }), react_1.default.createElement(components_lib_1.Paragraph, {
          size: components_lib_1.ParagraphSizeEnum.normal
        }, "I agree to the Dynamynd Inc."), react_1.default.createElement(components_lib_1.TextLink, null, "Result access Disclaimer")), _this.state.checkboxError && react_1.default.createElement(components_lib_1.RowWrapper, null, react_1.default.createElement(components_lib_1.Paragraph, {
          size: components_lib_1.ParagraphSizeEnum.small,
          style: {
            color: 'red'
          }
        }, _this.state.checkboxError)), react_1.default.createElement(components_lib_1.RowWrapper, {
          wrap: true,
          justify: 'flex-end',
          align: 'center',
          style: {
            width: '600px'
          }
        }, react_1.default.createElement(components_lib_1.Paragraph, {
          size: components_lib_1.ParagraphSizeEnum.extraSmall
        }, "Disclaimer: The information you provide here, including any Student Aptitude Quiz results generated from that information, will be accessible to Kolbe Corp and may also be accessed by a third party or it\u2019s representatives for which Kolbe Corp created the Access Code you are using. Your use of this site gives your permission for Kolbe Corp, the third party mentioned above, and it\u2019s representatives to access the information you provide. We will not share that information to any additional parties."))));
      }

      return react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(index_1.Padding, null, react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.secondary,
        text: "Access code",
        uppercase: true,
        onPress: _this.accessCodePurchasePressHandler
      })), react_1.default.createElement(index_1.Padding, null, react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.secondary,
        text: "Purchase for $" + module.moduleCost,
        uppercase: true,
        onPress: _this.purchaseModulePressHandler
      })));
    };

    return _this;
  }

  PurchaseMethodPicker.prototype.render = function () {
    var moduleModel = this.getActiveModule();

    if (!moduleModel) {
      return react_1.default.createElement(index_1.RecoverWrongModule, null);
    }

    var module = moduleModel.module;
    var _a = this.props,
        purchasingContactInfo = _a.purchasingContactInfo,
        updatingAvailableModules = _a.updatingAvailableModules;

    if (updatingAvailableModules) {
      return react_1.default.createElement(components_1.LoadingIndicatorModules, null);
    }

    var isAccessCodeFlowActive = this.state.isAccessCodeFlowActive;
    return react_1.default.createElement(index_1.ContentContainer, null, react_1.default.createElement(components_lib_1.TitleWithMargin, null, "Purchase ", module.moduleDisplayName), react_1.default.createElement(components_lib_1.ParagraphWithMargin, {
      size: components_lib_1.ParagraphSizeEnum.large,
      centered: true
    }, react_1.default.createElement(content_parser_1.ParsedContent, {
      content: module.moduleDescription
    })), !isAccessCodeFlowActive && react_1.default.createElement(index_1.Fixer, {
      val: 60
    }, react_1.default.createElement(components_lib_1.ParagraphWithMargin, {
      size: components_lib_1.ParagraphSizeEnum.large
    }, "Select a method for purchasing the ", module.moduleDisplayName, " module")), react_1.default.createElement(components_lib_1.RowFlexStartResponsive, null, this.renderContent(moduleModel)), react_1.default.createElement(index_1.Fixer, {
      val: 24
    }, react_1.default.createElement(components_lib_1.RowCentered, null, react_1.default.createElement(components_lib_1.ParagraphWithMargin, {
      size: components_lib_1.ParagraphSizeEnum.normal
    }, react_1.default.createElement(content_parser_1.ParsedContent, {
      content: purchasingContactInfo
    })))));
  };

  return PurchaseMethodPicker;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    availableModules: store_1.availableModulesSelector(state),
    personId: user_1.userIdSelector(state),
    purchasingContactInfo: store_1.getPurchasingContactInfoSelector(state),
    updatingAvailableModules: store_1.updatingAvailableModulesSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getAvailableModulesWithPurchase: redux_1.bindActionCreators(store_1.getAvailableModulesWithPurchaseActionCreator, dispatch)
  };
};

var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.default = hoc(PurchaseMethodPicker);