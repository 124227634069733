"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SharedQsParamsEnum;

(function (SharedQsParamsEnum) {
  SharedQsParamsEnum["pid"] = "pid";
  SharedQsParamsEnum["mid"] = "mid";
})(SharedQsParamsEnum = exports.SharedQsParamsEnum || (exports.SharedQsParamsEnum = {}));