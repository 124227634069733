"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var features_1 = require("@dynamynd/features");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var utilities_1 = require("@dynamynd/utilities");

var redux_observable_1 = require("redux-observable");

var operators_1 = require("rxjs/operators");

exports.bootstrapApplicationEpic = function (actions$, state, _a) {
  var contentApiService = _a.contentApiService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.BOOTSTRAP_APPLICATION), operators_1.mergeMap(bootstrapApplicationEpicHandler(contentApiService)), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var bootstrapApplicationEpicHandler = function bootstrapApplicationEpicHandler(contentApiService) {
  return function () {
    return contentApiService.bootstrap().pipe(operators_1.mergeMap(function (response) {
      // set home page content
      var data = response.data; /// filtering access codes based on the env prefix - gradually degrade

      var accessCodes = Object.keys(data.AccessCodes);
      var filteredAccessCodes = filterAccessCodes(accessCodes, core_1.environmentManager.getEnvironment());
      var instructions = content_1.contentService.mapInstructionsToMatchStateModel(data);
      return [survey_1.setSurveyAccessCodesActionCreator(filteredAccessCodes), content_1.setInstructionsActionCreator({
        instructions: instructions
      }), features_1.setFeatureFlagsActionCreator({
        payments: true
      })];
    }), operators_1.catchError(core_1.defaultErrorHandler));
  };
};

function filterAccessCodes(accessCodes, env) {
  if (env === core_1.EnvironmentsEnum.production) {
    return accessCodes.filter(function (c) {
      return !c.includes(env);
    });
  }

  var envSpecific = accessCodes.filter(function (m) {
    return utilities_1.startsWith(m, env);
  });

  if (!envSpecific.length) {
    return __spreadArrays(accessCodes);
  }

  return modules_1.modulesService.filterCollectionForEnv(envSpecific, accessCodes);
}