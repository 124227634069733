"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var functions_1 = require("../../screens/PasswordResetScreen/functions");

var actions_1 = require("../actions");

exports.updateUserPasswordEpic = function (actions$, state, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.UPDATE_USER_PASSWORD), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return updateUserPasswordEpicHandler(payload, authApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var updateUserPasswordEpicHandler = function updateUserPasswordEpicHandler(payload, authApiService) {
  return authApiService.updateUserPassword(payload).pipe(operators_1.mergeMap(function () {
    var userMeta = functions_1.validateAndPrepareResetPasswordModel(payload.Username);

    if (!userMeta) {
      return rxjs_1.of(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.login, {}));
    } // login the user


    return [actions_1.loginAttemptActionCreator(__assign(__assign({}, userMeta), {
      password: payload.Password
    }))];
  }), operators_1.catchError(core_1.defaultErrorHandler));
};