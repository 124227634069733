"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PasswordResetScreenStageEnum;

(function (PasswordResetScreenStageEnum) {
  PasswordResetScreenStageEnum["requestLink"] = "requestLink";
  PasswordResetScreenStageEnum["updatePassword"] = "updatePassword";
})(PasswordResetScreenStageEnum = exports.PasswordResetScreenStageEnum || (exports.PasswordResetScreenStageEnum = {}));