"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var reselect_1 = require("reselect");

var getLoading = function getLoading(state) {
  return state.loading;
};

exports.loadingSelector = reselect_1.createSelector(getLoading, function (loading) {
  return loading;
});

var getContactPageMessageState = function getContactPageMessageState(state) {
  return state.contactPageMessageStatus;
};

exports.contactPageMessageSentStatusSelector = reselect_1.createSelector(getContactPageMessageState, function (contactPageMessageStatus) {
  return contactPageMessageStatus;
});