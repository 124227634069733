"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var navigation_service_1 = require("./navigation.service");

exports.navigationService = navigation_service_1.navigationService;

var ContactPageApiService_1 = require("./ContactPageApiService");

exports.ContactPageApiService = ContactPageApiService_1.ContactPageApiService;