"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModuleSectionViewsEnum;

(function (ModuleSectionViewsEnum) {
  ModuleSectionViewsEnum["intro"] = "intro";
  ModuleSectionViewsEnum["overview"] = "overview";
  ModuleSectionViewsEnum["info"] = "info";
  ModuleSectionViewsEnum["firstMo"] = "firstMo";
  ModuleSectionViewsEnum["secondMo"] = "secondMo";
  ModuleSectionViewsEnum["thirdMo"] = "thirdMo";
  ModuleSectionViewsEnum["fourthMo"] = "fourthMo";
})(ModuleSectionViewsEnum = exports.ModuleSectionViewsEnum || (exports.ModuleSectionViewsEnum = {}));