"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var actions_1 = require("../actions");

var state_1 = require("../state");

exports.featuresReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialFeaturesState;
  }

  if (!action) return state;
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case actions_1.FeaturesActionTypes.DYNAMYND_SET_FEATURE_FLAGS:
      return setFeatureFlagsHandler(state, payload);

    default:
      return state;
  }
}; ///


function setFeatureFlagsHandler(state, payload) {
  return __assign(__assign({}, state), payload);
}