"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = require("react");

function useWizard() {
  var _a = react_1.useState(0),
      activeStep = _a[0],
      setActiveStep = _a[1];

  return {
    previousHandler: function previousHandler() {
      setActiveStep(function (prevStep) {
        return prevStep - Number(prevStep >= 1);
      });
    },
    nextHandler: function nextHandler() {
      setActiveStep(function (prevStep) {
        return prevStep + Number(prevStep >= 0);
      });
    },
    activeStep: activeStep,
    setActiveStep: setActiveStep
  };
}

exports.useWizard = useWizard;