"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var ContactPageApiService =
/** @class */
function (_super) {
  __extends(ContactPageApiService, _super);

  function ContactPageApiService() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  ContactPageApiService.prototype.submitContactPageMessage = function (payload) {
    var subject = payload.subject,
        name = payload.name,
        email = payload.email,
        body = payload.body;
    var url = this.baseUrl + "/base.cfc";
    return this.postForm(url, {
      method: 'setContact',
      PersonId: 0,
      Name: name,
      Email: email,
      Subject: subject,
      Message: body
    }, api_1.HttpApi.requestType.withoutCredentials);
  };

  return ContactPageApiService;
}(api_1.HttpApi);

exports.ContactPageApiService = ContactPageApiService;