"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var breakpoints_1 = require("../breakpoints");

var context_1 = require("../context");

var screenOrientation_1 = require("../screenOrientation");

var Responsive =
/** @class */
function (_super) {
  __extends(Responsive, _super);

  function Responsive(props) {
    var _this = _super.call(this, props) || this;

    _this.changeHandler = function (e) {
      var _a = e.window,
          width = _a.width,
          height = _a.height;
      var currentOrientation = _this.state.screenOrientation;

      var nextOrientation = _this.screenOrientation.getScreenOrientation(width, height); // recalculate only if the orientation has been changed


      if (currentOrientation === nextOrientation) {
        // there is an issue on phones - investigate further
        _this.setState(_this.calculateState(width, height, nextOrientation));
      }
    };

    _this.breakpoints = new breakpoints_1.Breakpoints();
    _this.screenOrientation = new screenOrientation_1.ScreenOrientation();

    var _a = react_native_1.Dimensions.get('window'),
        initialWidth = _a.width,
        initialHeight = _a.height;

    _this.state = _this.calculateState(initialWidth, initialHeight, _this.screenOrientation.getScreenOrientation(initialWidth, initialHeight));
    return _this;
  }

  Responsive.prototype.componentDidMount = function () {
    react_native_1.Dimensions.addEventListener('change', this.changeHandler);
  };

  Responsive.prototype.componentWillUnmount = function () {
    react_native_1.Dimensions.removeEventListener('change', this.changeHandler);
  };

  Responsive.prototype.calculateState = function (width, height, orientation) {
    return {
      width: width,
      height: height,
      activeBreakpoint: this.breakpoints.getActiveBreakpoint(width),
      screenOrientation: this.screenOrientation.getScreenOrientation(width, height)
    };
  };

  Responsive.prototype.render = function () {
    var children = this.props.children;
    return react_1.default.createElement(context_1.ResponsiveContext.Provider, {
      value: this.state
    }, children);
  };

  return Responsive;
}(react_1.Component);

exports.default = Responsive;