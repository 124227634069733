"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ActionMetaTypeEnum;

(function (ActionMetaTypeEnum) {
  ActionMetaTypeEnum["apiWithoutCredentials"] = "apiWithoutCredentials";
  ActionMetaTypeEnum["apiWithCredentials"] = "apiWithCredentials";
  ActionMetaTypeEnum["default"] = "default";
})(ActionMetaTypeEnum = exports.ActionMetaTypeEnum || (exports.ActionMetaTypeEnum = {}));