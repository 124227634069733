"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.bootstrapSurveyEpic = function (actions$, state$, _a) {
  var surveyApiService = _a.surveyApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.SurveyActionTypes.BOOTSTRAP_SURVEY), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (_a) {
    var userId = _a.userId;
    return bootstrapSurveyEpicHandler(userId, state$, surveyApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var bootstrapSurveyEpicHandler = function bootstrapSurveyEpicHandler(userId, state$, surveyApiService) {
  var codes = state$.value.survey.configs.accessCodes.codes;
  var getSurveyCalls = [];
  codes.forEach(function (code) {
    return getSurveyCalls.push(surveyApiService.getSurveyByAccessCode(code));
  });
  return rxjs_1.forkJoin(getSurveyCalls).pipe(operators_1.flatMap(function (responses) {
    var totalCount = 0;
    var actions = [];
    var getSurveyResponsesActionConfig = [];
    var questions = [];
    var questionsOrder = [];
    responses.forEach(function (response) {
      var data = response.data;
      totalCount += data.QuestionCount;
      var transformed = services_1.surveyService.questionToArray(data);
      questionsOrder = questionsOrder.concat(services_1.surveyService.getOrderedQuestionsIds(transformed.Questions));
      questions = questions.concat(transformed.Questions);
      actions.push(actions_1.setSurveyActionCreator({
        surveyConfig: transformed
      }));
      getSurveyResponsesActionConfig.push({
        userId: userId,
        questionnaireId: data.QuestionnaireId,
        code: data.AccessCode
      });
    });
    actions.push(actions_1.setQuestionsOrderActionCreator(questionsOrder), actions_1.getSurveyResponsesActionCreator({
      questionsOrder: questionsOrder,
      config: getSurveyResponsesActionConfig
    }), actions_1.setSurveyQuestionsTotalCountActionCreator(totalCount), actions_1.setQuestionsPoolActionCreator(questions));
    return actions;
  }), operators_1.catchError(core_1.defaultErrorHandler));
};