"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var parseHtmlChars_1 = require("../parseHtmlChars");

function parseResultsSummaryContent(content) {
  var contentWithParsedHtmlChars = parseHtmlChars_1.parseHtmlChars(content);

  var _a = contentWithParsedHtmlChars.split('|'),
      quoteWithAuthor = _a[0],
      titleContent = _a[1];

  var _b = quoteWithAuthor.split(' – '),
      quoteContent = _b[0],
      authorContent = _b[1];

  return {
    titleContent: titleContent,
    quoteContent: quoteContent,
    authorContent: authorContent
  };
}

exports.parseResultsSummaryContent = parseResultsSummaryContent;