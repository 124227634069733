"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../store");

function withRefetchUserProfile(WrappedComponent) {
  var WithRefetchUserProfileHOC =
  /** @class */
  function (_super) {
    __extends(WithRefetchUserProfileHOC, _super);

    function WithRefetchUserProfileHOC() {
      return _super !== null && _super.apply(this, arguments) || this;
    }

    WithRefetchUserProfileHOC.prototype.componentDidMount = function () {
      if (this.shouldRefetch()) {
        var activePathName = core_1.locationWebService.getActivePathName();
        this.props.getUserProfile({
          activeRoute: activePathName,
          withRedirect: false
        });
      }
    };

    WithRefetchUserProfileHOC.prototype.shouldRefetch = function () {
      // if the user profile has been loaded -> do nothing
      // if it's still fetching do nothing
      // if it's not fetching and there is no active profile - refetch
      var _a = this.props,
          userId = _a.userId,
          fetching = _a.fetching;
      if (fetching || userId) return false;
      return true;
    };

    WithRefetchUserProfileHOC.prototype.render = function () {
      var _a = this.props,
          userId = _a.userId,
          fetching = _a.fetching,
          rest = __rest(_a, ["userId", "fetching"]);

      return react_1.default.createElement(WrappedComponent, __assign({}, rest));
    };

    return WithRefetchUserProfileHOC;
  }(react_1.Component);

  var mapStateToProps = function mapStateToProps(state) {
    return {
      userId: store_1.userIdSelector(state),
      fetching: store_1.viewStateFetchingSelector(state)
    };
  };

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      getUserProfile: redux_1.bindActionCreators(store_1.getUserProfileActionCreator, dispatch)
    };
  };

  var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
  return hoc(WithRefetchUserProfileHOC);
}

exports.withRefetchUserProfile = withRefetchUserProfile;