"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var Yup = __importStar(require("yup"));

exports.userProfileEditFormValidationSchema = function (withPassword) {
  var shape = {
    avatar: Yup.string().required(core_1.ValidationErrorMessagesEnum.avatar),
    firstName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong).required(core_1.ValidationErrorMessagesEnum.firstName),
    lastName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong).required(core_1.ValidationErrorMessagesEnum.lastName),
    nickName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong).notRequired(),
    gender: Yup.string().notRequired(),
    preferredPronoun: Yup.string().notRequired(),
    ethnicity: Yup.string().notRequired(),
    specialNeeds: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    zipCode: Yup.string().notRequired(),
    educationalGoal: Yup.string().notRequired()
  };

  if (withPassword) {
    shape.currentPassword = Yup.string().notRequired().min(8, core_1.ValidationErrorMessagesEnum.passwordLength);
    shape.password = Yup.string().when('currentPassword', {
      is: function is(v) {
        return !!v;
      },
      then: Yup.string().required(core_1.ValidationErrorMessagesEnum.passwordRequired)
    }).min(8, core_1.ValidationErrorMessagesEnum.passwordLength);
    shape.confirmPassword = Yup.string().when('password', {
      is: function is(v) {
        return !!v;
      },
      then: Yup.string().required(core_1.ValidationErrorMessagesEnum.confirmPasswordRequired),
      otherwise: Yup.string().oneOf(['password'], core_1.ValidationErrorMessagesEnum.passwordShouldMatch)
    }).when('password', {
      is: function is(v) {
        return !!v;
      },
      then: Yup.string().oneOf([Yup.ref('password')], core_1.ValidationErrorMessagesEnum.passwordShouldMatch)
    }).min(8, core_1.ValidationErrorMessagesEnum.passwordLength);
  }

  return Yup.object().shape(shape);
};