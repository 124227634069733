"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actions_1 = require("../actions");

var state_1 = require("../state"); // todo actions


exports.viewReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialModulesState.view;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ModulesActionTypes.GET_AVAILABLE_MODULES:
      return __assign(__assign({}, state), {
        updatingAvailableModules: true
      });

    case actions_1.ModulesActionTypes.GET_AVAILABLE_MODULES_WITH_PURCHASE:
      return __assign(__assign({}, state), {
        updatingAvailableModules: true,
        purchasing: true
      });

    case actions_1.ModulesActionTypes.UNLOCK_MODULE_WITH_ACCESS_CODE:
      return __assign(__assign({}, state), {
        purchasing: true
      });

    case actions_1.ModulesActionTypes.PURCHASING_ERROR:
      return __assign(__assign({}, state), {
        purchasing: false
      });

    case actions_1.ModulesActionTypes.SET_AVAILABLE_MODULES:
      return __assign(__assign({}, state), {
        updatingAvailableModules: false,
        purchasing: false
      });

    case actions_1.ModulesActionTypes.GET_MODULE_DETAILS:
      return __assign(__assign({}, state), {
        updatingAddOnModule: true
      });

    case actions_1.ModulesActionTypes.SET_MODULE_DETAILS:
      return __assign(__assign({}, state), {
        updatingAddOnModule: false
      });

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return __assign({}, state_1.initialModulesState.view);

    default:
      return state;
  }
};