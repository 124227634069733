"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var GutterSizeEnum;

(function (GutterSizeEnum) {
  GutterSizeEnum[GutterSizeEnum["small"] = 12] = "small";
  GutterSizeEnum[GutterSizeEnum["wide"] = 32] = "wide";
})(GutterSizeEnum = exports.GutterSizeEnum || (exports.GutterSizeEnum = {}));