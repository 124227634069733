"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var analytics_1 = require("@dynamynd/analytics");

var authentication_1 = require("@dynamynd/authentication");

var content_1 = require("@dynamynd/content");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var user_1 = require("@dynamynd/user");

var redux_observable_1 = require("redux-observable");

var accessTokenExpired_epic_1 = require("./accessTokenExpired.epic");

var bootstrapApplication_epic_1 = require("./bootstrapApplication.epic");

var bootstrapAvatars_epic_1 = require("./bootstrapAvatars.epic");

var contactPageMessage_epic_1 = require("./contactPageMessage.epic");

var error_epic_1 = require("./error.epic");

var getUserProfileSuccessWithRedirect_epic_1 = require("./getUserProfileSuccessWithRedirect.epic");

var login_epic_1 = require("./login.epic");

var navigate_epic_1 = require("./navigate.epic");

var userIsActive_epic_1 = require("./userIsActive.epic");

var userIsIdle_epic_1 = require("./userIsIdle.epic");

exports.rootEpic = redux_observable_1.combineEpics(accessTokenExpired_epic_1.accessTokenExpiredEpic, bootstrapApplication_epic_1.bootstrapApplicationEpic, bootstrapAvatars_epic_1.bootstrapAvatarsEpic, login_epic_1.loginEpic, userIsActive_epic_1.userIsActiveEpic, userIsIdle_epic_1.userIsIdleEpic, authentication_1.authenticationEpic, analytics_1.analyticsEpic, navigate_epic_1.navigateEpic, survey_1.surveyEpic, modules_1.modulesEpic, user_1.userEpic, error_epic_1.errorEpic, content_1.contentEpic, getUserProfileSuccessWithRedirect_epic_1.getUserProfileSuccessWithRedirectEpic, contactPageMessage_epic_1.submitContactPageMessageEpic);