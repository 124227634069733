"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var Yup = __importStar(require("yup"));

var regEx_1 = require("./regEx");

exports.EmailValidation = Yup.object().shape({
  password: Yup.string().min(8, core_1.ValidationErrorMessagesEnum.passwordLength).required(core_1.ValidationErrorMessagesEnum.passwordRequired)
});
exports.PhoneValidation = Yup.object().shape({
  phone: Yup.string().matches(regEx_1.phoneNumberRegEx, core_1.ValidationErrorMessagesEnum.phoneNumber).required(core_1.ValidationErrorMessagesEnum.phoneNumberRequired),
  password: Yup.string().min(8, core_1.ValidationErrorMessagesEnum.passwordLength).required(core_1.ValidationErrorMessagesEnum.passwordRequired)
});