"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var cdnBaseUrl = process.env.REACT_APP_ASSETS_CDN_URL;
exports.appConfig = {
  layout: {
    main: cdnBaseUrl + "/images/bg-elements.png",
    owlCell: cdnBaseUrl + "/images/dynamynd-survey-results-owl-cell-background.svg",
    owlBeach: cdnBaseUrl + "/images/dynamynd-survey-results-owl-beach-background.svg",
    owlBackground: cdnBaseUrl + "/images/dynamynd-survey-results-owl-background.svg",
    surveyResultBackground: cdnBaseUrl + "/images/dynamynd-survey-results-general-background.svg",
    fourMarkers: cdnBaseUrl + "/images/dynamynd-survey-results-diagram.png",
    survey: cdnBaseUrl + "/images/bg-survey.svg",
    errorPage: cdnBaseUrl + "/images/error-background.svg"
  },
  audio: {
    adventurer: cdnBaseUrl + "/audio/Adventurer.mp3",
    assessor: cdnBaseUrl + "/audio/Assessor.mp3",
    demonstrator: cdnBaseUrl + "/audio/Demonstrator.mp3",
    entrepreneur: cdnBaseUrl + "/audio/Entrepreneur.mp3",
    facilitator: cdnBaseUrl + "/audio/Facilitator.mp3",
    integrator: cdnBaseUrl + "/audio/Integrator.mp3",
    investigator: cdnBaseUrl + "/audio/Investigator.mp3",
    navigator: cdnBaseUrl + "/audio/Navigator.mp3",
    negotiator: cdnBaseUrl + "/audio/Negotiator.mp3",
    organizer: cdnBaseUrl + "/audio/Organizer.mp3",
    originator: cdnBaseUrl + "/audio/Originator.mp3",
    pioneer: cdnBaseUrl + "/audio/Pioneer.mp3",
    protector: cdnBaseUrl + "/audio/Protector.mp3",
    reformer: cdnBaseUrl + "/audio/Reformer.mp3",
    seeker: cdnBaseUrl + "/audio/Seeker.mp3",
    specifier: cdnBaseUrl + "/audio/Specifier.mp3",
    trailblazer: cdnBaseUrl + "/audio/Trailblazer.mp3",
    verifier: cdnBaseUrl + "/audio/Verifier.mp3"
  },
  images: {
    main: cdnBaseUrl + "/images/bg-elements.png",
    popup: cdnBaseUrl + "/images/shape-thought.svg",
    mascot: cdnBaseUrl + "/images/dynamynd-mascot-instructions.svg",
    instructionsBackground: cdnBaseUrl + "/images/instructions-icon-background.svg",
    flaskScale: cdnBaseUrl + "/images/survey-results-section-flask-scale.svg",
    surveyResultsSpeechBubble: cdnBaseUrl + "/images/dynamynd-speach-bubble.svg",
    surveyResultsResumeSectionShape: cdnBaseUrl + "/images/survey-results-resume-section-shape.svg",
    surveyResultsResumeSectionOwl: cdnBaseUrl + "/images/survey-results-resume-section-owl.svg",
    surveyResultsIntroSectionShape: cdnBaseUrl + "/images/survey-results-intro-section-shape.svg",
    surveyResultsIntroSectionOwl: cdnBaseUrl + "/images/survey-results-intro-section-owl.svg",
    surveyDashboardSectionStrengths: cdnBaseUrl + "/images/survey-dashboard-section-strengths.svg",
    addOnModulesIntroSectionOwl: cdnBaseUrl + "/images/add-ons-modules-intro-section-owl.svg",
    addOnsModulesSummarySectionOwl: cdnBaseUrl + "/images/add-ons-modules-summary-section-owl.svg",
    addOnsModulesSummarySectionShape: cdnBaseUrl + "/images/add-ons-modules-summary-section-shape.svg",
    addOnsModulesOwlThought: cdnBaseUrl + "/images/add-ons-modules-owl-thoughts.svg"
  },
  icons: {
    defaultAvatar: cdnBaseUrl + "/images/avatar-default.svg",
    mascot: cdnBaseUrl + "/images/dynamynd-mascot-rotated.svg",
    arrowUp: cdnBaseUrl + "/images/dynamynd-survey-results-arrow-up.svg",
    arrowSurveyArrowRight: cdnBaseUrl + "/images/dynamynd-survey-results-arrow-right.svg",
    party: cdnBaseUrl + "/images/dynamynd-icon-party.png",
    flagUsCaMixed: cdnBaseUrl + "/images/dynamynd-icon-flag-us-ca-mixed.png"
  },
  logos: {
    logoPurple: cdnBaseUrl + "/images/student-aptitude-quiz-logo-purple.svg",
    logoWhite: cdnBaseUrl + "/images/student-aptitude-quiz-logo-white.svg",
    logoText: cdnBaseUrl + "/images/student-aptitude-quiz-logo-white-text.svg"
  },
  shapes: {
    landingBottomLeft: cdnBaseUrl + "/images/bg-landing-shape-bottom-left.svg",
    landingBottomRight: cdnBaseUrl + "/images/bg-landing-shape-bottom-right.svg",
    landingTopLeft: cdnBaseUrl + "/images/bg-landing-shape-top-left.svg",
    landingTopRight: cdnBaseUrl + "/images/bg-landing-shape-top-right.svg",
    authLayout: cdnBaseUrl + "/images/dynamynd-shape-owl-auth-layout-background.svg",
    userProfile: cdnBaseUrl + "/images/user-profile-owl.svg",
    swiperFf: cdnBaseUrl + "/images/dynamynd-swiper-ff.svg",
    swiperFt: cdnBaseUrl + "/images/dynamynd-swiper-ft.svg",
    swiperQs: cdnBaseUrl + "/images/dynamynd-swiper-qs.svg",
    swiperIm: cdnBaseUrl + "/images/dynamynd-swiper-im.svg"
  }
};