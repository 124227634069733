"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actions_1 = require("../actions");

var state_1 = require("./../state"); // todo actions


exports.activeModuleReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialModulesState.activeModule;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ModulesActionTypes.SET_ACTIVE_MODULE:
      return action.payload;

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return state_1.initialModulesState.activeModule;

    default:
      return state;
  }
};