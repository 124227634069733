"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var countries_1 = require("./countries");

exports.genderValues = {
  male: {
    value: 'male',
    label: 'Male'
  },
  female: {
    value: 'female',
    label: 'Female'
  },
  other: {
    value: 'other',
    label: 'Other'
  },
  preferNotToSay: {
    value: 'preferNotToSay',
    label: 'Prefer not to say'
  }
};
exports.preferredPronounValues = {
  feminine: {
    value: 'feminine',
    label: 'Feminine: She, her, hers'
  },
  masculine: {
    value: 'masculine',
    label: 'Masculine: He, him, his'
  },
  neutral: {
    value: 'neutral',
    label: 'Gender Neutral: They, them, their'
  }
};
exports.countryValues = mapCountries(countries_1.countries);
exports.usaFormFieldValue = 'unitedStates';
exports.ethnicityValues = {
  americanIndianAlaskaNative: {
    value: 'americanIndianAlaskaNative',
    label: 'American Indian or Alaska Native'
  },
  asian: {
    value: 'asian',
    label: 'Asian'
  },
  blackOrAfricanAmerican: {
    value: 'blackOrAfricanAmerican',
    label: 'Black or African American'
  },
  latino: {
    value: 'latino',
    label: 'Hispanic or Latino'
  },
  hawaiian: {
    value: 'hawaiian',
    label: 'Native Hawaiian or Other Pacific Islander'
  },
  white: {
    value: 'white',
    label: 'White'
  },
  preferNotToSay: {
    value: 'preferNotToSay',
    label: 'Prefer not to say'
  }
};
exports.specialNeedsValues = {
  add: {
    value: 'add',
    label: 'ADD'
  },
  adhd: {
    value: 'adhd',
    label: 'ADHD'
  },
  ocd: {
    value: 'ocd',
    label: 'OCD'
  },
  autistic: {
    value: 'autistic',
    label: 'Autistic'
  },
  dyslexic: {
    value: 'dyslexic',
    label: 'Dyslexic'
  },
  hearing: {
    value: 'hearing',
    label: 'Hearing'
  },
  physical: {
    value: 'physical',
    label: 'Physical'
  },
  neurological: {
    value: 'neurological',
    label: 'Neurological'
  },
  gifted: {
    value: 'gifted',
    label: 'Gifted'
  }
};
exports.educationalGoalValues = {
  middleSchool: {
    value: 'middleSchool',
    label: 'Middle School'
  },
  highSchool: {
    value: 'highSchool',
    label: 'High School'
  },
  tradeSchool: {
    value: 'tradeSchool',
    label: 'Trade School'
  },
  collegeUniversity: {
    value: 'collegeUniversity',
    label: 'College/University'
  },
  postGraduate: {
    value: 'postGraduate',
    label: 'Post Graduate'
  },
  militaryProfessionalTraining: {
    value: 'militaryProfessionalTraining',
    label: 'Military/Professional Training'
  }
}; ////

function mapCountries(countriesList) {
  return countriesList.reduce(function (accumulator, current) {
    var v = utilities_1.camelCase(current);
    accumulator[v] = {
      value: v,
      label: current
    };
    return accumulator;
  }, {});
}