"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.accessTokenExpiredEpic = function (actions$, state, _a) {
  var toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.ACCESS_TOKEN_EXPIRED), operators_1.mergeMap(accessTokenExpiredEpicHandler(toastNotificationService)), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var accessTokenExpiredEpicHandler = function accessTokenExpiredEpicHandler(toastNotificationService) {
  return function () {
    return rxjs_1.of(core_1.logoutUserActionCreator(), toastNotificationService.infoAction(core_1.ApplicationMessagesEnum.AccessTokenExpiredMessage));
  };
};