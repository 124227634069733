"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var EnvironmentsEnum;

(function (EnvironmentsEnum) {
  EnvironmentsEnum["test"] = "test";
  EnvironmentsEnum["production"] = "production";
})(EnvironmentsEnum = exports.EnvironmentsEnum || (exports.EnvironmentsEnum = {}));