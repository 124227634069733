"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

var core_1 = require("@dynamynd/core");

var store_1 = require("../store"); // todo types


exports.validateAccessToken = function (store) {
  return function (next) {
    return function (action) {
      var isApiWithCredentials = isApiWithCredentialsType(action);

      if (isApiWithCredentials && authentication_1.authService.isTokenExpired()) {
        return next(store_1.accessTokenExpiredActionCreator());
      }

      return next(action);
    };
  };
};

function isApiWithCredentialsType(action) {
  var type = action.meta.type;
  if (!type) return false;
  return type === core_1.ActionMetaTypeEnum.apiWithCredentials;
}