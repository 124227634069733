"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.htmlCharsMap = [{
  char: '&amp;',
  replaceWith: '&'
}, {
  char: '&nbsp;',
  replaceWith: ' '
}];