"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function calculateHeight(screenHeight, gutterSize, headerHeight, footerHeight) {
  var paddingTop = gutterSize;
  var paddingBottom = gutterSize;
  return screenHeight - headerHeight - paddingTop - paddingBottom - footerHeight;
}

exports.calculateHeight = calculateHeight;