"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var RenderError_1 = require("../RenderError/RenderError");

var FormField =
/** @class */
function (_super) {
  __extends(FormField, _super);

  function FormField() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  FormField.prototype.render = function () {
    var _a = this.props,
        errors = _a.errors,
        touched = _a.touched,
        isValid = _a.isValid,
        children = _a.children,
        name = _a.name;
    var errorText = errors[name];
    var error = touched[name] && !isValid;
    return react_1.default.createElement(react_1.default.Fragment, null, children, error && react_1.default.createElement(RenderError_1.RenderError, {
      errorText: errorText
    }));
  };

  return FormField;
}(react_1.Component);

exports.FormField = FormField;