"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function first(collection) {
  return collection[0];
}

exports.first = first;