"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var LoginTypeEnum;

(function (LoginTypeEnum) {
  LoginTypeEnum["userName"] = "username";
  LoginTypeEnum["phone"] = "phone";
  LoginTypeEnum["email"] = "email";
})(LoginTypeEnum = exports.LoginTypeEnum || (exports.LoginTypeEnum = {}));