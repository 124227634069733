"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var htmlCharsMap_1 = require("./htmlCharsMap");

function parseHtmlChars(content) {
  var resultContent = content || '';
  htmlCharsMap_1.htmlCharsMap.forEach(function (charValuePair) {
    var _char = charValuePair.char,
        replaceWith = charValuePair.replaceWith;
    resultContent = resultContent.replace(_char, replaceWith);
  });
  return resultContent;
}

exports.parseHtmlChars = parseHtmlChars;