"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

function shouldRedirectAuth() {
  return authentication_1.authService.isAuthenticated();
}

exports.shouldRedirectAuth = shouldRedirectAuth;