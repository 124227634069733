"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ElementsEnum;

(function (ElementsEnum) {
  ElementsEnum["listItem"] = "listItem";
  ElementsEnum["paragraph"] = "paragraph";
})(ElementsEnum = exports.ElementsEnum || (exports.ElementsEnum = {}));