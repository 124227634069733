"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

var React = __importStar(require("react"));

var app_1 = require("../../../config/app");

var backgrounds_styled_1 = require("../styled/backgrounds.styled");

var bottomRightContainerImageSizes = {
  width: 178,
  height: 155
};

var UserProfileBackground =
/** @class */
function (_super) {
  __extends(UserProfileBackground, _super);

  function UserProfileBackground() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.renderBottomRightImage = function () {
      var activeBreakpoint = _this.props.activeBreakpoint;

      if (responsive_1.isSmallOrMedium(activeBreakpoint)) {
        return null;
      }

      return React.createElement(backgrounds_styled_1.BottomRightContainerWithStaticSizes, {
        width: bottomRightContainerImageSizes.width,
        height: bottomRightContainerImageSizes.height,
        source: {
          uri: app_1.appConfig.shapes.userProfile
        }
      });
    };

    return _this;
  }

  UserProfileBackground.prototype.render = function () {
    var _a = this.props,
        children = _a.children,
        height = _a.height,
        activeBreakpoint = _a.activeBreakpoint;
    return React.createElement(backgrounds_styled_1.BackgroundContainer, {
      height: height,
      activeBreakpoint: activeBreakpoint,
      backgroundColor: "white"
    }, this.renderBottomRightImage, children);
  };

  return UserProfileBackground;
}(React.Component);

exports.default = responsive_1.withResponsiveMeta(UserProfileBackground);