"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var getContentForSharing_epic_1 = require("./getContentForSharing.epic");

var pageContent_epic_1 = require("./pageContent.epic");

exports.contentEpic = redux_observable_1.combineEpics(pageContent_epic_1.pageContentEpic, getContentForSharing_epic_1.getContentForSharingEpic);