"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var enums_1 = require("../enums");

var modulesColors = {
  red: '#FF5348',
  green: '#00BF6C',
  blue: '#4B91EB',
  yellow: '#FFC73A',
  purple: '#6F309F'
};
var ModulesPropertyNamesEnum;

(function (ModulesPropertyNamesEnum) {
  ModulesPropertyNamesEnum["naturalAdvantageTitle"] = "naturalAdvantageTitle";
  ModulesPropertyNamesEnum["naturalAdvantageHeader"] = "naturalAdvantageHeader";
  ModulesPropertyNamesEnum["naturalAdvantageDescription"] = "naturalAdvantageDescription";
  ModulesPropertyNamesEnum["strengthsDescriptions"] = "strengthsDescriptions";
  ModulesPropertyNamesEnum["strengthsTitles"] = "strengthsTitles";
  ModulesPropertyNamesEnum["strengthsHeaders"] = "strengthsHeaders";
  ModulesPropertyNamesEnum["tipsAndTricks"] = "tipsAndTricks";
  ModulesPropertyNamesEnum["popUpTip"] = "popUpTip";
  ModulesPropertyNamesEnum["sharing"] = "sharing";
})(ModulesPropertyNamesEnum || (ModulesPropertyNamesEnum = {}));

var red = modulesColors.red,
    green = modulesColors.green,
    blue = modulesColors.blue,
    yellow = modulesColors.yellow,
    purple = modulesColors.purple;

var ModulesService =
/** @class */
function () {
  function ModulesService() {
    var _a;

    this.naturalAdvantageProp = 'naturalAdvantageTitle';
    this.alternativeNaturalAdvantageProp = 'NaturalAdvantageTitle';
    this.naturalAdvantageColorMap = {
      Specifier: red,
      Navigator: red,
      Negotiator: red,
      Verifier: red,
      Organizer: blue,
      Assessor: blue,
      Trailblazer: blue,
      Integrator: blue,
      Originator: green,
      Entrepreneur: green,
      Reformer: green,
      Pioneer: green,
      Demonstrator: yellow,
      Investigator: yellow,
      Protector: yellow,
      Adventurer: yellow,
      Coordinator: purple,
      Seeker: purple,
      Facilitator: purple
    };
    this.modulesKeys = (_a = {}, _a[ModulesPropertyNamesEnum.naturalAdvantageTitle] = /naturaladvantagetitle|natural advantage title/i, _a[ModulesPropertyNamesEnum.naturalAdvantageHeader] = /naturaladvantageheader/i, _a[ModulesPropertyNamesEnum.naturalAdvantageDescription] = /naturaladvantagedescription/i, _a[ModulesPropertyNamesEnum.strengthsDescriptions] = /strengthsdescription|strengthsgeneraldescription/i, _a[ModulesPropertyNamesEnum.strengthsTitles] = /strengthstitles|strengthstitle/i, _a[ModulesPropertyNamesEnum.strengthsHeaders] = /strengthsheader|strengthsheaders/i, _a[ModulesPropertyNamesEnum.tipsAndTricks] = /tips/i, _a[ModulesPropertyNamesEnum.popUpTip] = /differentPeople|different people/i, _a[ModulesPropertyNamesEnum.sharing] = /share|sharing/i, _a);
  }

  ModulesService.prototype.mapModulesViewData = function (ordered, entities) {
    var entitiesViewModel = ordered.reduce(function (accumulator, currentValue) {
      var entity = entities[currentValue];
      accumulator.push({
        name: currentValue,
        title: currentValue,
        type: entity.isLink ? enums_1.ModuleTypeEnum.link : enums_1.ModuleTypeEnum.purchase,
        link: entity.Link,
        priceTag: "$" + entity.moduleCost,
        description: entity.moduleDescription,
        purchased: entity.unlocked,
        outputGroupId: entity.OutputGroupId
      });
      return accumulator;
    }, []);
    return entitiesViewModel;
  };

  ModulesService.prototype.getNaturalAdvantageValue = function (resultsModule) {
    var _this = this;

    var module = resultsModule.module;
    if (!module) return '';
    var prop = Object.keys(module).filter(function (k) {
      var key = k.split(' ').join('');
      return key === _this.naturalAdvantageProp || key === _this.alternativeNaturalAdvantageProp;
    })[0];
    return module[prop] ? module[prop].natAdvOuput : '';
  }; // todo refactor heavily!


  ModulesService.prototype.mapViewModel = function (moduleDetails, moduleName, resultsModuleName, activeModuleData) {
    var _this = this;

    var _a;

    var module = moduleDetails.module,
        colored4Tubes = moduleDetails.colored4Tubes,
        seperate4Tubes = moduleDetails.seperate4Tubes,
        content = moduleDetails.content; /// ???

    var moduleResponseParsedData = Object.keys(module).reduce(function (moduleState, key) {
      var serializedKey = key.toLocaleLowerCase().replace(/\s/g, '');
      Object.keys(_this.modulesKeys).forEach(function (prop) {
        if (_this.modulesKeys[prop].test(serializedKey)) {
          moduleState[prop] = module[key];
        }
      });
      return moduleState;
    }, {});
    var _b = moduleResponseParsedData,
        _c = ModulesPropertyNamesEnum.naturalAdvantageTitle,
        naturalAdvantageTitle = _b[_c],
        _d = ModulesPropertyNamesEnum.naturalAdvantageHeader,
        naturalAdvantageHeader = _b[_d],
        _e = ModulesPropertyNamesEnum.naturalAdvantageDescription,
        naturalAdvantageDescription = _b[_e],
        _f = ModulesPropertyNamesEnum.strengthsDescriptions,
        strengthsDescriptions = _b[_f],
        _g = ModulesPropertyNamesEnum.strengthsTitles,
        strengthsTitles = _b[_g],
        _h = ModulesPropertyNamesEnum.strengthsHeaders,
        strengthsHeaders = _b[_h],
        _j = ModulesPropertyNamesEnum.tipsAndTricks,
        tipsAndTricks = _b[_j],
        _k = ModulesPropertyNamesEnum.popUpTip,
        popUpTipData = _b[_k],
        _l = ModulesPropertyNamesEnum.sharing,
        sharing = _b[_l];
    var userModes = naturalAdvantageTitle.userMo,
        natAdvOuput = naturalAdvantageTitle.natAdvOuput,
        contentDisplayOrderString = naturalAdvantageTitle.contentDisplayOrder;
    var contentDisplayOrder = contentDisplayOrderString.split('/');
    var description = naturalAdvantageDescription.natAdvOuput;
    var screenContent = this.mapModuleContent(content);
    var moduleHeaders = this.getModuleSectionHeaders(screenContent, strengthsHeaders, moduleName, resultsModuleName);
    var mainFourModes = contentDisplayOrder.reduce(function (mainFourModesData, mode, index) {
      var popUpTip = '';

      if (popUpTipData) {
        popUpTip = popUpTipData[mode + "Output"];
      }

      var _a = tipsAndTricks,
          _b = mode + "Output",
          tips = _a[_b];

      var _c = strengthsTitles,
          _d = mode + "Output",
          title = _c[_d];

      var header = moduleHeaders.length > 0 ? moduleHeaders[index] : strengthsHeaders[mode + "Output"];

      var _e = strengthsDescriptions,
          _f = mode + "Output",
          modeDescription = _e[_f];

      mainFourModesData[mode] = {
        tips: tips,
        title: title,
        header: header,
        popUpTip: popUpTip,
        description: modeDescription
      };
      return mainFourModesData;
    }, {});
    var sharingMessage = (_a = sharing === null || sharing === void 0 ? void 0 : sharing.natAdvOuput) !== null && _a !== void 0 ? _a : '';
    var introImageUri = activeModuleData ? activeModuleData.ModuleImageLink : '';
    return __assign({
      moduleName: moduleName,
      naturalAdvantageTitle: naturalAdvantageTitle,
      description: description,
      userModes: userModes,
      mainFourModes: mainFourModes,
      contentDisplayOrder: contentDisplayOrder,
      colored4Tubes: colored4Tubes,
      seperate4Tubes: seperate4Tubes,
      introImageUri: introImageUri,
      sharingMessage: sharingMessage,
      moduleId: activeModuleData.moduleID,
      type: natAdvOuput,
      naturalAdvantageHeader: naturalAdvantageHeader ? naturalAdvantageHeader : {},
      popUpTip: popUpTipData ? popUpTipData : ''
    }, screenContent);
  };

  ModulesService.prototype.getModuleSectionHeaders = function (content, strengthsHeaders, moduleName, resultsModuleName) {
    /// todo refactor!!!!!!!!!!!
    if (resultsModuleName && moduleName.toLowerCase().includes(resultsModuleName.toLowerCase()) || !strengthsHeaders) {
      var labels = content[enums_1.ModulesContentPagesEnum.moduleLabels];
      return labels ? labels.split('|') : [];
    }

    var contentDisplayOrder = strengthsHeaders.contentDisplayOrder;
    return contentDisplayOrder.split('/').map(function (m) {
      return strengthsHeaders[m + "Output"];
    });
  };

  ModulesService.prototype.mapModuleContent = function (content) {
    var moduleMap = ModulesService.modulesContentOrder;

    if (utilities_1.isEmpty(content)) {
      return {
        introSection: '',
        overviewSection: '',
        infoSection: '',
        moduleLabels: '',
        summarySection: ''
      };
    }

    return Object.keys(content).reduce(function (accumulator, currentValue, idx) {
      var entityContent = content[currentValue];
      accumulator[moduleMap[idx]] = entityContent;
      return accumulator;
    }, {});
  };

  ModulesService.prototype.getModuleColors = function (base, next, isFirst, isLast) {
    var baseColorConfig = ModulesService.modulesSectionsColors[base];

    if (isLast) {
      return {
        background: "linear-gradient(0deg,#FFF5D7 0%," + baseColorConfig.background + " 6%)",
        tips: baseColorConfig.tips
      };
    }

    var nextColorConfig = ModulesService.modulesSectionsColors[next];

    if (isFirst) {
      return {
        background: "linear-gradient(0deg," + nextColorConfig.background + " 0%," + baseColorConfig.background + " 6%)",
        tips: baseColorConfig.tips
      };
    }

    return {
      background: "linear-gradient(0deg," + nextColorConfig.background + " 0%," + baseColorConfig.background + " 6%)",
      tips: baseColorConfig.tips
    };
  };

  ModulesService.prototype.getNaturalAdvantageTitleColor = function (type) {
    var defaultColor = '#ef3a33';
    if (!type || typeof type !== 'string') return defaultColor;
    var color = this.naturalAdvantageColorMap[utilities_1.capitalize(type)];
    if (!color) return '#ef3a33';
    return color;
  };

  ModulesService.prototype.getNaturalAdvantageAudioKey = function (type) {
    if (!type || typeof type !== 'string') return '';
    return type.toLowerCase();
  };

  ModulesService.prototype.filterAvailableModulesPerEnv = function (modules, env) {
    var filtered = this.filterModulesHandler(Object.keys(modules), env);
    return filtered.reduce(function (accumulator, currentValue) {
      var entity = modules[currentValue];
      accumulator[currentValue] = entity;
      return accumulator;
    }, {});
  };

  ModulesService.prototype.filterModulesHandler = function (modules, env) {
    if (env === core_1.EnvironmentsEnum.production) {
      return modules.filter(function (m) {
        return !utilities_1.startsWith(m, core_1.EnvironmentsEnum.test);
      });
    }

    var envSpecific = modules.filter(function (m) {
      return utilities_1.startsWith(m, env);
    });

    if (!envSpecific.length) {
      return __spreadArrays(modules);
    }

    return this.filterCollectionForEnv(envSpecific, modules);
  };

  ModulesService.prototype.filterCollectionForEnv = function (envSpecific, all) {
    var toRemove = envSpecific.reduce(function (accumulator, currentValue) {
      var c = currentValue.split('-')[1];
      accumulator.push(c);
      return accumulator;
    }, []);
    return all.filter(function (m) {
      return !toRemove.includes(m);
    });
  };

  ModulesService.prototype.setResultsModule = function (modules, resultsModule, env) {
    if (env === core_1.EnvironmentsEnum.production) {
      return resultsModule;
    }

    var keys = Object.keys(modules);
    var testModule = env + "-" + resultsModule;

    if (keys.includes(testModule)) {
      return testModule;
    }

    return resultsModule;
  };

  ModulesService.modulesSectionsColors = {
    ff: {
      background: '#FFDDDA',
      tips: red
    },
    ft: {
      background: '#DBE9F9',
      tips: blue
    },
    qs: {
      background: '#CCF2E1',
      tips: green
    },
    im: {
      background: '#FFF5D7',
      tips: yellow
    }
  };
  ModulesService.modulesContentOrder = [enums_1.ModulesContentPagesEnum.introSection, enums_1.ModulesContentPagesEnum.overviewSection, enums_1.ModulesContentPagesEnum.infoSection, enums_1.ModulesContentPagesEnum.moduleLabels, enums_1.ModulesContentPagesEnum.summarySection];
  ModulesService.moduleParam = 'module';
  return ModulesService;
}();

exports.ModulesService = ModulesService;
exports.modulesService = new ModulesService();