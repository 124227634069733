"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var reselect_1 = require("reselect");

var getUserProfile = function getUserProfile(state) {
  return state.user.profile;
};

var getViewState = function getViewState(state) {
  return state.user.view;
};

exports.userIdSelector = reselect_1.createSelector(getUserProfile, function (profile) {
  return profile.PersonId;
});
exports.userUUIIdSelector = reselect_1.createSelector(getUserProfile, function (profile) {
  return profile.ID;
});
exports.userOrigAccessCodeSelector = reselect_1.createSelector(getUserProfile, function (profile) {
  return profile.OrigAccessCode;
});
exports.userMOSelector = reselect_1.createSelector(getUserProfile, function (profile) {
  return profile.MO;
});
exports.userProfileSelector = reselect_1.createSelector(getUserProfile, function (profile) {
  return profile;
});
exports.viewStateFetchingSelector = reselect_1.createSelector(getViewState, function (viewState) {
  return viewState.fetching;
});