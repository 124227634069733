"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.errorEpic = function (actions$, state, _a) {
  var navigationService = _a.navigationService,
      toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_ERROR), operators_1.mergeMap(errorEpicHandler(navigationService, toastNotificationService)));
}; //////////


var errorEpicHandler = function errorEpicHandler(navigationService, toastNotificationService) {
  return function (_a) {
    var payload = _a.payload;
    var message = payload.message,
        type = payload.type;

    if (type === core_1.ApplicationErrorTypeEnum.Navigate) {
      navigationService.navigate(routes_1.RoutesKeysEnum.error, {});
    }

    return rxjs_1.of(toastNotificationService.errorAction(message));
  };
};