"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var analytics_1 = require("@dynamynd/analytics");

var authentication_1 = require("@dynamynd/authentication");

var content_1 = require("@dynamynd/content");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var toast_notifications_1 = require("@dynamynd/toast-notifications");

var user_1 = require("@dynamynd/user");

var services_1 = require("../../services");

exports.setupEpicDependencies = function (baseUrl, authUrl) {
  return {
    navigationService: services_1.navigationService,
    userApiService: new user_1.UserApiService(baseUrl, authentication_1.authService),
    contentApiService: new content_1.ContentApiService(baseUrl),
    surveyApiService: new survey_1.SurveyApiService(baseUrl, authentication_1.authService),
    authApiService: new authentication_1.AuthApiService(baseUrl, authUrl),
    toastNotificationService: new toast_notifications_1.ToastNotificationService(),
    modulesApiService: new modules_1.ModulesApiService(baseUrl, authentication_1.authService),
    contactPageApiService: new services_1.ContactPageApiService(baseUrl),
    analyticsApiService: new analytics_1.AnalyticsApiService(baseUrl, authentication_1.authService)
  };
};