"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorStateEnum;

(function (ErrorStateEnum) {
  ErrorStateEnum["error"] = "error";
  ErrorStateEnum["noError"] = "noError";
})(ErrorStateEnum = exports.ErrorStateEnum || (exports.ErrorStateEnum = {}));