"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var EnvironmentManager =
/** @class */
function () {
  function EnvironmentManager() {
    this.envName = 'REACT_APP_ENV';
    this.environment = process.env[this.envName];
  }

  EnvironmentManager.getInstance = function () {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }

    return EnvironmentManager.instance;
  };

  EnvironmentManager.prototype.getEnvironment = function () {
    return this.environment;
  };

  return EnvironmentManager;
}();

exports.EnvironmentManager = EnvironmentManager;
exports.environmentManager = EnvironmentManager.getInstance();