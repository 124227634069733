"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModulesActionTypes;

(function (ModulesActionTypes) {
  ModulesActionTypes["GET_AVAILABLE_MODULES"] = "[modules] get all available modules";
  ModulesActionTypes["GET_AVAILABLE_MODULES_WITH_PURCHASE"] = "[modules] get available modules and validate purchased one";
  ModulesActionTypes["SET_AVAILABLE_MODULES"] = "[modules] set available modules to the state";
  ModulesActionTypes["UPDATE_MODULE_PROPS"] = "[modules] update a property value to a given module";
  ModulesActionTypes["SET_ACTIVE_MODULE"] = "[modules] set the active module";
  ModulesActionTypes["START_ACTIVE_MODULE_VIEW_SESSION"] = "[modules] the active module view session has been started";
  ModulesActionTypes["REVOKE_ACTIVE_MODULE_VIEW_SESSION"] = "[modules] the active module view session has been revoked";
  ModulesActionTypes["GET_MODULE_DETAILS"] = "[modules] get module details";
  ModulesActionTypes["SET_MODULE_DETAILS"] = "[modules] set module details to the store";
  ModulesActionTypes["SET_RESULTS_MODULE_NAME"] = "[modules] set the results module name to the store";
  ModulesActionTypes["ERROR_FETCHING_MODULE"] = "[modules] error while fetching module";
  ModulesActionTypes["UNLOCK_MODULE_WITH_ACCESS_CODE"] = "[modules] validate the access code for purchasing a module and unlock it";
  ModulesActionTypes["PURCHASING_ERROR"] = "[modules] purchasing error";
  ModulesActionTypes["GET_PURCHASING_CONTACT_INFO"] = "[modules] get purchasing contact info";
  ModulesActionTypes["SET_PURCHASING_CONTACT_INFO"] = "[modules] set purchasing contact info";
  ModulesActionTypes["MODULE_SECTION_TIPS_AND_TRICKS_IN_VIEW"] = "[modules] module section tips and tricks cards has been rendered in view";
  ModulesActionTypes["MODULE_SECTION_TIPS_AND_TRICKS_OUT_OF_VIEW"] = "[modules] module section tips and tricks cards has been rendered out of view";
  ModulesActionTypes["TIPS_AND_TRICKS_INTERACTION"] = "[modules] interaction with tips and tricks card";
  ModulesActionTypes["GET_SHARED_MODULE_DATA"] = "[modules] get the data for a module that has been shared with a specific link";
  ModulesActionTypes["SET_SHARED_MODULE_DATA"] = "[modules] set the details data for a module that has been shared with a specific link";
  ModulesActionTypes["SET_SHARED_MODULE_ERROR_STATE"] = "[modules] set the error state of the shared module";
  ModulesActionTypes["SET_SHARED_ACTIVE_PERSON_ID"] = "[modules] set the active person id for the shared modules";
  ModulesActionTypes["SET_SHARED_ACTIVE_MODULE_ID"] = "[modules] set the active module id for the shared modules";
})(ModulesActionTypes = exports.ModulesActionTypes || (exports.ModulesActionTypes = {}));