import {WithPopupLayout} from '@dynamynd/dynamynd';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {UserProfileEditScreen, withRefetchUserProfile} from '@dynamynd/user';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {FunctionComponent} from 'react';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';

const EnhancedUserProfileEditScreen = compose(
    withAnalyticsTrackerWeb,
    withRefetchUserProfile
)(UserProfileEditScreen as FunctionComponent<any>);

export const userNavigator = createNavigator(
    appView(WithPopupLayout),
    SwitchRouter({
        [RoutesKeysEnum.editUserProfile]: EnhancedUserProfileEditScreen
    }),
    {}
);
