"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var rxjs_1 = require("rxjs");

var ajax_1 = require("rxjs/ajax");

var operators_1 = require("rxjs/operators");

var HttpMethodsEnum_1 = require("../enums/HttpMethodsEnum");

var RequestTypeEnum_1 = require("../enums/RequestTypeEnum");

var HttpApi =
/** @class */
function () {
  function HttpApi(baseUrl) {
    this.baseUrl = baseUrl;
  }

  HttpApi.prototype.getJson = function (url, shouldIncludeCredentials, access_token) {
    if (shouldIncludeCredentials === void 0) {
      shouldIncludeCredentials = RequestTypeEnum_1.RequestTypeEnum.withoutCredentials;
    }

    if (access_token === void 0) {
      access_token = '';
    }

    var headers = this.setAuthHeaders(shouldIncludeCredentials, access_token);
    return ajax_1.ajax({
      url: url,
      headers: headers,
      method: HttpMethodsEnum_1.HttpMethodsEnum.GET
    }).pipe(operators_1.map(function (res) {
      return res.response;
    }), operators_1.mergeMap(handleResponse), operators_1.catchError(function (err) {
      return rxjs_1.throwError(err.error);
    }));
  };

  HttpApi.prototype.putJson = function (url, body, shouldIncludeCredentials, access_token) {
    if (shouldIncludeCredentials === void 0) {
      shouldIncludeCredentials = RequestTypeEnum_1.RequestTypeEnum.withoutCredentials;
    }

    if (access_token === void 0) {
      access_token = '';
    }

    var headers = this.setAuthHeaders(shouldIncludeCredentials, access_token);
    return ajax_1.ajax({
      url: url,
      headers: headers,
      method: HttpMethodsEnum_1.HttpMethodsEnum.PUT,
      body: JSON.stringify(body)
    }).pipe(operators_1.map(function (res) {
      return res.response;
    }), operators_1.mergeMap(handleResponse), operators_1.catchError(function (err) {
      return rxjs_1.throwError(err.error);
    }));
  };

  HttpApi.prototype.postJson = function (url, body, shouldIncludeCredentials, access_token) {
    if (shouldIncludeCredentials === void 0) {
      shouldIncludeCredentials = RequestTypeEnum_1.RequestTypeEnum.withoutCredentials;
    }

    if (access_token === void 0) {
      access_token = '';
    }

    var headers = this.setAuthHeaders(shouldIncludeCredentials, access_token);
    return ajax_1.ajax({
      url: url,
      body: body,
      headers: headers,
      method: HttpMethodsEnum_1.HttpMethodsEnum.POST
    }).pipe(operators_1.map(function (res) {
      return res.response;
    }), operators_1.mergeMap(handleResponse), operators_1.catchError(function (err) {
      return rxjs_1.throwError(err);
    }));
  };

  HttpApi.prototype.postForm = function (url, body, shouldIncludeCredentials, access_token) {
    if (shouldIncludeCredentials === void 0) {
      shouldIncludeCredentials = RequestTypeEnum_1.RequestTypeEnum.withoutCredentials;
    }

    if (access_token === void 0) {
      access_token = '';
    }

    var headers = this.setAuthHeaders(shouldIncludeCredentials, access_token);
    var formData = new FormData();
    Object.keys(body).map(function (key) {
      formData.append(key, body[key]);
    });
    return ajax_1.ajax({
      url: url,
      headers: headers,
      method: HttpMethodsEnum_1.HttpMethodsEnum.POST,
      crossDomain: true,
      body: formData
    }).pipe(operators_1.map(function (res) {
      return res.response;
    }), operators_1.mergeMap(handleResponse), operators_1.catchError(function (err) {
      return rxjs_1.throwError(err);
    }));
  };

  HttpApi.prototype.setAuthHeaders = function (shouldIncludeCredentials, access_token) {
    if (access_token === void 0) {
      access_token = '';
    }

    var headers = {};

    if (shouldIncludeCredentials === RequestTypeEnum_1.RequestTypeEnum.withCredentials) {
      headers = {
        Authorization: "Bearer " + access_token
      };
    }

    return headers;
  };

  HttpApi.requestType = RequestTypeEnum_1.RequestTypeEnum;
  return HttpApi;
}();

exports.HttpApi = HttpApi;

function handleResponse(response) {
  // there is NO success property on auth call!
  if (response && response.access_token) {
    return rxjs_1.of(response);
  }

  if (!response) {
    return rxjs_1.throwError(new core_1.CustomError(core_1.ApplicationMessagesEnum.DefaultErrorMessage, core_1.ApplicationErrorTypeEnum.Navigate));
  } // The api responses are inconsistent. The following should block of code be refactored!


  if (response && typeof response.success === 'boolean') {
    if (!response.success) {
      // redirect to error page if some module has not been found
      if (response.errors.module && response.errors.module === 'Module not found') {
        return rxjs_1.throwError(new core_1.CustomError(core_1.ApplicationMessagesEnum.DefaultErrorMessage, core_1.ApplicationErrorTypeEnum.Navigate));
      }

      if (response.errors.purchases) {
        // pass down all purchases errors, they would be handled in the epics
        return rxjs_1.of(response);
      }

      return rxjs_1.throwError(new core_1.CustomError(response.error || core_1.ApplicationMessagesEnum.DefaultErrorMessage, core_1.ApplicationErrorTypeEnum.ToastNotification));
    }
  }

  return rxjs_1.of(response);
}