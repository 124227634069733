"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.getAvailableModulesActionCreator = function () {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    type: actionTypes_1.ModulesActionTypes.GET_AVAILABLE_MODULES
  });
};

exports.getAvailableModulesWithPurchaseActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.GET_AVAILABLE_MODULES_WITH_PURCHASE
  });
};

exports.setAvailableModulesActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_AVAILABLE_MODULES
  });
};

exports.updateModulePropsActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.UPDATE_MODULE_PROPS
  });
};

exports.setActiveModuleActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_ACTIVE_MODULE
  });
};

exports.getModuleDetailsActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.GET_MODULE_DETAILS
  });
};

exports.setModuleDetailsActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_MODULE_DETAILS
  });
};

exports.setResultsModuleNameActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_RESULTS_MODULE_NAME
  });
};

exports.errorFetchingModuleActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ModulesActionTypes.ERROR_FETCHING_MODULE
  });
};

exports.unlockModuleWithAccessCodeActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.UNLOCK_MODULE_WITH_ACCESS_CODE
  });
};

exports.purchasingErrorActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ModulesActionTypes.PURCHASING_ERROR
  });
};

exports.getPurchasingContactInfoActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ModulesActionTypes.GET_PURCHASING_CONTACT_INFO
  });
};

exports.setPurchasingContactInfoActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_PURCHASING_CONTACT_INFO
  });
};

exports.startActiveModuleViewSessionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.START_ACTIVE_MODULE_VIEW_SESSION
  });
};

exports.revokeActiveModuleViewSessionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.REVOKE_ACTIVE_MODULE_VIEW_SESSION
  });
};

exports.moduleSectionTipsAndTricksInViewActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.MODULE_SECTION_TIPS_AND_TRICKS_IN_VIEW
  });
};

exports.moduleSectionTipsAndTricksOutOfViewActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.MODULE_SECTION_TIPS_AND_TRICKS_OUT_OF_VIEW
  });
};

exports.tipsAndTricksInteractionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.TIPS_AND_TRICKS_INTERACTION
  });
};

exports.setSharedActivePersonIdActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_SHARED_ACTIVE_PERSON_ID
  });
};

exports.setSharedActiveModuleIdActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_SHARED_ACTIVE_MODULE_ID
  });
};

exports.getSharedModuleDataActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.GET_SHARED_MODULE_DATA
  });
};

exports.setSharedModuleDataActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_SHARED_MODULE_DATA
  });
};

exports.setSharedModuleErrorStateActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ModulesActionTypes.SET_SHARED_MODULE_ERROR_STATE
  });
};