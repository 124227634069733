"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldLabels;

(function (FieldLabels) {
  FieldLabels["name"] = "Name (Required)";
  FieldLabels["email"] = "Email (Required)";
  FieldLabels["subject"] = "Subject (Required)";
  FieldLabels["body"] = "Message (Required)";
  FieldLabels["submit"] = "Send";
})(FieldLabels = exports.FieldLabels || (exports.FieldLabels = {}));