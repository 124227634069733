"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_1 = require("redux");

var completed_reducer_1 = require("./completed.reducer");

var configs_reducer_1 = require("./configs.reducer");

var questionsState_reducer_1 = require("./questionsState.reducer");

var responses_reducer_1 = require("./responses.reducer");

var view_reducer_1 = require("./view.reducer"); // todo actions


var reducerMap = {
  configs: configs_reducer_1.configsReducer,
  completed: completed_reducer_1.completedReducer,
  responses: responses_reducer_1.responsesReducer,
  questionsState: questionsState_reducer_1.questionsStateReducer,
  view: view_reducer_1.viewReducer
};
exports.surveyReducer = redux_1.combineReducers(reducerMap);