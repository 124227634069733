"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentActionTypes;

(function (ContentActionTypes) {
  ContentActionTypes["DYNAMYND_GET_AVATARS"] = "[content] get the available avatars from the server";
  ContentActionTypes["DYNAMYND_SET_AVATARS"] = "[content] set the avatars to the store";
  ContentActionTypes["DYNAMYND_SET_INSTRUCTIONS"] = "[content] set the available instructions to the store";
  ContentActionTypes["DYNAMYND_GET_PAGES_CONTENT"] = "[content] get page content action";
  ContentActionTypes["DYNAMYND_SET_HOME_PAGE_CONTENT"] = "[content] set the home page content to the store";
  ContentActionTypes["DYNAMYND_SET_PAGE_CONTENT"] = "[content] set page content to the store";
  ContentActionTypes["GET_CONTENT_FOR_SHARING"] = "[content] get the content messages for the sharing functionality";
  ContentActionTypes["SET_CONTENT_FOR_SHARING"] = "[content] set the content messages for the sharing functionality";
})(ContentActionTypes = exports.ContentActionTypes || (exports.ContentActionTypes = {}));