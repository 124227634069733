"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.recordAnalyticsDataActionActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.AnalyticsActionTypes.RECORD_ANALYTICS_DATA
  });
};

exports.endAllActiveAnalyticsSessionsActionCreator = function () {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    type: actionTypes_1.AnalyticsActionTypes.END_ALL_ACTIVE_ANALYTICS_SESSIONS_ON_USER_IDLE
  });
};

exports.resumeAllLastActiveAnalyticsSessionsActionCreator = function () {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    type: actionTypes_1.AnalyticsActionTypes.RESUME_ALL_LAST_ACTIVE_ANALYTICS_SESSIONS
  });
};