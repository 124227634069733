import {BootstrapWebScreen, ErrorScreen} from '@dynamynd/dynamynd';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {
    authNavigator,
    commonNavigator,
    modulesNavigator,
    sharedNavigator,
    surveyNavigator,
    userNavigator
} from '../navigators';

export const routes = {
    [RoutesKeysEnum.bootstrap]: BootstrapWebScreen,
    [RoutesKeysEnum.dynamynd]: commonNavigator,
    [RoutesKeysEnum.shared]: sharedNavigator,
    [RoutesKeysEnum.auth]: authNavigator,
    [RoutesKeysEnum.survey]: surveyNavigator,
    [RoutesKeysEnum.modules]: modulesNavigator,
    [RoutesKeysEnum.user]: userNavigator,
    [RoutesKeysEnum.error]: ErrorScreen
};
