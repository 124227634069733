"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var normalizr_1 = require("normalizr");

var state_1 = require("../state");

var actionTypes_1 = require("./../actions/actionTypes"); // tslint:disable prefer-array-literal


var notificationEntity = new normalizr_1.schema.Entity('notifications', {}, {
  idAttribute: 'id'
});
var notificationsSchema = new normalizr_1.schema.Array(notificationEntity);

exports.toastNotificationsReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialToastNotificationsState;
  }

  if (!action) return state;
  var type = action.type;

  switch (type) {
    case actionTypes_1.ToastNotificationsActionTypes.ADD_TOAST_NOTIFICATION:
      return addNotificationHandler(state, action);

    case actionTypes_1.ToastNotificationsActionTypes.REMOVE_TOAST_NOTIFICATION:
      return removeNotificationHandler(state, action);

    default:
      return state;
  }
}; ///////


function addNotificationHandler(state, action) {
  var payload = action.payload;

  var notificationsValues = __spreadArrays(utilities_1.values(state.entities.notifications || {}), [payload]);

  return normalizr_1.normalize(notificationsValues, notificationsSchema);
}

function removeNotificationHandler(state, action) {
  var id = action.payload.id;
  var notificationsValues = [];

  if (state.entities.notifications) {
    notificationsValues = utilities_1.values(state.entities.notifications).filter(function (notification) {
      return notification.id !== id;
    });
  }

  return normalizr_1.normalize(notificationsValues, notificationsSchema);
}