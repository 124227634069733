"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../components");

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var store_1 = require("../../store");

var AddOnModuleScreen =
/** @class */
function (_super) {
  __extends(AddOnModuleScreen, _super);

  function AddOnModuleScreen(props) {
    var _a;

    var _this = _super.call(this, props) || this;

    _this.numberOfTipsToRender = 'all';
    _this.summarySectionTitle = 'Up Your Game';

    _this.setSectionHeightState = function (sectionName, height) {
      _this.setState(function (prevState) {
        var _a;

        return __assign(__assign({}, prevState), {
          sectionHeights: __assign(__assign({}, prevState.sectionHeights), (_a = {}, _a[sectionName] = height, _a))
        });
      });
    };

    _this.scrollToTop = function () {
      if (_this.scrollViewRef.current) {
        _this.scrollViewRef.current.scrollTo({
          x: 0,
          y: 0,
          animated: true
        });
      }
    };

    _this.scrollToNextSection = function (currentSection) {
      var sectionHeights = _this.state.sectionHeights;
      var scrollSectionsOrder = [enums_1.ModuleSectionViewsEnum.intro, enums_1.ModuleSectionViewsEnum.overview, enums_1.ModuleSectionViewsEnum.info, enums_1.ModuleSectionViewsEnum.firstMo, enums_1.ModuleSectionViewsEnum.secondMo, enums_1.ModuleSectionViewsEnum.thirdMo, enums_1.ModuleSectionViewsEnum.fourthMo];
      var sectionIndex = scrollSectionsOrder.indexOf(currentSection) + 1;
      var scrollToHeight = scrollSectionsOrder.slice(0, sectionIndex).reduce(function (acc, section) {
        // in order to scroll enough the info section we need to scroll further than the height of overview:
        if (currentSection === enums_1.ModuleSectionViewsEnum.overview) {
          return acc + sectionHeights[section] * 1.05;
        }

        return acc + sectionHeights[section];
      }, 0);

      if (_this.scrollViewRef.current) {
        _this.scrollViewRef.current.scrollTo({
          x: 0,
          y: scrollToHeight,
          animated: true
        });
      }
    };

    _this.getModuleData = function () {
      var _a = _this.props,
          navigation = _a.navigation,
          getModuleDetails = _a.getModuleDetails,
          activeModule = _a.activeModule;
      var module = navigation.getParam(services_1.ModulesService.moduleParam);

      var moduleName = _this.getModuleName(module);
      /*
      If the user loads the screen directly the activeModule is an empty string,
      that's why we are passing the activeModule || moduleName.
      In any other case both variables would have the same value.
      */


      var moduleToUse = activeModule ? activeModule : moduleName;

      _this.handleEmptyModule(moduleToUse);

      _this.handleActiveModuleUpdate(moduleToUse); // start the analytics session


      _this.props.startActiveModuleViewSession({
        moduleId: moduleToUse
      });

      if (!_this.moduleDataExists(moduleToUse)) {
        getModuleDetails(moduleToUse);
      }
    };

    _this.moduleDataExists = function (moduleName) {
      return _this.props.modulesWithData.includes(moduleName);
    };

    _this.getModuleName = function (module) {
      var _a = _this.props,
          modulesViewData = _a.modulesViewData,
          navigateTo = _a.navigateTo;
      var result = utilities_1.find(modulesViewData, function (m) {
        return m.outputGroupId === module;
      });

      if (result) {
        return result.name;
      } /// redirect to error page if there is not a module with the outputGroupId from the url


      navigateTo(routes_1.RoutesKeysEnum.error);
      return '';
    };

    _this.handleActiveModuleUpdate = function (moduleToCompare) {
      var _a = _this.props,
          setActiveModule = _a.setActiveModule,
          activeModule = _a.activeModule;

      if (moduleToCompare !== activeModule) {
        setActiveModule(moduleToCompare);
      }
    };

    _this.handleEmptyModule = function (module) {
      if (!module) {
        _this.props.navigateTo(routes_1.RoutesKeysEnum.error);

        return;
      }
    };

    _this.isDataReady = function () {
      var _a = _this.props,
          updatingAddOnModule = _a.updatingAddOnModule,
          addOnsModuleDetailsViewModel = _a.addOnsModuleDetailsViewModel; // if not updating and the data is empty -> would mean that we couldn't found the module and we couldn't have parsed it

      return !updatingAddOnModule && !utilities_1.isEmpty(addOnsModuleDetailsViewModel);
    };

    _this.renderIntroSection = function () {
      var _a = _this.props,
          addOnsModuleDetailsViewModel = _a.addOnsModuleDetailsViewModel,
          purchasingModulesViewState = _a.purchasingModulesViewState;

      var isDataReady = _this.isDataReady();

      var moduleName = addOnsModuleDetailsViewModel.moduleName,
          introSection = addOnsModuleDetailsViewModel.introSection,
          introImageUri = addOnsModuleDetailsViewModel.introImageUri;

      if (purchasingModulesViewState || !isDataReady) {
        return react_1.default.createElement(components_1.ViewSectionContainer, null, react_1.default.createElement(components_1.LoadingIndicatorModules, null));
      }

      return react_1.default.createElement(components_1.SectionIntro, {
        title: moduleName,
        content: introSection,
        scrollToElementHandler: _this.scrollToNextSection,
        introImageUri: introImageUri,
        userProps: _this.props
      });
    };

    _this.renderContent = function (showContent) {
      var _a = _this.props,
          addOnsModuleDetailsViewModel = _a.addOnsModuleDetailsViewModel,
          purchasingModulesViewState = _a.purchasingModulesViewState,
          renderModules = _a.renderModules,
          resultsModuleName = _a.resultsModuleName;

      if (purchasingModulesViewState) {
        return null;
      }

      if (_this.isDataReady()) {
        return renderModules({
          resultsModuleName: resultsModuleName,
          moduleData: addOnsModuleDetailsViewModel,
          summarySectionTitle: _this.summarySectionTitle,
          numberOfTipsToRender: _this.numberOfTipsToRender,
          showPaymentModal: showContent,
          scrollToSectionHandler: _this.scrollToNextSection,
          setSectionHeightState: _this.setSectionHeightState,
          withCustomAnalytics: true,
          withAvailableModules: true
        });
      }
    };

    _this.preLogoutHandler = function () {
      var _a = _this.props,
          revokeActiveModuleViewSession = _a.revokeActiveModuleViewSession,
          activeModule = _a.activeModule; // end the current analytics session

      revokeActiveModuleViewSession({
        moduleId: activeModule
      });
    };

    _this.scrollViewRef = react_1.default.createRef();
    _this.state = {
      sectionHeights: (_a = {}, _a[enums_1.ModuleSectionViewsEnum.intro] = 0, _a[enums_1.ModuleSectionViewsEnum.overview] = 0, _a[enums_1.ModuleSectionViewsEnum.info] = 0, _a[enums_1.ModuleSectionViewsEnum.firstMo] = 0, _a[enums_1.ModuleSectionViewsEnum.secondMo] = 0, _a[enums_1.ModuleSectionViewsEnum.thirdMo] = 0, _a[enums_1.ModuleSectionViewsEnum.fourthMo] = 0, _a)
    };
    return _this;
  }

  AddOnModuleScreen.prototype.componentDidMount = function () {
    // fetch data if not fetched yet
    this.getModuleData();
  };

  AddOnModuleScreen.prototype.shouldComponentUpdate = function (nextProps, nextState) {
    var currentSectionHeights = this.state.sectionHeights;
    var nextSectionHeights = nextState.sectionHeights;

    if (!utilities_1.isEqual(currentSectionHeights, nextSectionHeights)) {
      return false;
    }

    return true;
  };

  AddOnModuleScreen.prototype.componentDidUpdate = function (prevProps) {
    var _this = this;

    var addOnsModuleDetailsViewModel = this.props.addOnsModuleDetailsViewModel;
    this.handleModuleParamUpdate(prevProps);
    setTimeout(function () {
      _this.scrollToTop();
    }, 100);

    if (!utilities_1.isEmpty(addOnsModuleDetailsViewModel)) {
      this.setSharingDataValues();
    }
  };

  AddOnModuleScreen.prototype.componentWillUnmount = function () {
    var _a = this.props,
        setActiveModule = _a.setActiveModule,
        revokeActiveModuleViewSession = _a.revokeActiveModuleViewSession,
        activeModule = _a.activeModule;
    setActiveModule(''); // end the current analytics session

    revokeActiveModuleViewSession({
      moduleId: activeModule
    });
  };

  AddOnModuleScreen.prototype.setSharingDataValues = function () {
    var _a = this.props,
        withModuleShare = _a.withModuleShare,
        title = _a.activeModuleSharingDataValues.title,
        _b = _a.addOnsModuleDetailsViewModel,
        moduleId = _b.moduleId,
        sharingMessage = _b.sharingMessage,
        profileId = _a.user.ID;
    var url = this.props.getSharingUrl(sharing_1.SharedQsParamsEnum.mid + "=" + moduleId, sharing_1.SharedQsParamsEnum.pid + "=" + profileId, "/" + routes_1.RoutesKeysEnum.shared + "/" + routes_1.RoutesKeysEnum.sharedModule);
    withModuleShare({
      url: url,
      title: title,
      description: sharingMessage
    });
  };

  AddOnModuleScreen.prototype.handleModuleParamUpdate = function (prevProps) {
    var prevActiveModule = prevProps.activeModule;
    var _a = this.props,
        currentActiveModule = _a.activeModule,
        revokeActiveModuleViewSession = _a.revokeActiveModuleViewSession;

    if (currentActiveModule !== '' && prevActiveModule !== '' && prevActiveModule !== currentActiveModule) {
      /// TODO fix navigation error
      // end the current analytics session and start new one with the different user id
      revokeActiveModuleViewSession({
        moduleId: prevActiveModule
      });
      this.getModuleData();
    }
  };

  AddOnModuleScreen.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        _b = _a.screenProps.components,
        WebViewComponent = _b.WebViewComponent,
        IFrameComponent = _b.IFrameComponent,
        purchasingModulesViewState = _a.purchasingModulesViewState;
    var isDataReady = this.isDataReady();
    return react_1.default.createElement(content_1.WithAdditionalContent, {
      WebViewComponent: WebViewComponent,
      IFrameComponent: IFrameComponent,
      includeFooter: true
    }, function (_a) {
      var showContent = _a.showContent;
      return (// todo AddOnModulesView component?
        react_1.default.createElement(react_native_1.ScrollView, {
          ref: _this.scrollViewRef
        }, react_1.default.createElement(components_lib_1.ModulesBackground, {
          shouldExpand: purchasingModulesViewState || !isDataReady,
          onLayoutHandler: function onLayoutHandler(event) {
            var introHeight = event.nativeEvent.layout.height;

            _this.setSectionHeightState(enums_1.ModuleSectionViewsEnum.intro, introHeight);
          }
        }, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
          color: "purple",
          version: {
            sm: 'image',
            lg: 'image'
          }
        }), react_1.default.createElement(user_1.UserProfileBox, {
          showPrivacy: function showPrivacy() {
            return showContent(content_1.ContentPagesEnum.privacy);
          },
          showTerms: function showTerms() {
            return showContent(content_1.ContentPagesEnum.terms);
          },
          preLogout: _this.preLogoutHandler
        })), _this.renderIntroSection()), _this.renderContent(showContent))
      );
    });
  };

  return AddOnModuleScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    modulesViewData: store_1.modulesViewDataSelector(state),
    resultsModuleName: store_1.resultsModuleNameSelector(state),
    activeModule: store_1.activeModuleSelector(state),
    modulesWithData: store_1.modulesWithDataSelector(state),
    updatingAddOnModule: store_1.updatingAddOnModuleSelector(state),
    addOnsModuleDetailsViewModel: store_1.addOnsModuleDetailsViewModelSelector(state),
    purchasingModulesViewState: store_1.purchasingModulesViewStateSelector(state),
    activeModuleSharingDataValues: store_1.getActiveModuleSharingDataValues(state),
    user: user_1.userProfileSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getModuleDetails: redux_1.bindActionCreators(store_1.getModuleDetailsActionCreator, dispatch),
    setActiveModule: redux_1.bindActionCreators(store_1.setActiveModuleActionCreator, dispatch),
    startActiveModuleViewSession: redux_1.bindActionCreators(store_1.startActiveModuleViewSessionActionCreator, dispatch),
    revokeActiveModuleViewSession: redux_1.bindActionCreators(store_1.revokeActiveModuleViewSessionActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, components_1.withSectionModulesRender, core_1.withNavigateTo, sharing_1.withAddThisSharing);
exports.default = hoc(AddOnModuleScreen);