"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var operators_1 = require("rxjs/operators");

var enums_1 = require("../../enums");

var actions_1 = require("../actions");

var services_1 = require("./../../services");

exports.changeActiveQuestionEpic = function (actions$, state$) {
  return actions$.pipe(redux_observable_1.ofType(actions_1.SurveyActionTypes.CHANGE_ACTIVE_QUESTION), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.switchMap(function () {
    return services_1.surveyService.getOpenedInformation();
  }, function (action, response) {
    return [action, response];
  }), operators_1.mergeMap(function (_a) {
    var payload = _a[0],
        action = _a[1];
    return changeActiveQuestionEpicHandler(payload, state$, action);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var changeActiveQuestionEpicHandler = function changeActiveQuestionEpicHandler(payload, state$, instruction) {
  var type = payload.type;

  if (type === enums_1.ChangeQuestionTypeEnum.score) {
    return [actions_1.submitSurveyForScoringActionCreator()];
  }

  var _a = state$.value,
      _b = _a.survey,
      surveys = _b.configs.surveys,
      _c = _b.questionsState,
      activeQuestionId = _c.activeQuestionId,
      questionsOrder = _c.questionsOrder,
      entities = _c.questionsPool.entities,
      PersonId = _a.user.profile.PersonId; // tslint:disable-next-line: prefer-array-literal

  var actions = [];
  var consequentQuestionId = services_1.surveyService.pickConsequentQuestion(activeQuestionId, questionsOrder, type);
  var activeAccessCode = services_1.surveyService.pickActiveAccessCode(consequentQuestionId, surveys);
  var questionAlreadyOpen = services_1.surveyService.questionAlreadyOpen(instruction, PersonId, consequentQuestionId);
  var questionNeedInstruction = services_1.surveyService.questionNeedInstruction(consequentQuestionId, entities);
  actions.push(actions_1.setInstructionStatusCreator(false));

  if (questionNeedInstruction && !questionAlreadyOpen) {
    var users = instruction.usersId || [];
    users.push(PersonId);
    var questions = instruction.questionId || [];
    questions.push(consequentQuestionId);
    actions.push(actions_1.setInstructionStatusCreator(true));
    services_1.surveyService.setInstructionStatus({
      usersId: users,
      questionId: questions
    });
  }

  actions.push(actions_1.setSurveyActiveQuestionIdActionCreator(consequentQuestionId), actions_1.updateQuestionTakingSessionActionCreator(consequentQuestionId), actions_1.setSurveyActiveAccessCodeActionCreator(activeAccessCode));
  return actions;
};