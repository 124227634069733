import {WithPopupLayout} from '@dynamynd/dynamynd';
import {SharedAddOnModuleScreen} from '@dynamynd/modules';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {SharedSurveyResultsScreen} from '@dynamynd/survey';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';

const EnhancedSharedAddOnModuleScreen = compose(withAnalyticsTrackerWeb)(SharedAddOnModuleScreen);
const EnhancedSharedSurveyResultsScreen = compose(withAnalyticsTrackerWeb)(SharedSurveyResultsScreen as any);

export const sharedNavigator = createNavigator(
    appView(WithPopupLayout),
    SwitchRouter({
        [RoutesKeysEnum.sharedModule]: EnhancedSharedAddOnModuleScreen,
        [RoutesKeysEnum.sharedResultsModule]: EnhancedSharedSurveyResultsScreen
    }),
    {}
);
