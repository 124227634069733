"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var functions_1 = require("../functions");

var actionTypes_1 = require("./actionTypes");

exports.logoutUserActionCreator = function () {
  return functions_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_LOGOUT_USER
  });
};

exports.errorActionCreator = function (payload) {
  return functions_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ActionTypes.DYNAMYND_ERROR
  });
};

exports.cancelPendingAuthRequestsCreator = function () {
  return functions_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS
  });
};