"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../store");

var index_1 = require("../index");

function withSharedModuleData(WrappedComponent) {
  var WithSharedModuleDataComponent =
  /** @class */
  function (_super) {
    __extends(WithSharedModuleDataComponent, _super);

    function WithSharedModuleDataComponent() {
      return _super !== null && _super.apply(this, arguments) || this;
    }

    WithSharedModuleDataComponent.prototype.componentDidMount = function () {
      var navigation = this.props.navigation;
      var personId = navigation.getParam(sharing_1.SharedQsParamsEnum.pid);
      var moduleId = navigation.getParam(sharing_1.SharedQsParamsEnum.mid);
      this.validateParamsValue(personId, moduleId);
      var _a = this.props,
          getSharedModuleData = _a.getSharedModuleData,
          setSharedActiveModuleId = _a.setSharedActiveModuleId,
          setSharedActivePersonId = _a.setSharedActivePersonId;
      getSharedModuleData({
        personId: personId,
        moduleId: moduleId
      });
      setSharedActiveModuleId({
        moduleId: moduleId
      });
      setSharedActivePersonId({
        personId: personId
      });
    };

    WithSharedModuleDataComponent.prototype.componentWillUnmount = function () {
      var _a = this.props,
          setSharedActiveModuleId = _a.setSharedActiveModuleId,
          setSharedActivePersonId = _a.setSharedActivePersonId;
      setSharedActiveModuleId({
        moduleId: ''
      });
      setSharedActivePersonId({
        personId: ''
      });
    };

    WithSharedModuleDataComponent.prototype.validateParamsValue = function (personId, moduleId) {
      // redirect to error if there is an empty value for one of the qs params
      if (utilities_1.isEmpty(personId) || utilities_1.isEmpty(moduleId)) {
        this.props.navigation.navigate(routes_1.RoutesKeysEnum.error);
      }
    };

    WithSharedModuleDataComponent.prototype.render = function () {
      var _a = this.props,
          sharedModuleData = _a.sharedModuleData,
          errorState = _a.errorState;

      if (utilities_1.isEmpty(sharedModuleData.module) && !errorState) {
        return react_1.default.createElement(index_1.LoadingIndicatorModules, null);
      }

      return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
        data: sharedModuleData
      }));
    };

    return WithSharedModuleDataComponent;
  }(react_1.Component);

  var mapStateToProps = function mapStateToProps(state) {
    return {
      sharedModuleData: store_1.getSharedModuleDataByPersonIdSelector(state),
      errorState: store_1.sharedModuleErrorStateSelector(state)
    };
  };

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      getSharedModuleData: redux_1.bindActionCreators(store_1.getSharedModuleDataActionCreator, dispatch),
      setSharedActivePersonId: redux_1.bindActionCreators(store_1.setSharedActivePersonIdActionCreator, dispatch),
      setSharedActiveModuleId: redux_1.bindActionCreators(store_1.setSharedActiveModuleIdActionCreator, dispatch)
    };
  };

  var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
  var hoc = redux_1.compose(connector);
  return hoc(WithSharedModuleDataComponent);
}

exports.withSharedModuleData = withSharedModuleData;