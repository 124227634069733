"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var analytics_1 = require("@dynamynd/analytics");

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.userIsIdleEpic = function (actions$) {
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.USER_IS_IDLE), operators_1.mergeMap(userIsIdleEpicHandler), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var userIsIdleEpicHandler = function userIsIdleEpicHandler() {
  var isOnPageWithAnalytics = core_1.locationWebService.isOnPageWithAnalytics();

  if (isOnPageWithAnalytics) {
    return rxjs_1.of(analytics_1.endAllActiveAnalyticsSessionsActionCreator());
  }

  return rxjs_1.empty();
};