"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ApplicationMessagesEnum;

(function (ApplicationMessagesEnum) {
  ApplicationMessagesEnum["LoginSuccess"] = "You've successfully logged in!";
  ApplicationMessagesEnum["UserProfileUpdatedSuccessfully"] = "The Profile has been updated successfully!";
  ApplicationMessagesEnum["UserProfileValidationFail"] = "User profile validation failed";
  ApplicationMessagesEnum["DefaultErrorMessage"] = "Something went wrong!";
  ApplicationMessagesEnum["AccessTokenExpiredMessage"] = "Your session has expired. Please, login again.";
  ApplicationMessagesEnum["SomethingWentWrong"] = "Something went wrong.";
  ApplicationMessagesEnum["ResultsNotAvailable"] = "Results unavailable.";
  ApplicationMessagesEnum["SuccessfulModulePurchase"] = "Your purchase was successful.";
  ApplicationMessagesEnum["CodeSent"] = "We've sent your code.";
})(ApplicationMessagesEnum = exports.ApplicationMessagesEnum || (exports.ApplicationMessagesEnum = {}));