"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

var moImageDefaultBackgroundColor = '#F6E8D6'; // tslint:disable prefer-array-literal

exports.getModuleDetailsEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.GET_MODULE_DETAILS), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (module) {
    return getModuleDetailsEpicHandler(actions$, module, modulesApiService);
  }), operators_1.catchError(getModuleDetailsErrorHandler));
};

var getModuleDetailsEpicHandler = function getModuleDetailsEpicHandler(actions$, module, modulesApiService) {
  return modulesApiService.getModuleDetails(module, moImageDefaultBackgroundColor).pipe(operators_1.flatMap(function (response) {
    return rxjs_1.of(actions_1.setModuleDetailsActionCreator({
      module: module,
      data: response.data
    }));
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(getModuleDetailsErrorHandler));
};

function getModuleDetailsErrorHandler(error) {
  return __spreadArrays([actions_1.errorFetchingModuleActionCreator()], core_1.defaultErrorHandler(error));
}