"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions");

var enums_1 = require("./../../enums");

exports.recordQuestionResponseEpic = function (actions$, state$, _a) {
  var surveyApiService = _a.surveyApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.SurveyActionTypes.RECORD_QUESTION_RESPONSE), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return recordQuestionResponseEpicHandler(actions$, payload, state$, surveyApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var recordQuestionResponseEpicHandler = function recordQuestionResponseEpicHandler(actions$, payload, state$, surveyApiService) {
  var _a = state$.value.survey,
      _b = _a.configs,
      questionnaries = _b.questionnaries,
      accessCodes = _b.accessCodes,
      _c = _a.questionsState,
      completedQuestions = _c.completedQuestions,
      questionsPool = _c.questionsPool,
      responses = _a.responses;
  var active = accessCodes.active;
  var questionnarie = questionnaries[active];
  var questionId = payload.questionId,
      answerOptionIds = payload.answerOptionIds,
      questionType = payload.questionType,
      navigateDirection = payload.navigateDirection;
  var isUpdate = completedQuestions.includes(questionId);
  var question = questionsPool.entities.questionsPool[questionId];

  if (!questionnarie || !question) {
    return rxjs_1.of(core_1.errorActionCreator({
      message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: core_1.ApplicationErrorTypeEnum.Navigate
    }));
  }

  var PersonId = questionnarie.PersonId,
      QuestionnaireId = questionnarie.QuestionnaireId,
      AccessCode = questionnarie.AccessCode,
      PersonQuestionnaireId = questionnarie.PersonQuestionnaireId,
      Person = questionnarie.Person;
  var questionOrder = question.questionOrder,
      optionsRaw = question.options; /// todo request a change in the API

  var options = utilities_1.values(optionsRaw);
  var apiPayload = {
    questionId: questionId,
    questionOrder: questionOrder,
    personId: PersonId,
    questionnaireId: QuestionnaireId,
    accessCode: AccessCode,
    personQuestionnaireId: PersonQuestionnaireId,
    personFullName: Person
  };

  if (isUpdate) {
    var responsesEntities = responses.entities.responses;
    apiPayload.personQuestionnaireResponseId = responsesEntities[questionId].PersonQuestionnaireResponseId;
  }

  if (questionType === enums_1.SurveyQuestionTypeEnum.mostLeast) {
    var most_1 = answerOptionIds.most,
        least_1 = answerOptionIds.least;
    var mostOption = utilities_1.find(options, function (o) {
      return o.optionId === most_1;
    });
    var leastOption = utilities_1.find(options, function (o) {
      return o.optionId === least_1;
    });

    if (!mostOption || !leastOption) {
      return rxjs_1.of(core_1.errorActionCreator({
        message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
        type: core_1.ApplicationErrorTypeEnum.Navigate
      }));
    }

    var mostValue = mostOption.value;
    var leastValue = leastOption.value;
    return recordMostLeastQuestion(actions$, surveyApiService, __assign(__assign({}, apiPayload), {
      mostValue: mostValue,
      leastValue: leastValue
    }), navigateDirection);
  }

  var scale = answerOptionIds.scale;
  var scaleOption = utilities_1.find(options, function (o) {
    return o.optionId === scale;
  });

  if (!scaleOption) {
    return rxjs_1.of(core_1.errorActionCreator({
      message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: core_1.ApplicationErrorTypeEnum.Navigate
    }));
  }

  var scaleValue = scaleOption.value;
  return recordScaleQuestion(actions$, surveyApiService, __assign(__assign({}, apiPayload), {
    scaleValue: scaleValue
  }), navigateDirection);
};

var recordMostLeastQuestion = function recordMostLeastQuestion(actions$, surveyApiService, apiPayload, navigateDirection) {
  return surveyApiService.recordMostLeastQuestionResponse(apiPayload).pipe(operators_1.mergeMap(function (response) {
    return recordQuestionCallback(response, apiPayload, navigateDirection);
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(recordQuestionResponseErrorHandler));
};

var recordScaleQuestion = function recordScaleQuestion(actions$, surveyApiService, apiPayload, navigateDirection) {
  return surveyApiService.recordScaleQuestionResponse(apiPayload).pipe(operators_1.mergeMap(function (response) {
    return recordQuestionCallback(response, apiPayload, navigateDirection);
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(recordQuestionResponseErrorHandler));
};

var recordQuestionCallback = function recordQuestionCallback(response, apiPayload, navigateDirection) {
  var questionId = apiPayload.questionId;
  var answers = response.data.answers;
  var surveyResponse = services_1.surveyService.getResponseByQuestionId(answers, questionId);

  if (!surveyResponse) {
    return rxjs_1.of(core_1.errorActionCreator({
      message: core_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: core_1.ApplicationErrorTypeEnum.Navigate
    }));
  } // tslint:disable-next-line: prefer-array-literal


  var actions = [actions_1.upsertSurveyResponseActionCreator(surveyResponse), actions_1.setSurveyActiveQuestionIdActionCreator(questionId), actions_1.updateQuestionTakingSessionActionCreator(questionId), actions_1.setSurveyCompletedQuestionsActionCreator({
    completedQuestions: [questionId]
  })];

  if (navigateDirection === enums_1.ChangeQuestionTypeEnum.score) {
    // submit the survey for scoring
    actions.push(actions_1.submitSurveyForScoringActionCreator());
  } else {
    actions.push(actions_1.changeActiveQuestionActionCreator({
      type: navigateDirection
    }));
  }

  return actions;
};

function recordQuestionResponseErrorHandler(error) {
  return __spreadArrays([actions_1.setSurveyUpdatingActionCreator(false)], core_1.defaultErrorHandler(error));
}