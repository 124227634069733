"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var actions_1 = require("../actions");

var state_1 = require("./../state"); // todo actions


exports.modulesDetailsReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialModulesState.modulesDetails;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ModulesActionTypes.SET_MODULE_DETAILS:
      return setModuleDetailsHandler(state, action);

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return {
        entities: __assign({}, state_1.initialModulesState.modulesDetails.entities),
        result: __spreadArrays(state_1.initialModulesState.modulesDetails.result)
      };

    default:
      return state;
  }
}; ////


function setModuleDetailsHandler(state, action) {
  var _a;

  var _b = action.payload,
      module = _b.module,
      data = _b.data;

  var entities = __assign(__assign({}, state.entities), (_a = {}, _a[module] = __assign({}, data), _a));

  var result = utilities_1.uniq(__spreadArrays(state.result, [module]));
  return __assign(__assign({}, state), {
    entities: entities,
    result: result
  });
}