"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

var react_1 = __importStar(require("react"));

var Icon_1 = require("../Icon/Icon");

var LoadingIndicator_1 = require("../LoadingIndicator");

var ImageCircle_1 = require("./ImageCircle");

var styled_1 = require("./styled");

var ImagePicker =
/** @class */
function (_super) {
  __extends(ImagePicker, _super);

  function ImagePicker() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      selectedId: ''
    };

    _this.imageOnPress = function (image) {
      return function () {
        var isDisabled = _this.props.isDisabled;

        if (!isDisabled) {
          _this.setState({
            selectedId: image.id
          });
        }
      };
    };

    return _this;
  }

  ImagePicker.prototype.componentDidMount = function () {
    var initialSelectedId = this.props.initialSelectedId;
    this.setState({
      selectedId: initialSelectedId
    });
  };

  ImagePicker.prototype.componentDidUpdate = function (prevProps) {
    var prevCollection = prevProps.collection;
    var _a = this.props,
        currentCollection = _a.collection,
        initialSelectedId = _a.initialSelectedId; // that would mean that there are changes in the collection
    // i.e. the collection has been loaded or it has been updated with a new one

    if (prevCollection.length !== currentCollection.length) {
      this.setState({
        selectedId: initialSelectedId
      });
    }
  };

  ImagePicker.prototype.collectionHasValue = function () {
    return this.props.collection.length > 0;
  };

  ImagePicker.prototype.renderImages = function () {
    var _this = this;

    var _a = this.props,
        collection = _a.collection,
        activeBreakpoint = _a.activeBreakpoint;
    var selectedId = this.state.selectedId;
    var result = collection.map(function (image, idx) {
      var selected = selectedId === image.id;
      return react_1.default.createElement(ImageCircle_1.ImageCircle, {
        key: idx,
        image: image,
        selected: selected,
        onPress: _this.imageOnPress(image),
        activeBreakpoint: activeBreakpoint
      });
    });
    return result;
  };

  ImagePicker.prototype.getContainerComponent = function () {
    var activeBreakpoint = this.props.activeBreakpoint;
    return responsive_1.isSmall(activeBreakpoint) ? styled_1.ImagePickerContainerSmall : styled_1.ImagePickerContainerDefault;
  };

  ImagePicker.prototype.renderScrollMoreIcon = function () {
    var activeBreakpoint = this.props.activeBreakpoint;
    var small = responsive_1.isSmall(activeBreakpoint);

    if (small) {
      return react_1.default.createElement(styled_1.UnfoldMoreIconContainer, null, react_1.default.createElement(Icon_1.Icon, {
        name: "unfold-more-vertical",
        size: Icon_1.Icon.IconSize.medium
      }));
    }
  };

  ImagePicker.prototype.render = function () {
    var _a = this.props,
        screenWidth = _a.screenWidth,
        children = _a.children;
    var selectedId = this.state.selectedId;
    var collectionHasValue = this.collectionHasValue();

    if (!collectionHasValue) {
      return react_1.default.createElement(LoadingIndicator_1.LoadingIndicator, {
        size: "small"
      });
    }

    var ContainerComponent = this.getContainerComponent();
    return react_1.default.createElement(styled_1.ImagePickerFixer, null, react_1.default.createElement(ContainerComponent, {
      screenWidth: screenWidth
    }, this.renderImages(), children && children(selectedId)), this.renderScrollMoreIcon());
  };

  return ImagePicker;
}(react_1.Component);

exports.ImagePicker = ImagePicker;