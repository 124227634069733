"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

__export(require("./Button"));

var TileButton_1 = require("./TileButton/TileButton");

exports.TileButton = TileButton_1.TileButton;