"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var initialState_1 = require("../state/initialState");

exports.loadingReducer = function (state, action) {
  if (state === void 0) {
    state = initialState_1.initialState.loading;
  }

  if (!action) return state;
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case core_1.ActionTypes.DYNAMYND_SET_LOADING:
      return setLoadingHandler(payload);

    default:
      return state;
  }
}; ///


function setLoadingHandler(payload) {
  return payload.state;
}