"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.addToastNotificationActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ToastNotificationsActionTypes.ADD_TOAST_NOTIFICATION
  });
};

exports.removeToastNotificationActionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ToastNotificationsActionTypes.REMOVE_TOAST_NOTIFICATION
  });
};