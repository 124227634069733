"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var recordAnalyticsData_epic_1 = require("./recordAnalyticsData.epic");

exports.analyticsEpic = redux_observable_1.combineEpics(recordAnalyticsData_epic_1.recordAnalyticsDataEpic);