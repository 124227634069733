"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var StylesEnum;

(function (StylesEnum) {
  StylesEnum["italic"] = "italic";
  StylesEnum["bold"] = "bold";
  StylesEnum["underline"] = "underline";
  StylesEnum["colorAndFontSize"] = "colorAndFontSize";
})(StylesEnum = exports.StylesEnum || (exports.StylesEnum = {}));