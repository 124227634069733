"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var ModulesApiService =
/** @class */
function (_super) {
  __extends(ModulesApiService, _super);

  function ModulesApiService() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  ModulesApiService.prototype.getModuleDetails = function (module, bgColor) {
    var url = this.baseUrl + "/resultsCombined.cfc";
    return this.postForm(url, {
      module: module,
      bgColor: bgColor,
      method: 'OutputCombo'
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  ModulesApiService.prototype.unlockModuleWithAccessCode = function (payload) {
    var url = this.baseUrl + "/purchase.cfc";
    return this.postForm(url, __assign(__assign({}, payload), {
      method: 'purchaseWithOrgCode'
    }), api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  ModulesApiService.prototype.getPurchasingContactInfo = function () {
    var url = this.baseUrl + "/content.cfc";
    return this.postForm(url, {
      method: 'page',
      siteVar: 'email-contact'
    });
  };

  ModulesApiService.prototype.getAvailableModules = function () {
    var url = this.baseUrl + "/outputgroup.cfc";
    return this.postForm(url, {
      method: 'getAvailableModule'
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  ModulesApiService.prototype.getSharedModuleData = function (moduleId, personId) {
    var url = this.baseUrl + "/socialShare.cfc";
    return this.postForm(url, {
      method: 'getShare',
      mid: moduleId,
      pid: personId
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  return ModulesApiService;
}(api_1.HttpApiWithAuth);

exports.ModulesApiService = ModulesApiService;