"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var actions_1 = require("../actions");

var state_1 = require("../state"); // todo actions


exports.purchasingContactInfoReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialModulesState.purchasingContactInfo;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ModulesActionTypes.SET_PURCHASING_CONTACT_INFO:
      return action.payload;

    default:
      return state;
  }
};