"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function noop() {
  return undefined;
}

exports.noop = noop;