"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var enums_1 = require("../enums");

var models_1 = require("../models");

var ModulesAnalyticsManager =
/** @class */
function (_super) {
  __extends(ModulesAnalyticsManager, _super);

  function ModulesAnalyticsManager(actionsMap) {
    var _this = _super.call(this, actionsMap) || this;

    _this.moduleSessionId = '';
    _this.sectionSessionId = '';
    _this.activeModulesSessions = {};
    _this.activeSectionsSessions = {};
    _this.idleReset = false;
    return _this;
  }

  ModulesAnalyticsManager.prototype.handleAction = function (action, personId, dispatch) {
    var type = action.type,
        payload = action.payload;
    var analyticsActionType = utilities_1.get(this.actionsMap, type);
    if (!analyticsActionType) return;
    this.personId = personId;
    this.dispatch = dispatch;

    switch (analyticsActionType) {
      case enums_1.ActionTypeHandlersEnum.startModuleViewSession:
        this.startModuleViewSession(payload.moduleId);
        break;

      case enums_1.ActionTypeHandlersEnum.endModuleViewSession:
        this.endModuleViewSession(payload.moduleId);
        break;

      case enums_1.ActionTypeHandlersEnum.startModuleSectionTipsAndTricksViewSession:
        this.startModuleSectionTipsAndTricksViewSession(payload);
        break;

      case enums_1.ActionTypeHandlersEnum.endModuleSectionTipsAndTricksViewSession:
        this.endModuleSectionTipsAndTricksViewSession(payload);
        break;

      case enums_1.ActionTypeHandlersEnum.moduleTipsAndTricksInteraction:
        this.moduleTipsAndTricksInteraction(payload);
        break;

      case enums_1.ActionTypeHandlersEnum.endAllActiveAnalyticsSessionsOnUserIdle:
        this.endAllActiveAnalyticsSessionsOnUserIdle();
        break;

      case enums_1.ActionTypeHandlersEnum.resumeAllLastActiveAnalyticsSessionsOnUserActive:
        this.resumeAllLastActiveAnalyticsSessionsOnUserActive();
        break;

      default:
        return;
    }
  };

  ModulesAnalyticsManager.prototype.startModuleViewSession = function (moduleId, moduleSessionId) {
    if (!moduleSessionId) this.setModuleSessionId();
    var payload = {
      sessionId: moduleSessionId || this.moduleSessionId,
      actionType: enums_1.AnalyticsActionTypeEnum.startModuleViewSession,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.modules,
      data: {
        moduleId: moduleId
      },
      personId: this.personId
    };

    if (!moduleSessionId) {
      this.activeModulesSessions[moduleId] = {
        moduleSessionId: this.moduleSessionId
      };
    }

    this.recordData(new models_1.DynamyndAnalyticsData(payload));
  };

  ModulesAnalyticsManager.prototype.endModuleViewSession = function (moduleId) {
    var sessionId = this.moduleSessionId ? this.moduleSessionId : utilities_1.get(this.activeModulesSessions[moduleId], 'moduleSessionId');
    var payload = {
      sessionId: sessionId,
      actionType: enums_1.AnalyticsActionTypeEnum.endModuleViewSession,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.modules,
      data: {
        moduleId: moduleId
      },
      personId: this.personId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(payload));
    delete this.activeModulesSessions[moduleId];
    this.resetModuleSession();
  };

  ModulesAnalyticsManager.prototype.startModuleSectionTipsAndTricksViewSession = function (payload) {
    var moduleId = payload.moduleId,
        sectionId = payload.sectionId;
    this.setSectionSessionId();
    var recordDataPayload = {
      sessionId: this.moduleSessionId,
      actionType: enums_1.AnalyticsActionTypeEnum.startModuleSectionTipsAndTricksViewSession,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.modules,
      data: {
        moduleId: moduleId,
        sectionId: sectionId,
        sectionSessionId: this.sectionSessionId
      },
      personId: this.personId
    };
    this.activeSectionsSessions[moduleId + "-" + sectionId] = {
      moduleId: moduleId,
      sectionId: sectionId,
      moduleSessionId: this.moduleSessionId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(recordDataPayload));
  };

  ModulesAnalyticsManager.prototype.endModuleSectionTipsAndTricksViewSession = function (payload) {
    var moduleId = payload.moduleId,
        sectionId = payload.sectionId;

    if (this.activeSectionsSessions[moduleId + "-" + sectionId]) {
      var moduleSessionId = this.moduleSessionId ? this.moduleSessionId : utilities_1.get(this.activeSectionsSessions[moduleId + "-" + sectionId], 'moduleSessionId');
      var recordDataPayload = {
        sessionId: moduleSessionId,
        actionType: enums_1.AnalyticsActionTypeEnum.endModuleSectionTipsAndTricksViewSession,
        action: enums_1.AnalyticsActionEnum.view,
        actionOn: enums_1.AnalyticsActionOnEnum.modules,
        data: {
          moduleId: moduleId,
          sectionId: sectionId,
          sectionSessionId: this.sectionSessionId
        },
        personId: this.personId
      };
      delete this.activeSectionsSessions[moduleId + "-" + sectionId];
      this.recordData(new models_1.DynamyndAnalyticsData(recordDataPayload));
    }
  };

  ModulesAnalyticsManager.prototype.moduleTipsAndTricksInteraction = function (payload) {
    var moduleId = payload.moduleId,
        sectionId = payload.sectionId;
    var recordDataPayload = {
      sessionId: this.moduleSessionId,
      actionType: enums_1.AnalyticsActionTypeEnum.moduleTipsAndTricksInteraction,
      action: enums_1.AnalyticsActionEnum.interaction,
      actionOn: enums_1.AnalyticsActionOnEnum.modules,
      data: {
        moduleId: moduleId,
        sectionId: sectionId
      },
      personId: this.personId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(recordDataPayload));
  };

  ModulesAnalyticsManager.prototype.endAllActiveAnalyticsSessionsOnUserIdle = function () {
    var _this = this;

    var activeSectionsSessions = Object.keys(this.activeSectionsSessions);
    var activeModulesSessions = Object.keys(this.activeModulesSessions);

    if (activeSectionsSessions.length) {
      this.idleReset = true;
      activeSectionsSessions.forEach(function (id) {
        var data = _this.activeSectionsSessions[id];
        var payload = {
          sessionId: _this.moduleSessionId,
          actionType: enums_1.AnalyticsActionTypeEnum.endModuleSectionTipsAndTricksViewSession,
          action: enums_1.AnalyticsActionEnum.view,
          actionOn: enums_1.AnalyticsActionOnEnum.modules,
          data: {
            moduleId: data.moduleId,
            sectionId: data.sectionId
          },
          personId: _this.personId
        };

        _this.recordData(new models_1.DynamyndAnalyticsData(payload));
      });
    }

    if (activeModulesSessions.length) {
      this.idleReset = true;
      activeModulesSessions.forEach(function (moduleId) {
        var data = _this.activeModulesSessions[moduleId];
        var payload = {
          sessionId: data.moduleSessionId,
          actionType: enums_1.AnalyticsActionTypeEnum.endModuleViewSession,
          action: enums_1.AnalyticsActionEnum.view,
          actionOn: enums_1.AnalyticsActionOnEnum.modules,
          data: {
            moduleId: moduleId
          },
          personId: _this.personId
        };

        _this.recordData(new models_1.DynamyndAnalyticsData(payload));
      });
    }

    this.resetModuleSession();
    this.resetSectionSessionId();
  };

  ModulesAnalyticsManager.prototype.resumeAllLastActiveAnalyticsSessionsOnUserActive = function () {
    var _this = this;

    if (this.idleReset) {
      this.idleReset = false;
      var activeSectionsSessions = Object.keys(this.activeSectionsSessions);
      var activeModulesSessions = Object.keys(this.activeModulesSessions);

      if (activeSectionsSessions.length) {
        activeSectionsSessions.forEach(function (id) {
          var data = _this.activeSectionsSessions[id];

          _this.startModuleSectionTipsAndTricksViewSession({
            moduleId: data.moduleId,
            sectionId: data.sectionId
          });
        });
      }

      if (activeModulesSessions.length) {
        activeModulesSessions.forEach(function (moduleId) {
          var data = _this.activeModulesSessions[moduleId];

          _this.startModuleViewSession(moduleId, data.moduleSessionId);
        });
      }
    }
  };

  ModulesAnalyticsManager.prototype.setModuleSessionId = function () {
    this.moduleSessionId = this.generateId();
  };

  ModulesAnalyticsManager.prototype.setSectionSessionId = function () {
    this.sectionSessionId = this.generateId();
  };

  ModulesAnalyticsManager.prototype.resetSectionSessionId = function () {
    this.sectionSessionId = '';
  };

  ModulesAnalyticsManager.prototype.resetModuleSession = function () {
    this.moduleSessionId = '';
  };

  return ModulesAnalyticsManager;
}(models_1.AnalyticsProviderAbstract);

exports.ModulesAnalyticsManager = ModulesAnalyticsManager;