"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var user_1 = require("@dynamynd/user");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

function withAnalyticsTracker(providers) {
  return function (WrappedComponent) {
    var WithAnalyticsComponent =
    /** @class */
    function (_super) {
      __extends(WithAnalyticsComponent, _super);

      function WithAnalyticsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
      }

      WithAnalyticsComponent.prototype.componentDidMount = function () {
        var _a = this.props,
            personId = _a.personId,
            origAccessCode = _a.origAccessCode;
        this.setPersonId(personId);
        this.setOrigAccessCode(origAccessCode);
        this.recordPageView();
      };

      WithAnalyticsComponent.prototype.componentDidUpdate = function (prevProps) {
        var prevPersonId = prevProps.personId,
            prevOrigAccessCode = prevProps.origAccessCode;
        var _a = this.props,
            personId = _a.personId,
            origAccessCode = _a.origAccessCode;

        if (prevPersonId !== personId) {
          this.setPersonId(personId);
        }

        if (prevOrigAccessCode !== origAccessCode) {
          this.setOrigAccessCode(origAccessCode);
        } // handles cases when the page is loading another entity through updates in the query params


        this.recordPageView();
      };

      WithAnalyticsComponent.prototype.recordPageView = function () {
        providers.forEach(function (provider) {
          provider.recordView(core_1.locationWebService.getPageUrl());
        });
      };

      WithAnalyticsComponent.prototype.setPersonId = function (personId) {
        providers.forEach(function (provider) {
          provider.setPersonId(personId);
        });
      };

      WithAnalyticsComponent.prototype.setOrigAccessCode = function (origAccessCode) {
        providers.forEach(function (provider) {
          provider.setOrigAccessCode(origAccessCode);
        });
      };

      WithAnalyticsComponent.prototype.render = function () {
        var props = __assign({}, this.props);

        delete props.personId;
        return react_1.default.createElement(WrappedComponent, __assign({}, props));
      };

      return WithAnalyticsComponent;
    }(react_1.Component);

    var mapStateToProps = function mapStateToProps(state) {
      return {
        personId: user_1.userIdSelector(state),
        origAccessCode: user_1.userOrigAccessCodeSelector(state)
      };
    };

    var connector = react_redux_1.connect(mapStateToProps);
    return connector(WithAnalyticsComponent);
  };
}

exports.withAnalyticsTracker = withAnalyticsTracker;