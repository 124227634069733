"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../../enums");

exports.initialAuthState = {
  registerUser: {
    emailValidation: {
      email: '',
      emailAvailable: false,
      validated: false
    },
    phoneValidation: {
      phone: '',
      phoneAvailable: false,
      validated: false
    },
    userNameValidation: {
      userName: '',
      userNameAvailable: false,
      validated: false
    }
  },
  view: {
    updating: false,
    passwordReset: {
      stage: enums_1.PasswordResetScreenStageEnum.requestLink,
      username: ''
    }
  }
};