"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function analyticsCreator(analyticsProvider) {
  var analyticsMiddleware = function analyticsMiddleware(api) {
    return function (next) {
      return function (action) {
        var user = api.getState().user;
        analyticsProvider.handleAction(action, user.profile.PersonId, next);
        return next(action);
      };
    };
  };

  return analyticsMiddleware;
}

exports.analyticsCreator = analyticsCreator;