"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var components_1 = require("../../components");

var config_1 = require("../../config");

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var sections_1 = require("../sections");

function withSampleSectionModulesRender(WrappedComponent) {
  var WithSampleSectionModulesRenderHOC =
  /** @class */
  function (_super) {
    __extends(WithSampleSectionModulesRenderHOC, _super);

    function WithSampleSectionModulesRenderHOC() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.renderModules = function (options) {
        var moduleData = options.moduleData,
            summarySectionTitle = options.summarySectionTitle,
            resultsModuleName = options.resultsModuleName,
            numberOfTipsToRender = options.numberOfTipsToRender,
            showPaymentModal = options.showPaymentModal,
            _scrollToSectionHandler = options.scrollToSectionHandler,
            setSectionHeightState = options.setSectionHeightState,
            withCustomAnalytics = options.withCustomAnalytics,
            withAvailableModules = options.withAvailableModules;
        var moduleName = moduleData.moduleName,
            colored4Tubes = moduleData.colored4Tubes;
        var newType = 'Facilitator';
        var newInfoBackgroundToColor = '#CCF2E1';
        var newTubesHeader = 'Your personal combination of four powerful modes of mental energy make you a| Everyone has an equal amount of mental energy. Here’s how you naturally divide this energy among the four basic mental modes.';
        var newDescription = 'This Authentic Ability is a very rare ability to accommodate what needs to get done. Your instincts for bringing out the best in others often makes you the unsung hero of a team. These Authentic Abilities help you |Be a fabulous collaborator |Bring out the best in other people |Pick up where others leave off |Neutralize arguments';
        var newInfoSection = "You're Not Alone |You belong to a group of people who share the way you take action. It’s not about what you can or can’t do, it's about how you do things when at your best. What works for your group differs from the way other groups get things done. They help you do what’s not natural for you — and vice versa.";
        var naturalAdvantageHeader = config_1.moduleStateToComponentsContent(moduleName, moduleData, resultsModuleName).naturalAdvantageHeader;
        return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(sections_1.SectionOverview, {
          imageUrl: colored4Tubes,
          type: newType,
          description: newDescription,
          tubesHeader: newTubesHeader,
          naturalAdvantageHeader: naturalAdvantageHeader,
          onLayoutHandler: setSectionHeightState,
          scrollToSectionHandler: function scrollToSectionHandler() {
            return _scrollToSectionHandler(enums_1.ModuleSectionViewsEnum.overview);
          }
        }), react_1.default.createElement(sections_1.SectionInfo, {
          toBackgroundColor: newInfoBackgroundToColor,
          content: newInfoSection,
          onLayoutHandler: setSectionHeightState,
          scrollToSectionHandler: function scrollToSectionHandler() {
            return _scrollToSectionHandler(enums_1.ModuleSectionViewsEnum.info);
          }
        }), _this.renderSectionModules(moduleData, setSectionHeightState, _scrollToSectionHandler, numberOfTipsToRender, withCustomAnalytics), react_1.default.createElement(components_1.SectionSummary, {
          title: summarySectionTitle,
          showPaymentModal: showPaymentModal,
          withAvailableModules: withAvailableModules
        }));
      };

      _this.renderSectionModules = function (moduleData, setSectionHeightState, _scrollToSectionHandler2, numberOfTipsToRender, withCustomAnalytics) {
        // moduleData.mainFourModes.ff.tips.push('While you don’t need all the info, be prepared to help others find more facts. ',
        // 'If you don’t ask a lot of questions about how something needs to be done, you’ll have more leeway on how to do it.',
        // 'In both your writing and speaking, your most important points should be in your opening and closing statements.',
        // 'When in doubt, start conversations or papers with a question.',
        // 'Do all the listening you need to do before giving opinions.')
        // moduleData.mainFourModes.ft.tips.push('If the plan is getting too complicated, get rid of the nonessential parts.',
        //     'Decide whether it’s worth the effort to continue doing things the way you’re doing them.',
        //     'Check your checklists. Are they really helping or limiting options? ',
        //     'Don’t spend time creating your own systems, use one that is readymade. ',
        //     'Check whether you’re staying on track or going off the rails. ')
        // moduleData.mainFourModes.im.tips.push('Watch for body language to find unspoken guidelines from teachers and others. ',
        //     'Use what you have available, but don’t promise long-lasting results. ',
        //     'Be of service to others by helping to set up space. ',
        //     'Warn others of potential physical danger.',
        //     'Decide whether it’s worth it to repair equipment when it might be cheaper to replace it. ')
        // moduleData.mainFourModes.qs.tips.push('Use your built-in radar to judge risks — don’t let others deter you.',
        //     'You don’t need to step out of your comfort zone to innovate — as long as you set wise limits.',
        //     'Use bright colors to jog your memory or for just about anything else that matters to you. ',
        //     'If you feel adding one last thing would make a difference — do it.',
        //     'Consider the worst that could happen before deciding to take on challenges.')
        var mainFourModes = moduleData.mainFourModes,
            contentDisplayOrder = moduleData.contentDisplayOrder,
            moduleName = moduleData.moduleName;
        var separate4Tubes = moduleData.seperate4Tubes;
        var viewOrderNames = [enums_1.ModuleSectionViewsEnum.firstMo, enums_1.ModuleSectionViewsEnum.secondMo, enums_1.ModuleSectionViewsEnum.thirdMo, enums_1.ModuleSectionViewsEnum.fourthMo]; // todo labels parsed from state

        return contentDisplayOrder.map(function (modeName, idx) {
          var colorConfig = services_1.modulesService.getModuleColors(modeName, contentDisplayOrder[idx + 1], idx === 0, idx === contentDisplayOrder.length - 1);
          return react_1.default.createElement(components_1.ModuleSection, {
            key: idx,
            image: separate4Tubes && separate4Tubes[modeName],
            moduleData: mainFourModes[modeName],
            layoutType: idx % 2 === 0 ? enums_1.ModuleSectionLayoutTypeEnum.image : enums_1.ModuleSectionLayoutTypeEnum.content,
            backgroundColor: colorConfig.background,
            rectangleColor: colorConfig.tips,
            sectionName: modeName,
            moduleName: moduleName,
            viewOrderName: viewOrderNames[idx],
            onLayoutHandler: setSectionHeightState,
            scrollToSectionHandler: function scrollToSectionHandler() {
              return _scrollToSectionHandler2(viewOrderNames[idx]);
            },
            numberOfTipsToRender: numberOfTipsToRender,
            withCustomAnalytics: withCustomAnalytics
          });
        });
      };

      return _this;
    }

    WithSampleSectionModulesRenderHOC.prototype.render = function () {
      return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
        renderModules: this.renderModules
      }));
    };

    return WithSampleSectionModulesRenderHOC;
  }(react_1.Component);

  return WithSampleSectionModulesRenderHOC;
}

exports.withSampleSectionModulesRender = withSampleSectionModulesRender;