"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var initialQuestionsState_1 = require("./initialQuestionsState");

exports.initialSurveyState = {
  configs: {
    surveys: {},
    questionnaries: {},
    accessCodes: {
      active: '',
      codes: []
    }
  },
  completed: false,
  responses: {
    entities: {
      responses: {}
    },
    result: []
  },
  questionsState: initialQuestionsState_1.initialQuestionsState,
  view: {
    updating: true,
    resultsError: core_1.ErrorStateEnum.noError
  }
};