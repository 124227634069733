"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ButtonSizeEnum;

(function (ButtonSizeEnum) {
  ButtonSizeEnum["small"] = "small";
  ButtonSizeEnum["large"] = "large";
})(ButtonSizeEnum = exports.ButtonSizeEnum || (exports.ButtonSizeEnum = {}));