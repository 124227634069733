"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var responsive_1 = require("@dynamynd/responsive");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../store");

var Notification_1 = require("../Notification/Notification");

var styled_1 = require("../styled");

var ToastNotification =
/** @class */
function (_super) {
  __extends(ToastNotification, _super);

  function ToastNotification(props) {
    var _this = _super.call(this, props) || this;

    _this.scheduled = {};

    _this.renderNotifications = function (activeBreakpoint, width) {
      var notifications = _this.props.notificationsState.notifications;
      if (!notifications || !notifications.length) return null;
      return notifications.map(function (_a) {
        var id = _a.id,
            duration = _a.duration,
            message = _a.message,
            type = _a.type;

        if (!_this.isScheduled(id)) {
          _this.updateSchedule(id);

          _this.autoClose(id, duration);
        }

        return react_1.default.createElement(components_lib_1.Fade, {
          key: id,
          duration: duration,
          type: components_lib_1.Fade.type.fadeOut
        }, react_1.default.createElement(Notification_1.Notification, {
          type: type,
          message: message,
          close: _this.close(id),
          activeBreakpoint: activeBreakpoint,
          width: width
        }));
      });
    };

    _this.close = function (id) {
      return function () {
        _this.updateSchedule(id);

        _this.props.removeNotification({
          id: id
        });
      };
    };

    return _this;
  }

  ToastNotification.prototype.updateSchedule = function (id) {
    if (this.scheduled[id]) {
      delete this.scheduled[id];
      return;
    }

    this.scheduled[id] = true;
  };

  ToastNotification.prototype.isScheduled = function (id) {
    return this.scheduled[id];
  };

  ToastNotification.prototype.autoClose = function (id, duration) {
    var _this = this;

    setTimeout(function () {
      var ids = _this.props.notificationsState.ids;

      if (ids.includes(id)) {
        _this.props.removeNotification({
          id: id
        });
      }

      _this.updateSchedule(id);
    }, duration);
  };

  ToastNotification.prototype.render = function () {
    var _this = this;

    return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var activeBreakpoint = _a.activeBreakpoint,
          width = _a.width;
      return react_1.default.createElement(styled_1.NotificationsContainerView, {
        activeBreakpoint: activeBreakpoint
      }, _this.renderNotifications(activeBreakpoint, width));
    });
  };

  return ToastNotification;
}(react_1.Component);

exports.ToastNotification = ToastNotification;

var mapStateToProps = function mapStateToProps(state) {
  return {
    notificationsState: store_1.notificationsSelector(state.toastNotifications)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    removeNotification: redux_1.bindActionCreators(store_1.removeToastNotificationActionActionCreator, dispatch)
  };
};

var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.default = hoc(ToastNotification);