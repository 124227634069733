"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AnalyticsActionEnum;

(function (AnalyticsActionEnum) {
  AnalyticsActionEnum["view"] = "View";
  AnalyticsActionEnum["interaction"] = "Interaction";
})(AnalyticsActionEnum = exports.AnalyticsActionEnum || (exports.AnalyticsActionEnum = {}));