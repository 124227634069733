"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var responsive_1 = require("@dynamynd/responsive");

var react_1 = __importStar(require("react"));

var AuthLayout =
/** @class */
function (_super) {
  __extends(AuthLayout, _super);

  function AuthLayout() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  AuthLayout.prototype.render = function () {
    var _a = this.props,
        children = _a.children,
        activeBreakpoint = _a.activeBreakpoint;
    return react_1.default.createElement(components_lib_1.PopupProvider, {
      customStyles: components_lib_1.calculateMenuProviderStyles(activeBreakpoint)
    }, react_1.default.createElement(components_lib_1.BackgroundWithLeftFloatingImage, null, children));
  };

  return AuthLayout;
}(react_1.Component);

exports.default = responsive_1.withResponsiveMeta(AuthLayout);