"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var content_parser_1 = require("@dynamynd/content-parser");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var utilities_1 = require("@dynamynd/utilities");

var React = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../components");

var Home =
/** @class */
function (_super) {
  __extends(Home, _super);

  function Home() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.contentImagesUrls = ['https://d1pwqmv27l8xzj.cloudfront.net/images/dynamynd-home-content-r-col.svg', 'https://d1pwqmv27l8xzj.cloudfront.net/images/dynamynd-home-content-m-col.svg', 'https://d1pwqmv27l8xzj.cloudfront.net/images/dynamynd-home-content-l-col.svg'];
    return _this;
  }

  Home.prototype.componentDidMount = function () {
    if (!this.hasContent()) {
      this.props.getPageContent(content_1.ContentPagesEnum.home);
    }
  };

  Home.prototype.hasContent = function () {
    var pageContent = this.props.pageContent;
    return !utilities_1.isEmpty(pageContent.home);
  };

  Home.prototype.handlePageHeadings = function (activeBreakpoint, isContentReady) {
    if (isContentReady) {
      var _a = this.props.pageContent.home,
          pageTitle = _a.pageTitle,
          subTitle = _a.subTitle;
      return React.createElement(components_lib_1.ColumnCentered, null, React.createElement(components_1.TitleStyled, {
        activeBreakpoint: activeBreakpoint,
        centered: true
      }, React.createElement(content_parser_1.ParsedContent, {
        content: pageTitle
      })), React.createElement(content_parser_1.ParsedContent, {
        content: subTitle
      }));
    }

    return React.createElement(components_1.TitleStyled, {
      activeBreakpoint: activeBreakpoint,
      centered: true
    });
  };

  Home.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        WebViewComponent = _a.screenProps.components.WebViewComponent,
        navigation = _a.navigation;
    var isContentReady = this.hasContent();
    var navigationParams = utilities_1.navigateWithAccessCodeQueryParam(navigation.getParam);
    return React.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var height = _a.height,
          activeBreakpoint = _a.activeBreakpoint;
      var small = responsive_1.isSmall(activeBreakpoint);
      var SwipeComponent = components_lib_1.ContentWithSwiper;

      if (responsive_1.isLarge(activeBreakpoint) || responsive_1.isExtraLarge(activeBreakpoint)) {
        SwipeComponent = components_lib_1.RowFlexStart;
      }

      return React.createElement(content_1.WithAdditionalContent, {
        WebViewComponent: WebViewComponent,
        includeFooter: true,
        navigationExtraParams: navigationParams
      }, function (_a) {
        var showContent = _a.showContent;
        return React.createElement(components_lib_1.BackgroundWithShapes, {
          shapes: {
            topLeft: true,
            topRight: true,
            bottomLeft: true,
            bottomRight: true
          },
          shouldExpand: true
        }, React.createElement(components_lib_1.ColumnCentered, null, React.createElement(components_lib_1.Header, null, React.createElement(components_lib_1.LogoMascot, {
          color: "purple",
          version: {
            sm: 'image',
            lg: 'image'
          },
          navigationExtraParams: navigationParams
        }), small && React.createElement(components_lib_1.ContentItemsPopup, {
          navigation: navigation,
          showPrivacy: function showPrivacy() {
            return showContent(content_1.ContentPagesEnum.privacy);
          },
          showTerms: function showTerms() {
            return showContent(content_1.ContentPagesEnum.terms);
          },
          disableLogin: true,
          disableRegister: true
        })), React.createElement(components_lib_1.ContentContainerScrollable, null, _this.handlePageHeadings(activeBreakpoint, isContentReady), isContentReady ? React.createElement(React.Fragment, null, React.createElement(components_1.ButtonsRow, {
          activeBreakpoint: activeBreakpoint,
          height: height
        }, React.createElement(components_1.ButtonSpacer, {
          activeBreakpoint: activeBreakpoint
        }, React.createElement(components_lib_1.Button, {
          type: components_lib_1.ButtonTypeEnum.primary,
          text: "Create Profile",
          accessibilityLabel: 'Create profile',
          uppercase: true,
          onPress: function onPress() {
            return navigation.navigate(routes_1.RoutesKeysEnum.signup, navigationParams);
          }
        })), React.createElement(components_lib_1.Button, {
          type: components_lib_1.ButtonTypeEnum.link,
          text: "Log In",
          accessibilityLabel: 'Log In',
          uppercase: true,
          onPress: function onPress() {
            return navigation.navigate(routes_1.RoutesKeysEnum.login, navigationParams);
          }
        })), React.createElement(SwipeComponent, {
          controlsEnabled: true
        }, _this.contentImagesUrls.map(function (url, idx) {
          return React.createElement(components_1.ContentBox, {
            key: idx,
            imageUri: url,
            contentText: _this.props.pageContent[content_1.ContentPagesEnum.home].content[idx] ? _this.props.pageContent[content_1.ContentPagesEnum.home].content[idx] : ''
          });
        }))) : React.createElement(components_lib_1.LoadingIndicator, {
          size: "large"
        }))));
      });
    });
  };

  return Home;
}(React.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    pageContent: content_1.pageContentSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getPageContent: redux_1.bindActionCreators(content_1.getPageContentActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, sharing_1.withAddThisSharing);
exports.default = hoc(Home);