"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ToastNotificationPositionEnum;

(function (ToastNotificationPositionEnum) {
  ToastNotificationPositionEnum["top"] = "top";
  ToastNotificationPositionEnum["bottom"] = "bottom";
})(ToastNotificationPositionEnum = exports.ToastNotificationPositionEnum || (exports.ToastNotificationPositionEnum = {}));