"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.phoneNumberRegEx = /^\([0-9]*\)\s([0-9]*)\-[0-9]{4,7}$/;
exports.negativePhoneNumberRegEx = /^(?!\([0-9]*\)\s([0-9]*)\-[0-9]{4,7}).*$/;
exports.phoneNumberWithoutParenthesesRegEx = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
exports.negativePhoneNumberWithoutParenthesesRegEx = /^(?!\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})).*$/;
exports.emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.negativeEmailRegEx = /^(?!(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))).*$/;
exports.usaZipCodeRegex = /^([0-9]{5})(-[0-9]{4})?$/i;