import {WithPopupLayout, withPurchasedValidation, withScoringValidation} from '@dynamynd/dynamynd';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {Dashboard, SampleDashboard, SurveyResultsScreen, SampleSurveyResultsScreen, SurveyTakerScreen} from '@dynamynd/survey';
import {withRefetchUserProfile} from '@dynamynd/user';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';


/*
    Choosing which screen would be the active one is based on the MO property in the user profile model.
*/
const EnhancedDashboardScreen = compose(
    withAnalyticsTrackerWeb,
    withRefetchUserProfile,
    withScoringValidation,
    withPurchasedValidation
)(Dashboard);
const EnhancedSampleDashboardScreen = compose(
)(SampleDashboard);
const EnhancedSurveyTakerScreen = compose(
    withAnalyticsTrackerWeb,
    withRefetchUserProfile,
    withScoringValidation,
    withPurchasedValidation
)(SurveyTakerScreen);
const EnhancedSurveyResultsScreen = compose(
    withAnalyticsTrackerWeb,
    withRefetchUserProfile,
    withScoringValidation,
    withPurchasedValidation
)(SurveyResultsScreen);

const EnhancedSampleSurveyResultsScreen = compose()(SampleSurveyResultsScreen);

export const surveyNavigator = createNavigator(
    appView(WithPopupLayout),
    SwitchRouter({
        [RoutesKeysEnum.dashboard]: EnhancedDashboardScreen,
        [RoutesKeysEnum.sampleDashboard]: EnhancedSampleDashboardScreen,
        [RoutesKeysEnum.take]: EnhancedSurveyTakerScreen,
        [RoutesKeysEnum.results]: EnhancedSurveyResultsScreen,
        [RoutesKeysEnum.sampleResults]: EnhancedSampleSurveyResultsScreen
    }),
    {}
);
