"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var actions_1 = require("../actions");

var enums_1 = require("../enums");

function defaultErrorHandler(error) {
  if (!error) {
    return [actions_1.errorActionCreator({
      message: enums_1.ApplicationMessagesEnum.DefaultErrorMessage,
      type: enums_1.ApplicationErrorTypeEnum.Navigate
    })];
  }

  var errorMessage = error.message,
      type = error.type;
  var message = errorMessage ? errorMessage : enums_1.ApplicationMessagesEnum.DefaultErrorMessage;

  if (!type) {
    return [actions_1.errorActionCreator({
      message: message,
      type: enums_1.ApplicationErrorTypeEnum.Navigate
    })];
  }

  return [actions_1.errorActionCreator({
    message: message,
    type: type
  })];
}

exports.defaultErrorHandler = defaultErrorHandler;