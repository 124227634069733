"use strict";

var _a, _b;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../enums");

exports.textStylePatterns = (_a = {}, _a[enums_1.StylesEnum.italic] = {
  underscorePattern: /\b(?:_{1})(\S[\s\S]*?)(?:_{1})\b/g,
  asteriskPattern: /(?:\*{1})(\S[\s\S]*?)(?:\*{1})/g
}, _a[enums_1.StylesEnum.bold] = {
  underscorePattern: /\b(?:_{2})(\S[\s\S]*?)(?:_{2})\b/g,
  asteriskPattern: /(?:\*{2})(\S[\s\S]*?)(?:\*{2})/g
}, _a[enums_1.StylesEnum.underline] = {
  underscorePattern: /\b(?:_{3})(\S[\s\S]*?)(?:_{3})\b/g,
  asteriskPattern: /(?:\*{3})(\S[\s\S]*?)(?:\*{3})/g
}, _a[enums_1.StylesEnum.colorAndFontSize] = /=((?:\@\#[a-zA-Z0-9]{6})?(?:\$\d{1,2})?)\s([^=\s]?[\s\S]*?)=/g, _a);
exports.elementPatterns = (_b = {}, _b[enums_1.ElementsEnum.listItem] = [/(\||\[\])([\S\s]*?[^\[\]\|]*)(?:[\r\n\t]*)/g], _b[enums_1.ElementsEnum.paragraph] = [/(\||\[\])([\S\s]*?[^\[\]\|]*)(?:[\r\n\t]*)/g], _b);