"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var functions_1 = require("../functions");

var actionTypes_1 = require("./actionTypes");

exports.navigateActionCreator = function (routeName, params) {
  return functions_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_NAVIGATE,
    payload: {
      routeName: routeName,
      params: __assign({}, params)
    }
  });
};