"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ButtonTypeEnum;

(function (ButtonTypeEnum) {
  ButtonTypeEnum["primary"] = "primary";
  ButtonTypeEnum["secondary"] = "secondary";
  ButtonTypeEnum["link"] = "link";
})(ButtonTypeEnum = exports.ButtonTypeEnum || (exports.ButtonTypeEnum = {}));