"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModuleSectionLayoutTypeEnum;

(function (ModuleSectionLayoutTypeEnum) {
  ModuleSectionLayoutTypeEnum["content"] = "content";
  ModuleSectionLayoutTypeEnum["image"] = "image";
})(ModuleSectionLayoutTypeEnum = exports.ModuleSectionLayoutTypeEnum || (exports.ModuleSectionLayoutTypeEnum = {}));