"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var SurveyApiService_1 = require("./SurveyApiService");

exports.SurveyApiService = SurveyApiService_1.SurveyApiService;

var SurveyService_1 = require("./SurveyService");

exports.surveyService = SurveyService_1.surveyService;
exports.SurveyService = SurveyService_1.SurveyService;