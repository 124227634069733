"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var reselect_1 = require("reselect");

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var getSurveyState = function getSurveyState(state) {
  return state.survey;
};

var getContentState = function getContentState(state) {
  return state.content;
};

exports.mostLeastConfigurationSelector = reselect_1.createSelector(getSurveyState, function (survey) {
  return survey.configs.surveys[enums_1.SurveyTypeEnum.mostLeast];
});
exports.activeQuestionInstructionSelector = reselect_1.createSelector(getSurveyState, getContentState, function (survey, content) {
  var data = {
    id: '',
    title: '',
    label: '',
    content: ''
  };
  var contentData = content.instructions.entities.instructions[survey.configs.accessCodes.active];

  if (utilities_1.isEmpty(contentData)) {
    return data;
  }

  data.label = contentData.label.replace(/<\/?[^>]+(>|$)/g, '').trim();
  data.content = contentData.content.replace(/<\/?[^>]+(>|$)/g, '').trim();
  return data;
});
exports.scaleConfigurationSelector = reselect_1.createSelector(getSurveyState, function (survey) {
  return survey.configs.surveys[enums_1.SurveyTypeEnum.scale];
});
exports.questionsStateSelector = reselect_1.createSelector(getSurveyState, function (survey) {
  return survey.questionsState;
});

var getActiveQuestionId = function getActiveQuestionId(state) {
  return state.survey.questionsState.activeQuestionId;
};

var getQuestionsPool = function getQuestionsPool(state) {
  return state.survey.questionsState.questionsPool;
};

exports.activeQuestionIdSelector = reselect_1.createSelector([getActiveQuestionId], function (id) {
  return id;
});
exports.activeQuestionSelector = reselect_1.createSelector([getQuestionsPool, getActiveQuestionId], function (questions, id) {
  var question = questions.entities.questionsPool[id];

  if (utilities_1.isEmpty(question)) {
    return {};
  }

  return services_1.surveyService.transformQuestionsOptions(question);
});

var getQuestionsOrder = function getQuestionsOrder(state) {
  return state.survey.questionsState.questionsOrder;
};

exports.activeQuestionNumberSelector = reselect_1.createSelector([getQuestionsOrder, getActiveQuestionId], function (questionsOrder, id) {
  return questionsOrder.indexOf(id) + 1;
});
exports.questionPositionSelector = reselect_1.createSelector([getQuestionsOrder, getActiveQuestionId], function (questionsOrder, id) {
  var firstQuestionId = utilities_1.first(questionsOrder);
  var lastQuestionId = utilities_1.last(questionsOrder);
  return {
    isFirst: firstQuestionId === id,
    isLast: lastQuestionId === id
  };
});

var getResponses = function getResponses(state) {
  return state.survey.responses;
};

exports.activeQuestionResponseSelector = reselect_1.createSelector([getResponses, getActiveQuestionId], function (responses, id) {
  var response = {};

  if (!utilities_1.isEmpty(responses.entities.responses)) {
    response = responses.entities.responses[id];

    if (utilities_1.isEmpty(response)) {
      return {};
    }
  }

  return response;
});

var getQuestionsTotalCount = function getQuestionsTotalCount(state) {
  return state.survey.questionsState.totalCount;
};

exports.questionsTotalCountSelector = reselect_1.createSelector(getQuestionsTotalCount, function (totalCount) {
  return totalCount;
});

var getCompletedQuestions = function getCompletedQuestions(state) {
  return state.survey.questionsState.completedQuestions;
};

exports.progressBarStateSelector = reselect_1.createSelector([getQuestionsTotalCount, getCompletedQuestions], function (totalCount, completedQuestions) {
  return {
    totalCount: totalCount,
    completedQuestions: completedQuestions.length
  };
});

var getConfigs = function getConfigs(state) {
  return state.survey.configs;
};

exports.accessCodesSelector = reselect_1.createSelector(getConfigs, function (results) {
  return results.accessCodes.codes;
});

var getResultsErrorState = function getResultsErrorState(state) {
  return state.survey.view.resultsError;
};

exports.resultsErrorStateSelector = reselect_1.createSelector(getResultsErrorState, function (errorState) {
  return errorState;
});

var getView = function getView(state) {
  return state.survey.view;
};

exports.surveyUpdatingSelector = reselect_1.createSelector(getView, function (view) {
  return view.updating;
});

var getQuestionsState = function getQuestionsState(state) {
  return state.survey.questionsState;
};

exports.questionsStateBootstrappedSelector = reselect_1.createSelector(getQuestionsState, function (questionsState) {
  return questionsState.bootstrapped;
});