"use strict";

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../enums");

exports.elementChars = (_a = {}, _a[enums_1.ElementsEnum.listItem] = "\u2022", _a[enums_1.ElementsEnum.paragraph] = '', _a);