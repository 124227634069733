"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var modules_1 = require("@dynamynd/modules");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../SurveyResultsScreen/components");

var SharedSurveyResultsScreen =
/** @class */
function (_super) {
  __extends(SharedSurveyResultsScreen, _super);

  function SharedSurveyResultsScreen() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.numberOfTipsToRender = 3;
    _this.summarySectionTitle = 'Explore wise uses of Your Four natural abilities';

    _this.getDisplayName = function () {
      var person = _this.props.sharedModuleData.person;
      return person.firstName + " " + person.lastName;
    };

    _this.renderIntro = function () {
      var _a = _this.props,
          scrollToNextSection = _a.scrollToNextSection,
          setSectionHeightState = _a.setSectionHeightState,
          module = _a.sharedModuleData.module;
      var introContent = module.introSection;
      return react_1.default.createElement(components_1.SectionResultsIntro, {
        messageText: "",
        name: _this.getDisplayName(),
        resultsHasData: true,
        getModulesFailed: false,
        refreshResults: function refreshResults() {
          return null;
        },
        refreshExhausted: false,
        content: introContent,
        scrollToElementHandler: scrollToNextSection,
        onLayoutHandler: setSectionHeightState
      });
    };

    _this.renderContent = function () {
      var _a = _this.props,
          module = _a.sharedModuleData.module,
          renderModules = _a.renderModules,
          scrollToNextSection = _a.scrollToNextSection,
          setSectionHeightState = _a.setSectionHeightState;
      return renderModules({
        setSectionHeightState: setSectionHeightState,
        resultsModuleName: module.moduleId,
        moduleData: module,
        summarySectionTitle: _this.summarySectionTitle,
        numberOfTipsToRender: _this.numberOfTipsToRender,
        showPaymentModal: function showPaymentModal() {
          return null;
        },
        scrollToSectionHandler: scrollToNextSection,
        withCustomAnalytics: false,
        withAvailableModules: false
      });
    };

    return _this;
  }

  SharedSurveyResultsScreen.prototype.componentWillUnmount = function () {
    this.props.setSharedModuleErrorState({
      error: false
    });
  };

  SharedSurveyResultsScreen.prototype.render = function () {
    var _a = this.props,
        setSectionHeightState = _a.setSectionHeightState,
        errorState = _a.errorState;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(components_lib_1.ModulesBackground, {
      shouldExpand: errorState,
      onLayoutHandler: function onLayoutHandler(event) {
        var introHeight = event.nativeEvent.layout.height;
        setSectionHeightState(modules_1.ModuleSectionViewsEnum.intro, introHeight);
      }
    }, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
      color: "white",
      version: {
        sm: 'text',
        lg: 'image'
      }
    })), !errorState && this.renderIntro(), errorState && react_1.default.createElement(modules_1.SharedModuleNotPurchased, null)), !errorState && this.renderContent());
  };

  return SharedSurveyResultsScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    errorState: modules_1.sharedModuleErrorStateSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setSharedModuleErrorState: redux_1.bindActionCreators(modules_1.setSharedModuleErrorStateActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, modules_1.withSectionsHeight, modules_1.withSectionModulesRender, modules_1.withSharedModuleData);
exports.default = hoc(SharedSurveyResultsScreen);