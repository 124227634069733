"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var RequestLinkForm_1 = require("./RequestLinkForm");

exports.RequestLinkForm = RequestLinkForm_1.default;

var UpdatePasswordForm_1 = require("./UpdatePasswordForm");

exports.UpdatePasswordForm = UpdatePasswordForm_1.default;