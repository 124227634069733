"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RequestTypeEnum;

(function (RequestTypeEnum) {
  RequestTypeEnum["withCredentials"] = "withCredentials";
  RequestTypeEnum["withoutCredentials"] = "withoutCredentials";
})(RequestTypeEnum = exports.RequestTypeEnum || (exports.RequestTypeEnum = {}));