"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

var actions_2 = require("./../actions/actions");

exports.submitRegistrationEpic = function (actions$, state$, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_REGISTER_USER_SUBMIT), operators_1.mergeMap(function (action) {
    return authApiService.submitRegistration(action.payload.state).pipe(operators_1.mergeMap(function (response) {
      var password = action.payload.state.password;
      return rxjs_1.of(actions_1.registerResponseActionCreator(__assign(__assign({}, response), {
        password: password
      })));
    }), operators_1.catchError(function (error) {
      return rxjs_1.of(actions_2.registerFailActionCreator(error));
    }));
  }));
};

exports.registrationResponseEpic = function (actions$) {
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_REGISTER_USER_RESPONSE), operators_1.mergeMap(function (action) {
    var _a = action.payload.state,
        error = _a.error,
        email = _a.Email,
        phone = _a.CellPhone,
        password = _a.password;

    if (!error) {
      return rxjs_1.of(actions_2.loginAttemptActionCreator({
        email: email,
        phone: phone,
        password: password
      }));
    }

    return rxjs_1.of(actions_2.registerFailActionCreator(action.payload.state));
  }));
};

exports.emailExistsEpic = function (actions$, state$, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_VALID_EMAIL_CHECK), operators_1.mergeMap(function (action) {
    return authApiService.emailExists(action.payload.state).pipe(operators_1.mergeMap(function (response) {
      var emailAvailable = response.exists === 0;
      var email = response.email;
      return rxjs_1.of(actions_1.validEmailResponseActionCreator({
        email: email,
        emailAvailable: emailAvailable
      }));
    }), operators_1.catchError(core_1.defaultErrorHandler));
  }));
};

exports.phoneExistsEpic = function (actions$, state$, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_VALID_PHONE_CHECK), operators_1.mergeMap(function (action) {
    return authApiService.phoneExists(action.payload.state).pipe(operators_1.mergeMap(function (response) {
      var phoneAvailable = response.exists === 0;
      var phone = response.origPhone;
      return rxjs_1.of(actions_1.validPhoneResponseActionCreator({
        phone: phone,
        phoneAvailable: phoneAvailable
      }));
    }), operators_1.catchError(core_1.defaultErrorHandler));
  }));
};

exports.userNameExistsEpic = function (actions$, state$, _a) {
  var authApiService = _a.authApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_VALID_USERNAME_CHECK), operators_1.mergeMap(function (action) {
    return authApiService.userNameExists(action.payload.state).pipe(operators_1.mergeMap(function (response) {
      var userNameAvailable = response.exists === 0;
      var userName = response.email; // using the emailExists method, map response to userName here

      return rxjs_1.of(actions_1.validUserNameResponseActionCreator({
        userName: userName,
        userNameAvailable: userNameAvailable
      }));
    }), operators_1.catchError(core_1.defaultErrorHandler));
  }));
};

exports.registrationFailEpic = function (actions$) {
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.DYNAMYND_REGISTER_USER_FAIL), operators_1.mergeMap(function (action) {
    var message = action.payload.state;
    return rxjs_1.of(core_1.errorActionCreator({
      message: message,
      type: core_1.ApplicationErrorTypeEnum.ToastNotification
    }));
  }));
};