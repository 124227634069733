"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actions_1 = require("../actions");

var state_1 = require("../state");

exports.profileReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialUserState.profile;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.UserActionTypes.SET_USER_PROFILE:
      return action.payload;

    case actions_1.UserActionTypes.SET_USER_PROFILE_MO:
      return __assign(__assign({}, state), {
        MO: action.payload
      });

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return __assign({}, state_1.initialUserState.profile);

    default:
      return state;
  }
};