"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var enums_1 = require("../../enums");

function withSectionsHeight(WrappedComponent) {
  return (
    /** @class */
    function (_super) {
      __extends(WithSectionsHeightComponent, _super);

      function WithSectionsHeightComponent(props) {
        var _a;

        var _this = _super.call(this, props) || this;

        _this.setSectionHeightState = function (sectionName, height) {
          _this.setState(function (prevState) {
            var _a;

            return __assign(__assign({}, prevState), {
              sectionHeights: __assign(__assign({}, prevState.sectionHeights), (_a = {}, _a[sectionName] = height, _a))
            });
          });
        };

        _this.scrollToTop = function () {
          if (_this.scrollViewRef.current) {
            _this.scrollViewRef.current.scrollTo({
              x: 0,
              y: 0,
              animated: true
            });
          }
        };

        _this.scrollToNextSection = function (currentSection) {
          var sectionHeights = _this.state.sectionHeights;
          var scrollSectionsOrder = [enums_1.ModuleSectionViewsEnum.intro, enums_1.ModuleSectionViewsEnum.overview, enums_1.ModuleSectionViewsEnum.info, enums_1.ModuleSectionViewsEnum.firstMo, enums_1.ModuleSectionViewsEnum.secondMo, enums_1.ModuleSectionViewsEnum.thirdMo, enums_1.ModuleSectionViewsEnum.fourthMo];
          var sectionIndex = scrollSectionsOrder.indexOf(currentSection) + 1;
          var scrollToHeight = scrollSectionsOrder.slice(0, sectionIndex).reduce(function (acc, section) {
            // in order to scroll enough the info section we need to scroll further than the height of overview:
            if (currentSection === enums_1.ModuleSectionViewsEnum.overview) {
              return acc + sectionHeights[section] * 1.05;
            }

            return acc + sectionHeights[section];
          }, 0);

          if (_this.scrollViewRef.current) {
            _this.scrollViewRef.current.scrollTo({
              x: 0,
              y: scrollToHeight,
              animated: true
            });
          }
        };

        _this.scrollViewRef = react_1.default.createRef();
        _this.state = {
          sectionHeights: (_a = {}, _a[enums_1.ModuleSectionViewsEnum.intro] = 0, _a[enums_1.ModuleSectionViewsEnum.overview] = 0, _a[enums_1.ModuleSectionViewsEnum.info] = 0, _a[enums_1.ModuleSectionViewsEnum.firstMo] = 0, _a[enums_1.ModuleSectionViewsEnum.secondMo] = 0, _a[enums_1.ModuleSectionViewsEnum.thirdMo] = 0, _a[enums_1.ModuleSectionViewsEnum.fourthMo] = 0, _a)
        };
        return _this;
      }

      WithSectionsHeightComponent.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        var currentSectionHeights = this.state.sectionHeights;
        var nextSectionHeights = nextState.sectionHeights;

        if (!utilities_1.isEqual(currentSectionHeights, nextSectionHeights)) {
          return false;
        }

        return true;
      };

      WithSectionsHeightComponent.prototype.render = function () {
        return react_1.default.createElement(react_native_1.ScrollView, {
          ref: this.scrollViewRef
        }, react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
          scrollToNextSection: this.scrollToNextSection,
          setSectionHeightState: this.setSectionHeightState,
          scrollToTop: this.scrollToTop
        })));
      };

      return WithSectionsHeightComponent;
    }(react_1.Component)
  );
}

exports.withSectionsHeight = withSectionsHeight;