"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var reselect_1 = require("reselect");

var getToastNotifications = function getToastNotifications(state) {
  return state;
};

exports.notificationsSelector = reselect_1.createSelector(getToastNotifications, function (state) {
  var entities = state.entities,
      result = state.result;
  var notifications = entities.notifications;
  return {
    notifications: notifications ? utilities_1.values(notifications) : [],
    ids: result
  };
});