import {initializeHotjar} from '@dynamynd/analytics';
import {authService} from '@dynamynd/authentication';
import {IdleManager, setupStore} from '@dynamynd/dynamynd';
import {Responsive} from '@dynamynd/responsive';
import {defaultTheme} from '@dynamynd/theme';
import * as React from 'react';
import {AppRegistry} from 'react-native';
import {Provider} from 'react-redux';
import 'resize-observer-polyfill/dist/ResizeObserver.global';

import {ThemeProvider} from 'styled-components';
import {Viewport} from './components';
import App from './core/App/App';

const baseUrl = process.env.REACT_APP_API_BASE_URL as string;
const authUrl = process.env.REACT_APP_AUTH_URL as string;

initializeHotjar();
setup();

async function setup() {
    await authService.validateAuth();

    const store = setupStore(baseUrl, authUrl);
    const DynamyndApp = () => (
        <ThemeProvider theme={defaultTheme}>
            <Provider store={store}>
                <Viewport>
                    <Responsive>
                        <IdleManager>
                            <App />
                        </IdleManager>
                    </Responsive>
                </Viewport>
            </Provider>
        </ThemeProvider>
    );

    AppRegistry.registerComponent('App', () => DynamyndApp);
    AppRegistry.runApplication('App', {
        initialProps: {},
        rootTag: document.querySelector('#root')
    });
}
