"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var SurveyResultsScreen_1 = require("./SurveyResultsScreen");

exports.SurveyResultsScreen = SurveyResultsScreen_1.default;

var SampleSurveyResultsScreen_1 = require("./SampleSurveyResultsScreen");

exports.SampleSurveyResultsScreen = SampleSurveyResultsScreen_1.default;