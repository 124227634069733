"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AnalyticsActionTypeEnum;

(function (AnalyticsActionTypeEnum) {
  // survey
  AnalyticsActionTypeEnum["startSurveySession"] = "Start Survey Session";
  AnalyticsActionTypeEnum["endSurveySession"] = "End Survey Session";
  AnalyticsActionTypeEnum["endSession"] = "End Session";
  AnalyticsActionTypeEnum["setQuestionAnswer"] = "Set an Answer to a Survey Question";
  AnalyticsActionTypeEnum["startQuestionSession"] = "Start Question Session";
  AnalyticsActionTypeEnum["endQuestionSession"] = "End Question Session"; // modules

  AnalyticsActionTypeEnum["startModuleViewSession"] = "Start Module View Session";
  AnalyticsActionTypeEnum["endModuleViewSession"] = "End Module View Session";
  AnalyticsActionTypeEnum["startModuleSectionTipsAndTricksViewSession"] = "Start Module Section Tips And Tricks View Session";
  AnalyticsActionTypeEnum["endModuleSectionTipsAndTricksViewSession"] = "End Module Section Tips And Tricks View Session";
  AnalyticsActionTypeEnum["moduleTipsAndTricksInteraction"] = "Module Tips And Tricks interaction";
})(AnalyticsActionTypeEnum = exports.AnalyticsActionTypeEnum || (exports.AnalyticsActionTypeEnum = {}));