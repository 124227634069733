"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function last(collection) {
  return collection[collection.length - 1];
}

exports.last = last;