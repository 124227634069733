"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../store");

var UserProfileBox =
/** @class */
function (_super) {
  __extends(UserProfileBox, _super);

  function UserProfileBox() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      focusedIndex: 0
    };
    _this.lastActiveBreakpointForMenuOptions = responsive_1.ActiveBreakpointEnum.small;

    _this.generateMenuOptions = function (activeBreakpoint) {
      if (_this.lastActiveBreakpointForMenuOptions !== activeBreakpoint) {
        _this.lastActiveBreakpointForMenuOptions = activeBreakpoint;
      }

      var _a = _this.props,
          showInstructions = _a.showInstructions,
          showPrivacy = _a.showPrivacy,
          showTerms = _a.showTerms;
      var options = [{
        onSelect: _this.onOptionSelect(routes_1.RoutesKeysEnum.editUserProfile),
        render: function render() {
          var index = 0;
          return react_1.default.createElement(components_lib_1.OptionContainer, {
            withBorder: true,
            tabIndex: 0,
            onKeyDown: function onKeyDown(event) {
              return _this.handleKeyDown(event, index);
            },
            "aria-selected": _this.state.focusedIndex === index
          }, react_1.default.createElement(components_lib_1.Icon, {
            name: 'pencil',
            size: components_lib_1.IconSizeEnum.small
          }), react_1.default.createElement(components_lib_1.OptionText, {
            "aria-label": "Edit Profile"
          }, "Edit Profile"));
        },
        disabled: false
      }, {
        onSelect: _this.logout,
        render: function render() {
          var index = 1;
          return react_1.default.createElement(components_lib_1.OptionContainer, {
            withBorder: false,
            tabIndex: 0,
            onKeyDown: function onKeyDown(event) {
              return _this.handleKeyDown(event, index);
            },
            "aria-selected": _this.state.focusedIndex === index
          }, react_1.default.createElement(components_lib_1.Icon, {
            name: 'exit-to-app',
            size: components_lib_1.IconSizeEnum.small
          }), react_1.default.createElement(components_lib_1.OptionText, {
            "aria-label": "Exit"
          }, "Exit"));
        },
        disabled: false
      }];
      var small = responsive_1.isSmall(activeBreakpoint);

      if (small && showInstructions) {
        options.splice(1, 0, {
          onSelect: showInstructions,
          render: function render() {
            var index = 2;
            return react_1.default.createElement(components_lib_1.OptionContainer, {
              withBorder: true,
              tabIndex: 0,
              onKeyDown: function onKeyDown(event) {
                return _this.handleKeyDown(event, index);
              },
              "aria-selected": _this.state.focusedIndex === index
            }, react_1.default.createElement(components_lib_1.Icon, {
              name: 'information-outline',
              size: components_lib_1.IconSizeEnum.small
            }), react_1.default.createElement(components_lib_1.OptionText, {
              "aria-label": "Survey Instructions"
            }, "Survey Instructions"));
          },
          disabled: false
        });
      }

      if (small) {
        options.splice(1, 0, {
          onSelect: showPrivacy,
          render: function render() {
            var index = 3;
            return react_1.default.createElement(components_lib_1.OptionContainer, {
              withBorder: true,
              tabIndex: 0,
              onKeyDown: function onKeyDown(event) {
                return _this.handleKeyDown(event, index);
              },
              "aria-selected": _this.state.focusedIndex === index
            }, react_1.default.createElement(components_lib_1.Icon, {
              name: 'information-outline',
              size: components_lib_1.IconSizeEnum.small
            }), react_1.default.createElement(components_lib_1.OptionText, {
              "aria-label": "Privacy Policy"
            }, "Privacy Policy"));
          },
          disabled: false
        }, {
          onSelect: showTerms,
          render: function render() {
            var index = 4;
            return react_1.default.createElement(components_lib_1.OptionContainer, {
              withBorder: true,
              tabIndex: 0,
              onKeyDown: function onKeyDown(event) {
                return _this.handleKeyDown(event, index);
              },
              "aria-selected": _this.state.focusedIndex === index
            }, react_1.default.createElement(components_lib_1.Icon, {
              name: 'information-outline',
              size: components_lib_1.IconSizeEnum.small
            }), react_1.default.createElement(components_lib_1.OptionText, {
              "aria-label": "Terms and Conditions"
            }, "Terms & Conditions"));
          },
          disabled: false
        }, {
          onSelect: function onSelect() {
            return _this.navigate(routes_1.RoutesKeysEnum.contact);
          },
          render: function render() {
            var index = 5;
            return react_1.default.createElement(components_lib_1.OptionContainer, {
              withBorder: true,
              tabIndex: 0,
              onKeyDown: function onKeyDown(event) {
                return _this.handleKeyDown(event, index);
              },
              "aria-selected": _this.state.focusedIndex === index
            }, react_1.default.createElement(components_lib_1.Icon, {
              name: 'information-outline',
              size: components_lib_1.IconSizeEnum.small
            }), react_1.default.createElement(components_lib_1.OptionText, {
              "aria-label": "Contact Us"
            }, "Contact Us"));
          },
          disabled: false
        });
        options.unshift({
          render: function render() {
            var index = 6;
            return react_1.default.createElement(components_lib_1.OptionHeaderContainer, {
              withBorder: false,
              tabIndex: 0,
              onKeyDown: function onKeyDown(event) {
                return _this.handleKeyDown(event, index);
              },
              "aria-selected": _this.state.focusedIndex === index
            }, react_1.default.createElement(components_lib_1.LogoMascot, {
              color: "purple",
              version: {
                sm: 'image',
                lg: 'image'
              }
            }), react_1.default.createElement(components_lib_1.CloseContainer, null, react_1.default.createElement(components_lib_1.CloseText, {
              "aria-label": "Close Menu"
            }, "Close menu"), react_1.default.createElement(components_lib_1.Icon, {
              name: 'close',
              size: components_lib_1.IconSizeEnum.small
            })));
          },
          disabled: false
        });
      }

      return options;
    };

    _this.onOptionSelect = function (route) {
      return function () {
        _this.navigate(route);
      };
    };

    _this.navigate = function (route) {
      _this.props.navigateTo(route);
    };

    _this.renderTrigger = function (activeBreakpoint) {
      return function () {
        var _a = _this.props,
            _b = _a.userProfile,
            nickname = _b.Nickname,
            firstName = _b.FirstName,
            avatarId = _b.Avatar,
            avatars = _a.avatars;
        var avatar = avatars[avatarId] ? avatars[avatarId].source : components_lib_1.appConfig.icons.defaultAvatar;
        var renderText = activeBreakpoint !== responsive_1.ActiveBreakpointEnum.small;
        return react_1.default.createElement(components_lib_1.Trigger, null, renderText && react_1.default.createElement(components_lib_1.TriggerTextContainer, null, react_1.default.createElement(components_lib_1.TriggerText, {
          "aria-label": "User " + (nickname || firstName)
        }, nickname || firstName), react_1.default.createElement(components_lib_1.Icon, {
          name: 'chevron-down',
          size: components_lib_1.IconSizeEnum.small
        })), react_1.default.createElement(components_lib_1.Avatar, {
          size: components_lib_1.Avatar.AvatarSize.small,
          type: components_lib_1.Avatar.AvatarType.rounded,
          uri: avatar || components_lib_1.appConfig.icons.defaultAvatar,
          "aria-label": "User Avatar"
        }));
      };
    };

    _this.logout = function () {
      var _a = _this.props,
          logoutUser = _a.logoutUser,
          preLogout = _a.preLogout;

      if (preLogout) {
        preLogout();
        setTimeout(function () {
          return logoutUser();
        }, 600);
        return;
      }

      logoutUser();
    };

    _this.handleKeyDown = function (event, index) {
      var focusedIndex = _this.state.focusedIndex;

      var options = _this.generateMenuOptions(_this.lastActiveBreakpointForMenuOptions);

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();

          _this.setState({
            focusedIndex: (focusedIndex + 1) % options.length
          });

          break;

        case 'ArrowUp':
          event.preventDefault();

          _this.setState({
            focusedIndex: (focusedIndex - 1 + options.length) % options.length
          });

          break;

        case 'Enter':
          event.preventDefault();
          var selectedOption = options[focusedIndex];

          if (selectedOption && selectedOption.onSelect) {
            selectedOption.onSelect();
          }

          break;

        default:
          break;
      }
    };

    _this.getPopupRenderer = function (activeBreakpoint) {
      if (responsive_1.isSmall(activeBreakpoint)) {
        return components_lib_1.Popup.Renderers.SlideInMenuTop;
      }

      return components_lib_1.Popup.Renderers.Popover;
    };

    return _this;
  }

  UserProfileBox.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        userProfile = _a.userProfile,
        fetching = _a.fetching;
    if (fetching) return react_1.default.createElement(react_native_1.Text, null, "Loading...");
    if (!userProfile.PersonId) return null;
    return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var activeBreakpoint = _a.activeBreakpoint;
      return react_1.default.createElement(components_lib_1.RowCentered, null, react_1.default.createElement(components_lib_1.Popup, {
        renderer: _this.getPopupRenderer(activeBreakpoint),
        renderTrigger: _this.renderTrigger(activeBreakpoint),
        disabled: false,
        options: _this.generateMenuOptions(activeBreakpoint),
        optionsCustomStyles: components_lib_1.popupOptionsCustomStyles(activeBreakpoint),
        triggerCustomStyles: components_lib_1.triggerCustomStyles,
        rendererProps: {
          placement: 'bottom'
        }
      }));
    });
  };

  return UserProfileBox;
}(react_1.Component);

exports.UserProfileBox = UserProfileBox;

var mapStateToProps = function mapStateToProps(state) {
  return {
    userProfile: store_1.userProfileSelector(state),
    avatars: content_1.avatarsEntitiesSelector(state),
    fetching: store_1.viewStateFetchingSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    logoutUser: redux_1.bindActionCreators(core_1.logoutUserActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, core_1.withNavigateTo);
exports.default = hoc(UserProfileBox);