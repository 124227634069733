"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

exports.popupOptionsCustomStyles = function (activeBreakpoint) {
  return {
    optionsContainer: {
      backgroundColor: 'white',
      padding: 8,
      borderRadius: responsive_1.isSmall(activeBreakpoint) ? 0 : 8
    },
    optionWrapper: {
      padding: 0
    },
    optionTouchable: {
      activeOpacity: 80
    }
  };
};

exports.triggerCustomStyles = {
  triggerOuterWrapper: {
    padding: 10,
    flex: 1
  }
};
exports.dotsTriggerCustomStyles = {
  triggerOuterWrapper: {
    padding: 0,
    flex: 1
  }
};