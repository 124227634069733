"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialToastNotificationsState = {
  entities: {
    notifications: {}
  },
  result: []
};