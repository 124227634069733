"use strict";

var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var react_native_1 = require("react-native");

var styled_components_1 = __importStar(require("styled-components"));

var height = react_native_1.Dimensions.get('window').height;
var Wrapper = styled_components_1.default(react_native_1.View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    display: flex;\n    min-height: ", "px;\n    flex-direction: row;\n    margin: 0;\n"], ["\n    background: ", ";\n    display: flex;\n    min-height: ", "px;\n    flex-direction: row;\n    margin: 0;\n"])), function (props) {
  return props.theme.colors.white;
}, height);

var WithLayout =
/** @class */
function (_super) {
  __extends(WithLayout, _super);

  function WithLayout() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  WithLayout.prototype.render = function () {
    var children = this.props.children;
    return React.createElement(Wrapper, null, children);
  };

  return WithLayout;
}(React.Component);

exports.WithLayout = WithLayout;
exports.default = styled_components_1.withTheme(WithLayout);
var templateObject_1;