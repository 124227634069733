"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.bootstrapSurveyActionCreator = function (payload) {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.BOOTSTRAP_SURVEY
  });
};

exports.surveyTakerInactiveActionCreator = function () {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.SurveyActionTypes.SURVEY_TAKER_INACTIVE
  });
};

exports.getSurveyResponsesActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.GET_SURVEY_RESPONSES
  });
};

exports.setSurveyActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY
  });
};

exports.setQuestionsOrderActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_QUESTIONS_ORDER
  });
};

exports.setSurveyQuestionsTotalCountActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_QUESTIONS_TOTAL_COUNT
  });
};

exports.setQuestionsPoolActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_QUESTIONS_POOL
  });
};

exports.setSurveyActiveQuestionIdActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_ACTIVE_QUESTION_ID
  });
};

exports.setInstructionStatusCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_NEED_INSTRUCTION
  });
};

exports.setSurveyCompletedQuestionsActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_COMPLETED_QUESTIONS
  });
};

exports.setSurveyActiveAccessCodeActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_ACTIVE_ACCESS_CODE
  });
};

exports.setSurveyResponsesActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_RESPONSES
  });
};

exports.upsertSurveyResponseActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.UPSERT_SURVEY_RESPONSE
  });
};

exports.setQuestionnariesActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_QUESTIONNARIES
  });
};

exports.changeActiveQuestionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.CHANGE_ACTIVE_QUESTION
  });
};

exports.recordQuestionResponseActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.RECORD_QUESTION_RESPONSE
  });
};

exports.submitSurveyForScoringActionCreator = function () {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    type: actionTypes_1.SurveyActionTypes.SUBMIT_SURVEY_FOR_SCORING
  });
};

exports.setSurveyCompletionStatusActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_STATUS
  });
};

exports.setSurveyAccessCodesActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_ACCESS_CODES
  });
};

exports.updateSurveyResultsErrorStateActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_RESULTS_ERROR_STATE
  });
};

exports.setSurveyUpdatingActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SET_SURVEY_UPDATING_STATE
  });
};

exports.selectQuestionOptionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.SELECT_QUESTION_OPTION
  });
};

exports.startSurveyTakingSessionActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.SurveyActionTypes.START_SURVEY_TAKING_SESSION
  });
};

exports.updateQuestionTakingSessionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.SurveyActionTypes.UPDATE_QUESTION_TAKING_SESSION
  });
};