"use strict";

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.unlockModuleWithAccessCodeEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService,
      toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.UNLOCK_MODULE_WITH_ACCESS_CODE), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return unlockModuleWithAccessCodeHandler(payload, actions$, modulesApiService, toastNotificationService);
  }), operators_1.catchError(purchaseModulesErrorHandler));
};

var unlockModuleWithAccessCodeHandler = function unlockModuleWithAccessCodeHandler(payload, actions$, modulesApiService, toastNotificationService) {
  var accessCode = payload.accessCode,
      moduleId = payload.moduleId,
      moduleName = payload.moduleName,
      personId = payload.personId;
  return modulesApiService.unlockModuleWithAccessCode({
    personId: personId,
    module: moduleName,
    orgCode: accessCode
  }).pipe(operators_1.mergeMap(function (response) {
    var success = utilities_1.get(response, 'success');

    if (success) {
      return [actions_1.getAvailableModulesWithPurchaseActionCreator(moduleId), toastNotificationService.successAction(core_1.ApplicationMessagesEnum.SuccessfulModulePurchase)];
    }

    var error = {
      message: response.error || core_1.ApplicationMessagesEnum.SomethingWentWrong,
      type: core_1.ApplicationErrorTypeEnum.ToastNotification
    };
    return rxjs_1.of(core_1.errorActionCreator(error), actions_1.purchasingErrorActionCreator());
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(purchaseModulesErrorHandler));
};

function purchaseModulesErrorHandler(error) {
  return __spreadArrays(core_1.defaultErrorHandler(error), [actions_1.purchasingErrorActionCreator()]);
}