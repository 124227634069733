"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialModulesState = {
  activeModule: '',
  resultsModule: '',
  purchasingContactInfo: '',
  availableModules: {
    entities: {},
    result: []
  },
  modulesDetails: {
    entities: {},
    result: []
  },
  view: {
    updatingAvailableModules: false,
    purchasing: false,
    updatingAddOnModule: false
  },
  sharedModules: {
    activePersonId: '',
    activeModuleId: '',
    error: false,
    data: {}
  }
};