"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var store_1 = require("../../store");

var IdleManager =
/** @class */
function (_super) {
  __extends(IdleManager, _super);

  function IdleManager() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.timeout = 5 * 60 * 1000; // 5 min

    _this.onIdleHandler = function () {
      _this.props.userIsIdleAction();
    };

    _this.onActiveHandler = function () {
      _this.props.userIsActiveAction();
    };

    return _this;
  }

  IdleManager.prototype.render = function () {
    var children = this.props.children;
    return react_1.default.createElement(utilities_1.IdleTracker, {
      timeout: this.timeout,
      onIdleHandler: this.onIdleHandler,
      onActiveHandler: this.onActiveHandler
    }, children);
  };

  return IdleManager;
}(react_1.Component);

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    userIsActiveAction: redux_1.bindActionCreators(store_1.userIsActiveActionCreator, dispatch),
    userIsIdleAction: redux_1.bindActionCreators(store_1.userIsIdleActionCreator, dispatch)
  };
};

var hoc = react_redux_1.connect(null, mapDispatchToProps);
exports.default = hoc(IdleManager);