"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

function createCustomNavigator(AppStack, navigationService, screenProps) {
  var _a;

  return _a =
  /** @class */
  function (_super) {
    __extends(CustomNavigator, _super);

    function CustomNavigator() {
      return _super !== null && _super.apply(this, arguments) || this;
    }

    CustomNavigator.prototype.render = function () {
      var navigation = this.props.navigation;
      navigationService.setTopLevelNavigator(navigation);
      return React.createElement(AppStack, {
        navigation: navigation,
        screenProps: screenProps
      });
    };

    return CustomNavigator;
  }(React.Component), _a.router = __assign(__assign({}, AppStack.router), {
    getStateForAction: function getStateForAction(action, lastState) {
      return AppStack.router.getStateForAction(action, lastState);
    }
  }), _a;
}

exports.createCustomNavigator = createCustomNavigator;