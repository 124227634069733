"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ActionTypes;

(function (ActionTypes) {
  ActionTypes["BOOTSTRAP_APPLICATION"] = "[dynamynd] bootstrap for an authenticated flow";
  ActionTypes["ACCESS_TOKEN_EXPIRED"] = "[dynamynd] current access token is expired";
  ActionTypes["DYNAMYND_SET_LOADING"] = "[dynamynd] set loading state";
  ActionTypes["DYNAMYND_NAVIGATE"] = "[dynamynd] navigate";
  ActionTypes["DYNAMYND_LOGOUT_USER"] = "[authentication] logout the user";
  ActionTypes["DYNAMYND_ERROR"] = "[dynamynd] error in the application";
  ActionTypes["DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS"] = "[dynamynd] cancel all pending requests that requires authorization";
  ActionTypes["DYNAMYND_SUBMIT_CONTACT_PAGE_MESSAGE"] = "[dynamynd] submit a message from the contact page";
  ActionTypes["DYNAMYND_CONTACT_PAGE_MESSAGE_STATUS"] = "[dynamynd] set the status of the contact page message";
  ActionTypes["USER_IS_IDLE"] = "[dynamynd] the user is idle";
  ActionTypes["USER_IS_ACTIVE"] = "[dynamynd] the user is active within the application";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));