"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ApplicationErrorTypeEnum;

(function (ApplicationErrorTypeEnum) {
  ApplicationErrorTypeEnum["Navigate"] = "navigate";
  ApplicationErrorTypeEnum["ToastNotification"] = "toastNotification";
})(ApplicationErrorTypeEnum = exports.ApplicationErrorTypeEnum || (exports.ApplicationErrorTypeEnum = {}));