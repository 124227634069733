"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldNames;

(function (FieldNames) {
  FieldNames["name"] = "name";
  FieldNames["email"] = "email";
  FieldNames["subject"] = "subject";
  FieldNames["body"] = "body";
})(FieldNames = exports.FieldNames || (exports.FieldNames = {}));