"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ModulesApiService_1 = require("./ModulesApiService");

exports.ModulesApiService = ModulesApiService_1.ModulesApiService;

var ModulesService_1 = require("./ModulesService");

exports.modulesService = ModulesService_1.modulesService;
exports.ModulesService = ModulesService_1.ModulesService;