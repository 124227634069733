"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = require("react");

var react_native_1 = require("react-native");

var breakpoints_1 = require("../breakpoints");

var screenOrientation_1 = require("../screenOrientation");

var _a = react_native_1.Dimensions.get('window'),
    width = _a.width,
    height = _a.height;

exports.default = react_1.createContext({
  width: width,
  height: height,
  activeBreakpoint: breakpoints_1.Breakpoints.ActiveBreakpoint.small,
  screenOrientation: screenOrientation_1.ScreenOrientation.Orientation.portrait
});