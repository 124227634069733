"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

exports.triggerDefaultStyles = {
  triggerTouchable: {
    activeOpacity: 100,
    style: {
      flex: 1
    }
  }
};

exports.calculateMenuProviderStyles = function (activeBreakpoint) {
  if (responsive_1.isSmall(activeBreakpoint)) {
    return {
      backdrop: {
        backgroundColor: '#222222',
        opacity: 0.5
      }
    };
  }

  return {};
};