"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.getUserProfileActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.GET_USER_PROFILE
  });
};

exports.getUserProfileSuccessActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.GET_USER_PROFILE_SUCCESS
  });
};

exports.getUserProfileSuccessWithRedirectActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.GET_USER_PROFILE_SUCCESS_WITH_REDIRECT
  });
};

exports.setUserProfileActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.SET_USER_PROFILE
  });
};

exports.updateUserProfileActionCreator = function (payload) {
  return core_1.actionCreatorApiWithCredentialsMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.UPDATE_USER_PROFILE
  });
};

exports.setUserProfileMoActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.UserActionTypes.SET_USER_PROFILE_MO
  });
};