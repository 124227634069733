"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@react-navigation/core");

var NavigationService =
/** @class */
function () {
  function NavigationService() {
    this.navigator = null;
  }

  NavigationService.prototype.setTopLevelNavigator = function (navigatorRef) {
    this.navigator = navigatorRef;
  };

  NavigationService.prototype.navigate = function (routeName, params) {
    if (this.navigator) {
      this.navigator.dispatch(core_1.NavigationActions.navigate({
        routeName: routeName,
        params: params
      }));
    }
  };

  return NavigationService;
}();

exports.NavigationService = NavigationService;