"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var utilities_1 = require("@dynamynd/utilities");

var react_native_1 = require("react-native");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.getAvailableModulesWithPurchaseEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.GET_AVAILABLE_MODULES_WITH_PURCHASE), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return getAvailableModulesWithPurchaseHandler(payload, actions$, state$, modulesApiService);
  }), operators_1.catchError(surveyModulesErrorHandler));
};

var getAvailableModulesWithPurchaseHandler = function getAvailableModulesWithPurchaseHandler(moduleId, actions$, state$, modulesApiService) {
  return modulesApiService.getAvailableModules().pipe(operators_1.mergeMap(function (response) {
    var _a = state$.value.modules,
        _b = _a.availableModules,
        entities = _b.entities,
        result = _b.result,
        resultsModule = _a.resultsModule;
    var modules = services_1.modulesService.filterAvailableModulesPerEnv(response.data, core_1.environmentManager.getEnvironment());
    var stateUnlockedModules = result.reduce(function (accumulator, currentValue) {
      var entity = entities[currentValue];

      if (entity.unlocked) {
        accumulator.push(entity.OutputGroupId);
      }

      return accumulator;
    }, []);
    var unlockedModules = Object.keys(modules).filter(function (moduleName) {
      return modules[moduleName].unlocked;
    }).reduce(function (accumulator, currentValue) {
      accumulator.push(modules[currentValue].OutputGroupId);
      return accumulator;
    }, []);
    var actions = [actions_1.setAvailableModulesActionCreator(modules)];

    if (!stateUnlockedModules.includes(moduleId) && unlockedModules.includes(moduleId)) {
      // redirect to the appropriate route
      var resultsModuleId = utilities_1.get(modules, resultsModule + ".OutputGroupId");

      if (resultsModuleId === moduleId) {
        actions.push(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.results, {}));
      } else {
        if (moduleId === '121') {
          actions.push(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.dashboard));

          if (react_native_1.Platform.OS === 'web') {
            var personUUID = state$.value.user.profile.ID;
            var link = "https://app.dynamynd.com/printParentGuide.cfm?student_id=" + personUUID;
            window.open(prepareLink(link), '_blank');
          }
        } else {
          actions.push(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.moduleView, {
            module: moduleId
          }));
        }
      }
    }

    return actions;
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(surveyModulesErrorHandler));
};

function prepareLink(link) {
  var test = /^http/g;

  if (link.search(test) < 0) {
    return "https://" + link;
  }

  return link;
}

function surveyModulesErrorHandler() {
  var error = {
    message: core_1.ApplicationMessagesEnum.ResultsNotAvailable,
    type: core_1.ApplicationErrorTypeEnum.ToastNotification
  };
  return rxjs_1.of(core_1.errorActionCreator(error));
}