"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ActiveBreakpointEnum;

(function (ActiveBreakpointEnum) {
  ActiveBreakpointEnum["small"] = "small";
  ActiveBreakpointEnum["medium"] = "medium";
  ActiveBreakpointEnum["large"] = "large";
  ActiveBreakpointEnum["extraLarge"] = "extraLarge";
})(ActiveBreakpointEnum = exports.ActiveBreakpointEnum || (exports.ActiveBreakpointEnum = {}));