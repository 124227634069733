"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var reselect_1 = require("reselect");

var getFeatures = function getFeatures(state) {
  return state.features;
};

exports.paymentsFeatureSelector = reselect_1.createSelector([getFeatures], function (features) {
  return features.payments;
});
exports.featuresSelector = reselect_1.createSelector([getFeatures], function (features) {
  return features;
});