"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var actions_1 = require("../actions");

var state_1 = require("../state"); // todo actions


exports.sharedModulesReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialModulesState.sharedModules;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ModulesActionTypes.SET_SHARED_ACTIVE_MODULE_ID:
      return setSharedActiveModuleIdHandler(state, action.payload);

    case actions_1.ModulesActionTypes.SET_SHARED_ACTIVE_PERSON_ID:
      return setSharedActivePersonIdHandler(state, action.payload);

    case actions_1.ModulesActionTypes.SET_SHARED_MODULE_DATA:
      return setSharedDataHandler(state, action.payload);

    case actions_1.ModulesActionTypes.SET_SHARED_MODULE_ERROR_STATE:
      return setSharedModuleErrorStateHandler(state, action.payload);

    default:
      return state;
  }
}; ////


function setSharedActiveModuleIdHandler(state, _a) {
  var moduleId = _a.moduleId;
  return __assign(__assign({}, state), {
    activeModuleId: moduleId
  });
}

function setSharedActivePersonIdHandler(state, _a) {
  var personId = _a.personId;
  return __assign(__assign({}, state), {
    activePersonId: personId
  });
}

function setSharedDataHandler(state, payload) {
  var _a, _b;

  var personId = payload.personId,
      moduleId = payload.moduleId,
      module = payload.module,
      person = payload.person;

  var data = __assign({}, state.data);

  var personData = data[personId];

  if (utilities_1.isEmpty(personData)) {
    data[personId] = {
      modules: (_a = {}, _a[moduleId] = __assign({}, module), _a),
      person: __assign({}, person)
    };
  } else {
    data[personId] = {
      modules: __assign(__assign({}, personData.modules), (_b = {}, _b[moduleId] = __assign({}, module), _b)),
      person: __assign(__assign({}, personData.modules.person), person)
    };
  }

  return __assign(__assign({}, state), {
    data: data,
    error: false
  });
}

function setSharedModuleErrorStateHandler(state, payload) {
  return __assign(__assign({}, state), {
    error: payload.error
  });
}