"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ButtonIconPositionEnum;

(function (ButtonIconPositionEnum) {
  ButtonIconPositionEnum["left"] = "left";
  ButtonIconPositionEnum["right"] = "right";
  ButtonIconPositionEnum["iconWithoutText"] = "iconWithoutText";
})(ButtonIconPositionEnum = exports.ButtonIconPositionEnum || (exports.ButtonIconPositionEnum = {}));