"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

exports.logoutEpic = function (actions$, state, _a) {
  var navigationService = _a.navigationService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_LOGOUT_USER), operators_1.mergeMap(logoutEpicHandler(navigationService)), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var logoutEpicHandler = function logoutEpicHandler(navigationService) {
  return function () {
    services_1.authService.revoke();
    navigationService.navigate(routes_1.RoutesKeysEnum.home, {}); // cancel all pending requests that requires authorization

    return rxjs_1.of(core_1.cancelPendingAuthRequestsCreator());
  };
};