"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var HttpMethodsEnum;

(function (HttpMethodsEnum) {
  HttpMethodsEnum["GET"] = "GET";
  HttpMethodsEnum["POST"] = "POST";
  HttpMethodsEnum["PUT"] = "PUT";
  HttpMethodsEnum["DELETE"] = "DELETE";
})(HttpMethodsEnum = exports.HttpMethodsEnum || (exports.HttpMethodsEnum = {}));