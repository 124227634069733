"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModuleTypeEnum;

(function (ModuleTypeEnum) {
  ModuleTypeEnum["link"] = "link";
  ModuleTypeEnum["purchase"] = "purchase";
})(ModuleTypeEnum = exports.ModuleTypeEnum || (exports.ModuleTypeEnum = {}));