"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ToastNotificationsActionTypes;

(function (ToastNotificationsActionTypes) {
  ToastNotificationsActionTypes["ADD_TOAST_NOTIFICATION"] = "[Toast Notification] display a toast notification";
  ToastNotificationsActionTypes["REMOVE_TOAST_NOTIFICATION"] = "[Toast Notification] hide toast notification";
})(ToastNotificationsActionTypes = exports.ToastNotificationsActionTypes || (exports.ToastNotificationsActionTypes = {}));