"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var parseHtmlChars_1 = require("../parseHtmlChars");

function parseResultsInfoContent(content) {
  var contentWithParsedHtmlChars = parseHtmlChars_1.parseHtmlChars(content);

  var _a = contentWithParsedHtmlChars.split('|'),
      titleContent = _a[0],
      paragraphs = _a[1];

  if (paragraphs) {
    var _b = paragraphs.split('. '),
        firstSentence = _b[0],
        secondSentence = _b[1],
        thirdSentence = _b[2],
        fourthSentence = _b[3];

    return {
      titleContent: titleContent || '',
      firstParagraph: [firstSentence, secondSentence].join('. '),
      secondParagraph: [thirdSentence, fourthSentence].join('. ')
    };
  }

  return {
    titleContent: titleContent || '',
    firstParagraph: '',
    secondParagraph: ''
  };
}

exports.parseResultsInfoContent = parseResultsInfoContent;