"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var activeBreakpoint_enum_1 = require("./activeBreakpoint.enum");

var breakpoints_enum_1 = require("./breakpoints.enum");

var Breakpoints =
/** @class */
function () {
  function Breakpoints() {}

  Breakpoints.prototype.getActiveBreakpoint = function (width) {
    if (width < Breakpoints.ResponsiveBreakpoints.medium) {
      return Breakpoints.ActiveBreakpoint.small;
    }

    if (width < Breakpoints.ResponsiveBreakpoints.large) {
      return Breakpoints.ActiveBreakpoint.medium;
    }

    if (width < Breakpoints.ResponsiveBreakpoints.extraLarge) {
      return Breakpoints.ActiveBreakpoint.large;
    }

    return Breakpoints.ActiveBreakpoint.extraLarge;
  };

  Breakpoints.ActiveBreakpoint = activeBreakpoint_enum_1.ActiveBreakpointEnum;
  Breakpoints.ResponsiveBreakpoints = breakpoints_enum_1.ResponsiveBreakpointsEnum;
  return Breakpoints;
}();

exports.default = Breakpoints;