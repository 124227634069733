"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var actions_1 = require("../actions");

function withNavigateTo(WrappedComponent) {
  var WithNavigateTo =
  /** @class */
  function (_super) {
    __extends(WithNavigateTo, _super);

    function WithNavigateTo() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.navigateTo = function (route, params) {
        _this.props.navigateTo(route, params);
      };

      return _this;
    }

    WithNavigateTo.prototype.render = function () {
      return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
        navigateTo: this.navigateTo
      }));
    };

    return WithNavigateTo;
  }(react_1.Component);

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      navigateTo: redux_1.bindActionCreators(actions_1.navigateActionCreator, dispatch)
    };
  };

  var hoc = react_redux_1.connect(null, mapDispatchToProps);
  return hoc(WithNavigateTo);
}

exports.withNavigateTo = withNavigateTo;