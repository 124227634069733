"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var UserApiService =
/** @class */
function (_super) {
  __extends(UserApiService, _super);

  function UserApiService() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  UserApiService.prototype.getUserProfile = function () {
    var url = this.baseUrl + "/person.cfc";
    return this.postForm(url, {
      method: 'getPersonById'
    }, api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  UserApiService.prototype.updateUserProfile = function (userProfile) {
    var url = this.baseUrl + "/person.cfc";
    return this.postForm(url, __assign(__assign({}, userProfile), {
      method: 'updateUser'
    }), api_1.HttpApi.requestType.withCredentials, this.getAccessToken());
  };

  return UserApiService;
}(api_1.HttpApiWithAuth);

exports.UserApiService = UserApiService;