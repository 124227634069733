"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var activeBreakpoint_enum_1 = require("../breakpoints/activeBreakpoint.enum");

function isExtraLarge(activeBreakpoint) {
  return activeBreakpoint === activeBreakpoint_enum_1.ActiveBreakpointEnum.extraLarge;
}

exports.isExtraLarge = isExtraLarge;