"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var normalizr_1 = require("normalizr");

var initialSurveyState_1 = require("../state/initialSurveyState");

var actions_1 = require("./../actions"); // tslint:disable prefer-array-literal


var questionsEntity = new normalizr_1.schema.Entity('questionsPool', {}, {
  idAttribute: 'questionSampleId'
});
var questionsSchema = new normalizr_1.schema.Array(questionsEntity); // todo action types

exports.questionsStateReducer = function (state, action) {
  if (state === void 0) {
    state = initialSurveyState_1.initialSurveyState.questionsState;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.SurveyActionTypes.SET_QUESTIONS_ORDER:
      return setQuestionsOrderHandler(state, action);

    case actions_1.SurveyActionTypes.SET_QUESTIONS_POOL:
      return setQuestionsPoolHandler(state, action);

    case actions_1.SurveyActionTypes.SET_SURVEY_QUESTIONS_TOTAL_COUNT:
      return setSurveyQuestionTotalCountHandler(state, action);

    case actions_1.SurveyActionTypes.SET_COMPLETED_QUESTIONS:
      return setSurveyCompletedQuestionsHandler(state, action);

    case actions_1.SurveyActionTypes.SET_ACTIVE_QUESTION_ID:
      return setSurveyActiveQuestionIdHandler(state, action);

    case actions_1.SurveyActionTypes.SET_NEED_INSTRUCTION:
      return setSurveyInstructionHandler(state, action);

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return __assign({}, initialSurveyState_1.initialSurveyState.questionsState);

    default:
      return state;
  }
}; /////


function setQuestionsOrderHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    questionsOrder: __spreadArrays(payload)
  });
}

function setQuestionsPoolHandler(state, action) {
  var payload = action.payload;
  var questions = utilities_1.values(payload);
  var questionsPool = normalizr_1.normalize(questions, questionsSchema);
  return __assign(__assign({}, state), {
    questionsPool: questionsPool,
    bootstrapped: true
  });
}

function setSurveyQuestionTotalCountHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    totalCount: payload
  });
}

function setSurveyCompletedQuestionsHandler(state, action) {
  var completedQuestions = action.payload.completedQuestions;
  var stateCompletedQuestions = state.completedQuestions;

  if (!stateCompletedQuestions.length) {
    return __assign(__assign({}, state), {
      completedQuestions: __spreadArrays(completedQuestions)
    });
  }

  var unique = utilities_1.difference(completedQuestions, state.completedQuestions);

  if (!unique || !unique.length) {
    return state;
  }

  return __assign(__assign({}, state), {
    completedQuestions: __spreadArrays(stateCompletedQuestions, unique)
  });
}

function setSurveyActiveQuestionIdHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    activeQuestionId: payload
  });
}

function setSurveyInstructionHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    showInstruction: payload
  });
}