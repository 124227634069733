"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actions_1 = require("../actions");

var state_1 = require("../state"); // todo actions


exports.viewReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialSurveyState.view;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.SurveyActionTypes.BOOTSTRAP_SURVEY:
    case actions_1.SurveyActionTypes.RECORD_QUESTION_RESPONSE:
    case actions_1.SurveyActionTypes.SUBMIT_SURVEY_FOR_SCORING:
      return __assign(__assign({}, state), {
        updating: true
      });

    case actions_1.SurveyActionTypes.SET_SURVEY_RESPONSES:
    case actions_1.SurveyActionTypes.UPSERT_SURVEY_RESPONSE:
      return __assign(__assign({}, state), {
        updating: false
      });

    case actions_1.SurveyActionTypes.SET_SURVEY_UPDATING_STATE:
      return action.payload;

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return __assign(__assign({}, state), {
        updating: state_1.initialSurveyState.view.updating
      });

    case actions_1.SurveyActionTypes.SET_SURVEY_RESULTS_ERROR_STATE:
      return setSurveyResultsErrorStateHandler(state, action);

    default:
      return state;
  }
};

function setSurveyResultsErrorStateHandler(state, action) {
  var payload = action.payload;
  return __assign(__assign({}, state), {
    updating: false,
    resultsError: payload
  });
}