"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var baseUrl = 'https://portal.optimumhq.com/custom/dynamynd/purchase.jsp?';
var activeEnv = core_1.environmentManager.getEnvironment();

if (activeEnv === core_1.EnvironmentsEnum.test) {
  baseUrl = baseUrl + "&TestMode=true&";
}

function getPurchaseUrl(outputGroupId, personId) {
  return baseUrl + "PersonId=" + personId + "&OutputGroupId=" + outputGroupId;
}

exports.getPurchaseUrl = getPurchaseUrl;