"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var bootstrapSurvey_epic_1 = require("./bootstrapSurvey.epic");

var changeActiveQuestion_epic_1 = require("./changeActiveQuestion.epic");

var getSurveyResponses_epic_1 = require("./getSurveyResponses.epic");

var getUserProfileSuccess_epic_1 = require("./getUserProfileSuccess.epic");

var recordQuestionResponse_epic_1 = require("./recordQuestionResponse.epic");

var submitSurveyForScoring_epic_1 = require("./submitSurveyForScoring.epic");

var surveyResultsErrorFetchingModule_epic_1 = require("./surveyResultsErrorFetchingModule.epic");

var surveyResultsErrorState_epic_1 = require("./surveyResultsErrorState.epic");

exports.surveyEpic = redux_observable_1.combineEpics(getUserProfileSuccess_epic_1.getUserProfileSuccessEpic, bootstrapSurvey_epic_1.bootstrapSurveyEpic, getSurveyResponses_epic_1.getSurveyResponsesEpic, recordQuestionResponse_epic_1.recordQuestionResponseEpic, changeActiveQuestion_epic_1.changeActiveQuestionEpic, submitSurveyForScoring_epic_1.submitSurveyForScoringEpic, surveyResultsErrorState_epic_1.surveyResultsErrorStateEpic, surveyResultsErrorFetchingModule_epic_1.surveyResultsErrorFetchingModuleEpic);