"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ContentItemsEnum;

(function (ContentItemsEnum) {
  ContentItemsEnum["naturalAdvantageHeader"] = "naturalAdvantageHeader";
  ContentItemsEnum["description"] = "description";
  ContentItemsEnum["tubesHeader"] = "tubesHeader";
})(ContentItemsEnum || (ContentItemsEnum = {}));

var moduleContentSources = function moduleContentSources(resultsModuleName) {
  var _a;

  return _a = {}, _a[resultsModuleName] = function (state) {
    var _a = state.overviewSection.split('|'),
        naturalAdvantageHeader = _a[0],
        tubesHeader = _a[1];

    return {
      naturalAdvantageHeader: naturalAdvantageHeader,
      tubesHeader: tubesHeader
    };
  }, _a.default = function (state) {
    var tubesHeader = state.overviewSection;
    var naturalAdvantageHeader = state.naturalAdvantageHeader.natAdvOuput;
    return {
      naturalAdvantageHeader: naturalAdvantageHeader,
      tubesHeader: tubesHeader
    };
  }, _a;
}; //// todo REFACTOR!


exports.moduleStateToComponentsContent = function (moduleName, moduleContent, resultsModuleName) {
  var contentMap = moduleContentSources(resultsModuleName);
  var getContent = contentMap[moduleName];
  if (!getContent) getContent = contentMap.default;
  return getContent(moduleContent);
};