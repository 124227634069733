"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ToastNotificationTypeEnum;

(function (ToastNotificationTypeEnum) {
  ToastNotificationTypeEnum["info"] = "info";
  ToastNotificationTypeEnum["success"] = "success";
  ToastNotificationTypeEnum["error"] = "error";
  ToastNotificationTypeEnum["warning"] = "warning";
})(ToastNotificationTypeEnum = exports.ToastNotificationTypeEnum || (exports.ToastNotificationTypeEnum = {}));