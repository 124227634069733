"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_1 = require("@dynamynd/content");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var services_1 = require("../services");

var utils_1 = require("../utils");

var withDefaultShareTitle = 'Student Aptitude';
var withDefaultShareDescription = 'I discovered that my Authentic Abilities make me a Coordinator. Find out who you are';

function withAddThisSharing(WrappedComponent) {
  var WithAddThisSharingComponent =
  /** @class */
  function (_super) {
    __extends(WithAddThisSharingComponent, _super);

    function WithAddThisSharingComponent() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.addThisInstance = window.addthis;

      _this.withDefaultShare = function (message) {
        var defaultShareData = {
          url: services_1.sharingFacade.getSharingBaseUri(),
          title: withDefaultShareTitle,
          description: message || withDefaultShareDescription,
          media: "" + message
        };

        _this.updateSharingMetaData(defaultShareData);
      };

      _this.withModuleShare = function (data) {
        _this.updateSharingMetaData(data);
      };

      _this.updateSharingMetaData = function (_a) {
        var url = _a.url,
            title = _a.title,
            description = _a.description;

        if (_this.addThisInstance !== undefined) {
          _this.addThisInstance.update('share', 'url', url);

          _this.addThisInstance.update('share', 'title', title + " | " + description);

          _this.addThisInstance.update('share', 'description', description);

          _this.addThisInstance.update('share', 'media', description);
        }
      };

      return _this;
    }

    WithAddThisSharingComponent.prototype.componentDidMount = function () {
      // addthis library does not provide a public api for showing/hiding the share widget
      // the widget would be displayed on every page where this hoc is used and hidden when the page unmounts
      // there is no reliable event which we can use to select the widget container reliably
      setTimeout(function () {
        utils_1.addThisShareWidgetVisibilityManager(utils_1.VisibilityActionEnum.show);
      }, 600);
    };

    WithAddThisSharingComponent.prototype.componentDidUpdate = function (prevProps) {
      var prevSharingContent = prevProps.sharingContent;
      var sharingContent = this.props.sharingContent;

      if (prevSharingContent.defaultShareMessage !== sharingContent.defaultShareMessage) {
        this.withDefaultShare(sharingContent.defaultShareMessage);
      }
    };

    WithAddThisSharingComponent.prototype.componentWillUnmount = function () {
      var sharingContent = this.props.sharingContent;
      this.withDefaultShare(sharingContent.defaultShareMessage);
      utils_1.addThisShareWidgetVisibilityManager(utils_1.VisibilityActionEnum.hide);
    };

    WithAddThisSharingComponent.prototype.getSharingUrl = function (mid, pid, route) {
      var url = "" + services_1.sharingFacade.getSharingBaseUri() + route + "?" + mid + "&" + pid;
      return url;
    };

    WithAddThisSharingComponent.prototype.render = function () {
      return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
        withModuleShare: this.withModuleShare,
        getSharingUrl: this.getSharingUrl
      }), this.props.children);
    };

    return WithAddThisSharingComponent;
  }(react_1.Component);

  var mapStateToProps = function mapStateToProps(state) {
    return {
      sharingContent: content_1.sharingContentSelector(state)
    };
  };

  var connector = react_redux_1.connect(mapStateToProps);
  return connector(WithAddThisSharingComponent);
}

exports.withAddThisSharing = withAddThisSharing;