"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

var actions_2 = require("./../actions/actions");

exports.getUserProfileEpic = function (actions$, state, _a) {
  var userApiService = _a.userApiService,
      toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.UserActionTypes.GET_USER_PROFILE), operators_1.mergeMap(getUserProfileEpicHandler(actions$, userApiService, toastNotificationService)), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var getUserProfileEpicHandler = function getUserProfileEpicHandler(actions$, userApiService, toastNotificationService) {
  return function (_a) {
    var payload = _a.payload;
    var activeRoute = payload.activeRoute,
        withRedirect = payload.withRedirect;
    return userApiService.getUserProfile().pipe(operators_1.mergeMap(function (response) {
      var actions = [toastNotificationService.successAction(core_1.ApplicationMessagesEnum.LoginSuccess), actions_2.getUserProfileSuccessActionCreator({
        userProfile: response
      }), authentication_1.resetRegisterUserViewStateActionActionCreator()];

      if (withRedirect) {
        actions.push(actions_2.getUserProfileSuccessWithRedirectActionCreator({
          activeRoute: activeRoute,
          userProfile: response
        }));
      }

      return actions;
    }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(core_1.defaultErrorHandler));
  };
};