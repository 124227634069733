"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AnalyticsActionOnEnum;

(function (AnalyticsActionOnEnum) {
  AnalyticsActionOnEnum["survey"] = "Survey";
  AnalyticsActionOnEnum["modules"] = "Modules";
})(AnalyticsActionOnEnum = exports.AnalyticsActionOnEnum || (exports.AnalyticsActionOnEnum = {}));