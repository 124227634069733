"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var isMedium_1 = require("./isMedium");

var isSmall_1 = require("./isSmall");

function isSmallOrMedium(activeBreakpoint) {
  return isSmall_1.isSmall(activeBreakpoint) || isMedium_1.isMedium(activeBreakpoint);
}

exports.isSmallOrMedium = isSmallOrMedium;