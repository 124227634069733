"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var screenOrientation_enum_1 = require("./screenOrientation.enum");

var ScreenOrientation =
/** @class */
function () {
  function ScreenOrientation() {}

  ScreenOrientation.prototype.getScreenOrientation = function (width, height) {
    if (width > height) {
      return ScreenOrientation.Orientation.landscape;
    }

    return ScreenOrientation.Orientation.portrait;
  };

  ScreenOrientation.Orientation = screenOrientation_enum_1.ScreenOrientationEnum;
  return ScreenOrientation;
}();

exports.default = ScreenOrientation;