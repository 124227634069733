"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialContentState = {
  instructions: {
    entities: {
      instructions: {}
    },
    result: []
  },
  pages: {
    available: [],
    content: {}
  },
  avatars: {
    entities: {
      avatars: {}
    },
    result: []
  },
  sharing: {
    defaultShareMessage: '',
    moduleLockedMessage: ''
  }
};