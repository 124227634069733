"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions");

exports.updateUserProfileEpic = function (actions$, state$, _a) {
  var userApiService = _a.userApiService,
      toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.UserActionTypes.UPDATE_USER_PROFILE), operators_1.mergeMap(function (_a) {
    var payload = _a.payload;
    return updateUserProfileEpicHandler(actions$, payload, userApiService, toastNotificationService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

function updateUserProfileEpicHandler(actions$, payload, userApiService, toastNotificationService) {
  var userProfile = payload.userProfile,
      withPassword = payload.withPassword;
  var values = services_1.userService.prepareUpdateUserApiPayload(userProfile, withPassword);
  return userApiService.updateUserProfile(values).pipe(operators_1.mergeMap(function (response) {
    var data = response.data;
    return rxjs_1.of(toastNotificationService.successAction(core_1.ApplicationMessagesEnum.UserProfileUpdatedSuccessfully), actions_1.setUserProfileActionCreator(data));
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(core_1.defaultErrorHandler));
}