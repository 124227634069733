"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var enums_1 = require("../enums");

function matchSimpleStylePattern(text, pattern, style) {
  var _a;

  if (text === void 0) {
    text = '';
  }

  var regEx = pattern;
  var children = [];
  var lastIndex = 0;
  var result = regEx.exec(text);

  while (result !== null) {
    var capturedText = result[1];
    var matchIndex = text.indexOf(result[0]);

    if (lastIndex < matchIndex) {
      children.push({
        textContent: text.slice(lastIndex, matchIndex)
      });
    }

    children.push((_a = {
      textContent: capturedText
    }, _a[style] = true, _a));
    lastIndex = regEx.lastIndex;
    result = regEx.exec(text);
  }

  if (lastIndex <= text.length - 1) {
    children.push({
      textContent: text.slice(lastIndex, text.length)
    });
  }

  return children;
}

function parseStyleData(styleData) {
  var _a = styleData.split('$'),
      colorStr = _a[0],
      fontSizeStr = _a[1];

  var fontSize = fontSizeStr ? Number(fontSizeStr) : 0;
  var color = colorStr.replace('@', '');
  return {
    color: color,
    fontSize: fontSize
  };
}

function matchColorAndFontSizePattern(text, pattern) {
  if (text === void 0) {
    text = '';
  }

  var regEx = pattern;
  var children = [];
  var lastIndex = 0;
  var result = regEx.exec(text);

  while (result !== null) {
    var styleData = result[1];
    var parsedStyledData = parseStyleData(styleData);
    var capturedText = result[2];
    var matchIndex = text.indexOf(result[0]);

    if (lastIndex < matchIndex) {
      children.push({
        textContent: text.slice(lastIndex, matchIndex)
      });
    }

    children.push(__assign({
      textContent: capturedText
    }, parsedStyledData));
    lastIndex = regEx.lastIndex;
    result = regEx.exec(text);
  }

  if (lastIndex <= text.length - 1) {
    children.push({
      textContent: text.slice(lastIndex, text.length)
    });
  }

  return children;
}

function matchListItemsAndParagraphs(text, pattern) {
  var regEx = pattern;
  var children = [];
  var lastIndex = 0;
  var result = regEx.exec(text);
  var elementChars = {
    '|': enums_1.ElementsEnum.listItem,
    '[]': enums_1.ElementsEnum.paragraph
  };

  while (result !== null) {
    var elementChar = result[1];
    var capturedText = result[2];
    var matchIndex = text.indexOf(result[0]);
    var elementType = elementChars[elementChar];

    if (lastIndex < matchIndex) {
      children.push({
        elementType: enums_1.ElementsEnum.paragraph,
        textContent: text.slice(lastIndex, matchIndex)
      });
    }

    children.push({
      elementType: elementType,
      textContent: capturedText
    });
    lastIndex = regEx.lastIndex;
    result = regEx.exec(text);
  }

  if (lastIndex <= text.length - 1) {
    children.push({
      elementType: enums_1.ElementsEnum.paragraph,
      textContent: text.slice(lastIndex, text.length)
    });
  }

  return children;
}

exports.matchListItemsAndParagraphs = matchListItemsAndParagraphs;
exports.matchPatternFunctions = (_a = {}, _a[enums_1.StylesEnum.italic] = matchSimpleStylePattern, _a[enums_1.StylesEnum.bold] = matchSimpleStylePattern, _a[enums_1.StylesEnum.underline] = matchSimpleStylePattern, _a[enums_1.StylesEnum.colorAndFontSize] = matchColorAndFontSizePattern, _a);