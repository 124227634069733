"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authentication_1 = require("@dynamynd/authentication");

var content_1 = require("@dynamynd/content");

var features_1 = require("@dynamynd/features");

var modules_1 = require("@dynamynd/modules");

var survey_1 = require("@dynamynd/survey");

var toast_notifications_1 = require("@dynamynd/toast-notifications");

var user_1 = require("@dynamynd/user");

exports.initialState = {
  loading: false,
  contactPageMessageStatus: {
    sent: false,
    updating: false
  },
  features: features_1.initialFeaturesState,
  authentication: authentication_1.initialAuthState,
  user: user_1.initialUserState,
  survey: survey_1.initialSurveyState,
  content: content_1.initialContentState,
  modules: modules_1.initialModulesState,
  toastNotifications: toast_notifications_1.initialToastNotificationsState
};