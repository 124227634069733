"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.navigateEpic = function (actions$, state$, _a) {
  var navigationService = _a.navigationService;
  return actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_NAVIGATE), operators_1.mergeMap(function (action) {
    var _a = action.payload,
        routeName = _a.routeName,
        params = _a.params;
    navigationService.navigate(routeName, params ? params : {});
    return rxjs_1.empty();
  }));
};