"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var reselect_1 = require("reselect");

var services_1 = require("./../../services");

var getResultsModule = function getResultsModule(state) {
  return state.modules.resultsModule;
};

var getAvailableModules = function getAvailableModules(state) {
  return state.modules.availableModules;
};

var getActiveModule = function getActiveModule(state) {
  return state.modules.activeModule;
};

exports.modulesViewDataSelector = reselect_1.createSelector([getAvailableModules, getResultsModule], function (availableModules, resultsModule) {
  var result = availableModules.result;
  var entities = availableModules.entities; // removes the results module from the collection

  var filtered = result.filter(function (m) {
    return m !== resultsModule;
  });
  var ordered = utilities_1.orderBy(filtered, function (m) {
    return entities[m].unlocked;
  });
  var entitiesViewModel = services_1.modulesService.mapModulesViewData(ordered, entities);
  return entitiesViewModel;
}); /// todo merge common logic ^

exports.modulesViewDataWithoutCurrentModuleSelector = reselect_1.createSelector([getAvailableModules, getActiveModule, getResultsModule], function (availableModules, activeModule, resultsModule) {
  var result = availableModules.result;
  var entities = availableModules.entities;
  var filtered = result.filter(function (m) {
    return m !== resultsModule && m !== activeModule;
  });
  var ordered = utilities_1.orderBy(filtered, function (m) {
    return entities[m].unlocked;
  });
  var entitiesViewModel = services_1.modulesService.mapModulesViewData(ordered, entities);
  return entitiesViewModel;
});
exports.availableModulesSelector = reselect_1.createSelector([getAvailableModules], function (modules) {
  return modules.entities;
});
exports.activeModuleSelector = reselect_1.createSelector([getActiveModule], function (activeModule) {
  return activeModule;
});

var getModulesDetails = function getModulesDetails(state) {
  return state.modules.modulesDetails;
};

exports.modulesDetailsSelector = reselect_1.createSelector([getModulesDetails], function (modulesDetails) {
  return modulesDetails.entities;
});
exports.modulesWithDataSelector = reselect_1.createSelector([getModulesDetails], function (modulesDetails) {
  return modulesDetails.result;
});
exports.resultsModuleNameSelector = reselect_1.createSelector([getResultsModule], function (resultsModule) {
  return resultsModule;
});
exports.resultsModuleDetailsViewModelSelector = reselect_1.createSelector([getResultsModule, getModulesDetails, getAvailableModules], function (resultsModule, modulesDetails, availableModules) {
  var entities = modulesDetails.entities;
  var resultsModuleDetailsData = entities[resultsModule];
  var resultsModuleData = availableModules.entities[resultsModule];
  if (!resultsModuleDetailsData) return {};
  return services_1.modulesService.mapViewModel(resultsModuleDetailsData, resultsModule, resultsModule, resultsModuleData);
});
exports.addOnsModuleDetailsViewModelSelector = reselect_1.createSelector([getActiveModule, getModulesDetails, getResultsModule, getAvailableModules], function (activeModule, modulesDetails, resultsModule, availableModules) {
  var entities = modulesDetails.entities;
  var activeModuleDetailsData = entities[activeModule];
  var activeModuleData = availableModules.entities[activeModule];
  if (!activeModuleDetailsData) return {};
  return services_1.modulesService.mapViewModel(activeModuleDetailsData, activeModule, resultsModule, activeModuleData);
});
exports.naturalAdvantageValueSelector = reselect_1.createSelector([getResultsModule, getModulesDetails], function (resultsModule, modulesDetails) {
  var entities = modulesDetails.entities;
  var resultsModuleData = entities[resultsModule] ? entities[resultsModule] : {};
  return services_1.modulesService.getNaturalAdvantageValue(resultsModuleData);
});

var getViewState = function getViewState(state) {
  return state.modules.view;
};

exports.updatingAvailableModulesSelector = reselect_1.createSelector([getViewState], function (viewState) {
  return viewState.updatingAvailableModules;
});
exports.purchasingModulesViewStateSelector = reselect_1.createSelector([getViewState], function (viewState) {
  return viewState.purchasing;
});
exports.updatingAddOnModuleSelector = reselect_1.createSelector([getViewState], function (viewState) {
  return viewState.updatingAddOnModule;
});
exports.purchasedModulesSelector = reselect_1.createSelector([getAvailableModules], function (availableModules) {
  var entities = availableModules.entities;
  return availableModules.result.reduce(function (accumulator, currentValue) {
    var entity = entities[currentValue];

    if (entity.unlocked) {
      accumulator.push(entity.OutputGroupId);
    }

    return accumulator;
  }, []);
});
exports.resultsModuleMetaDataSelector = reselect_1.createSelector([getAvailableModules, getResultsModule], function (availableModules, resultsModuleKey) {
  var entities = availableModules.entities;
  var resultsModule = utilities_1.get(entities, resultsModuleKey);

  if (!resultsModule) {
    return {
      id: '',
      isUnlocked: false
    };
  }

  return {
    id: resultsModule.OutputGroupId,
    isUnlocked: resultsModule.unlocked
  };
});

var getPurchasingContactInfo = function getPurchasingContactInfo(state) {
  return state.modules.purchasingContactInfo;
};

exports.getPurchasingContactInfoSelector = reselect_1.createSelector([getPurchasingContactInfo], function (data) {
  return data;
});
exports.getActiveModuleSharingDataValues = reselect_1.createSelector([getActiveModule, exports.availableModulesSelector], function (activeModule, availableModulesEntities) {
  var moduleData = availableModulesEntities[activeModule];
  if (utilities_1.isEmpty(moduleData)) return {
    title: '',
    description: ''
  }; // todo moduleDescription should come from the modules collection

  return {
    title: moduleData.moduleDisplayName,
    description: moduleData.moduleDescription
  };
});

var getSharedActivePersonId = function getSharedActivePersonId(state) {
  return state.modules.sharedModules.activePersonId;
};

exports.getSharedActivePersonIdSelector = reselect_1.createSelector([getSharedActivePersonId], function (activePersonId) {
  return activePersonId;
});

var getSharedActiveModuleId = function getSharedActiveModuleId(state) {
  return state.modules.sharedModules.activeModuleId;
};

exports.getSharedActiveModuleIdSelector = reselect_1.createSelector([getSharedActiveModuleId], function (activeModuleId) {
  return activeModuleId;
});

var getSharedModuleData = function getSharedModuleData(state) {
  return state.modules.sharedModules.data;
};

exports.getSharedModuleDataByPersonIdSelector = reselect_1.createSelector([exports.getSharedActivePersonIdSelector, exports.getSharedActiveModuleIdSelector, getSharedModuleData], function (activePersonId, activeModuleId, sharedModulesData) {
  if (!activeModuleId || !activePersonId) {
    return {
      module: {},
      person: {}
    };
  }

  var personData = sharedModulesData[activePersonId];

  if (utilities_1.isEmpty(personData)) {
    return {
      module: {},
      person: {}
    };
  }

  var data = personData.modules[activeModuleId];

  if (utilities_1.isEmpty(data)) {
    return {
      module: {},
      person: {}
    };
  }

  var result = services_1.modulesService.mapViewModel(data, activeModuleId, '', {
    moduleID: activeModuleId,
    ModuleImageLink: ''
  });
  return {
    module: result,
    person: personData.person
  };
});

var getSharedModuleErrorState = function getSharedModuleErrorState(state) {
  return state.modules.sharedModules.error;
};

exports.sharedModuleErrorStateSelector = reselect_1.createSelector([getSharedModuleErrorState], function (errorState) {
  return errorState;
});