"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var getUserProfile_epic_1 = require("./getUserProfile.epic");

var getUserProfileSuccess_epic_1 = require("./getUserProfileSuccess.epic");

var updateUserProfile_epic_1 = require("./updateUserProfile.epic");

exports.userEpic = redux_observable_1.combineEpics(getUserProfile_epic_1.getUserProfileEpic, getUserProfileSuccess_epic_1.getUserProfileSuccessEpic, updateUserProfile_epic_1.updateUserProfileEpic);