"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var index_1 = require("../index");

var sendLabel = 'Send';

function withFormUtils(WrappedComponent) {
  return (
    /** @class */
    function (_super) {
      __extends(WithFormComponent, _super);

      function WithFormComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;

        _this.renderForm = function (renderFields) {
          return function (formProps) {
            var isSubmitting = formProps.isSubmitting;
            return react_1.default.createElement(react_1.default.Fragment, null, renderFields(formProps), react_1.default.createElement(index_1.Button, {
              type: index_1.ButtonTypeEnum.primary,
              text: sendLabel,
              onPress: _this.submitForm(formProps),
              disabled: _this.isSubmitButtonDisabled(formProps),
              uppercase: true,
              loading: isSubmitting
            }));
          };
        };

        _this.submitForm = function (props) {
          return function () {
            props.submitForm();
          };
        };

        return _this;
      }

      WithFormComponent.prototype.isSubmitButtonDisabled = function (formProps) {
        var isValid = formProps.isValid,
            isSubmitting = formProps.isSubmitting,
            touched = formProps.touched;
        return touched && !isValid || isSubmitting;
      };

      WithFormComponent.prototype.render = function () {
        return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
          renderForm: this.renderForm
        }));
      };

      return WithFormComponent;
    }(react_1.Component)
  );
}

exports.withFormUtils = withFormUtils;