"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialQuestionsState = {
  questionsPool: {
    entities: {
      questionsPool: {}
    },
    result: []
  },
  activeQuestionId: '',
  questionsOrder: [],
  completedQuestions: [],
  totalCount: 0,
  bootstrapped: false,
  showInstruction: false
};