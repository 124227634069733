"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var enums_1 = require("../../enums");

var actions_1 = require("../actions");

exports.initiateResetPasswordFlowEpic = function (actions$, state, _a) {
  var authApiService = _a.authApiService,
      toastNotificationService = _a.toastNotificationService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ActionTypes.RESET_PASSWORD_INITIATE_FLOW), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return initiateResetPasswordFlowEpicHandler(payload, authApiService, toastNotificationService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
}; //////////


var initiateResetPasswordFlowEpicHandler = function initiateResetPasswordFlowEpicHandler(payload, authApiService, toastNotificationService) {
  return authApiService.initiateResetPasswordFlow(payload).pipe(operators_1.mergeMap(function (response) {
    if (!response.success) {
      return [actions_1.updateResetPasswordStageActionActionCreator({
        stage: enums_1.PasswordResetScreenStageEnum.requestLink
      }), toastNotificationService.errorAction(core_1.ApplicationMessagesEnum.SomethingWentWrong)];
    }

    return rxjs_1.of(actions_1.updateResetPasswordStageActionActionCreator({
      stage: enums_1.PasswordResetScreenStageEnum.updatePassword
    }), toastNotificationService.infoAction(core_1.ApplicationMessagesEnum.CodeSent));
  }), operators_1.catchError(core_1.defaultErrorHandler));
};