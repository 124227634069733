"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ScreenOrientationEnum;

(function (ScreenOrientationEnum) {
  ScreenOrientationEnum["portrait"] = "portrait";
  ScreenOrientationEnum["landscape"] = "landscape";
})(ScreenOrientationEnum = exports.ScreenOrientationEnum || (exports.ScreenOrientationEnum = {}));