"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var VisibilityActionEnum;

(function (VisibilityActionEnum) {
  VisibilityActionEnum["hide"] = "none";
  VisibilityActionEnum["show"] = "block";
})(VisibilityActionEnum = exports.VisibilityActionEnum || (exports.VisibilityActionEnum = {}));

var containerSelector = '#at4-share';

function addThisShareWidgetVisibilityManager(action) {
  var e = document.querySelector(containerSelector); // tslint:disable-next-line: no-unused-expression

  if (e) {
    e.style.display = action;
  }
}

exports.addThisShareWidgetVisibilityManager = addThisShareWidgetVisibilityManager;