"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var IsQuestionRequiredEnum;

(function (IsQuestionRequiredEnum) {
  IsQuestionRequiredEnum["Yes"] = "Yes";
  IsQuestionRequiredEnum["No"] = "No";
})(IsQuestionRequiredEnum = exports.IsQuestionRequiredEnum || (exports.IsQuestionRequiredEnum = {}));