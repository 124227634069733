"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function toMilliseconds(time) {
  if (typeof time !== 'number') {
    return 0;
  }

  return time * 1000;
}

exports.toMilliseconds = toMilliseconds;