"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utilities_1 = require("@dynamynd/utilities");

var enums_1 = require("../enums");

var models_1 = require("../models");

var SurveyTakerAnalyticsManager =
/** @class */
function (_super) {
  __extends(SurveyTakerAnalyticsManager, _super);

  function SurveyTakerAnalyticsManager(actionsMap) {
    var _this = _super.call(this, actionsMap) || this;

    _this.surveySessionId = '';
    _this.surveySessionIdToResume = '';
    _this.activeQuestionSessions = {};
    _this.idleReset = false;
    return _this;
  }

  SurveyTakerAnalyticsManager.prototype.handleAction = function (action, personId, dispatch) {
    var type = action.type;
    var analyticsActionType = utilities_1.get(this.actionsMap, type);
    if (!analyticsActionType) return;
    this.personId = personId;
    this.dispatch = dispatch;

    switch (analyticsActionType) {
      case enums_1.ActionTypeHandlersEnum.startSurveyTakingSession:
        this.startSurveyTakingSession();
        break;

      case enums_1.ActionTypeHandlersEnum.endSurveyTakingSession:
        this.endSurveyTakingSessionHandler();
        break;

      case enums_1.ActionTypeHandlersEnum.handleQuestionSession:
        this.handleQuestionSession(action.payload);
        break;

      case enums_1.ActionTypeHandlersEnum.setQuestionAnswer:
        this.setQuestionAnswer(action.payload.questionId, action.payload.answerOptionIds);
        break;

      case enums_1.ActionTypeHandlersEnum.endAllActiveAnalyticsSessionsOnUserIdle:
        this.endAllActiveAnalyticsSessionsOnUserIdle();
        break;

      case enums_1.ActionTypeHandlersEnum.resumeAllLastActiveAnalyticsSessionsOnUserActive:
        this.resumeAllLastActiveAnalyticsSessionsOnUserActive();
        break;

      default:
        return;
    }
  };

  SurveyTakerAnalyticsManager.prototype.startSurveyTakingSession = function () {
    if (!this.idleReset) {
      this.setSurveySessionId();
    }

    var payload = {
      sessionId: this.surveySessionId,
      actionType: enums_1.AnalyticsActionTypeEnum.startSurveySession,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.survey,
      data: {},
      personId: this.personId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(payload));
  };

  SurveyTakerAnalyticsManager.prototype.endSurveyTakingSessionHandler = function () {
    this.revokeAllActiveEntitiesSessions();
    this.endSurveyTakingSession();
  };

  SurveyTakerAnalyticsManager.prototype.endSurveyTakingSession = function () {
    if (this.surveySessionId) {
      var payload = {
        sessionId: this.surveySessionId,
        actionType: enums_1.AnalyticsActionTypeEnum.endSurveySession,
        action: enums_1.AnalyticsActionEnum.view,
        actionOn: enums_1.AnalyticsActionOnEnum.survey,
        data: {},
        personId: this.personId
      }; // this.revokeAllActiveEntitiesSessions();

      this.recordData(new models_1.DynamyndAnalyticsData(payload));
      this.resetSession();
    }
  };

  SurveyTakerAnalyticsManager.prototype.handleQuestionSession = function (questionId) {
    var actionType = this.setQuestionSessionActionType(questionId);
    this.handleActiveQuestionSessions(actionType);
    var data = this.setQuestionSessionPayloadData(questionId, actionType);

    if (actionType === enums_1.AnalyticsActionTypeEnum.startQuestionSession) {
      this.setQuestionSession(data);
    } else {
      this.removeQuestionSession(data.questionId);
    }

    var payload = {
      actionType: actionType,
      data: data,
      sessionId: this.surveySessionId,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.survey,
      personId: this.personId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(payload));
  };

  SurveyTakerAnalyticsManager.prototype.endAllActiveAnalyticsSessionsOnUserIdle = function () {
    // end the survey session
    // record last active survey session
    // end questions sessions
    this.surveySessionIdToResume = this.surveySessionId;
    this.endSurveyTakingSession();
    this.recordAllQuestionsSessions(enums_1.AnalyticsActionTypeEnum.endQuestionSession);
    this.idleReset = true;
  };

  SurveyTakerAnalyticsManager.prototype.resumeAllLastActiveAnalyticsSessionsOnUserActive = function () {
    // start the survey session - from the last active one
    // start all the questions sessions
    if (this.idleReset) {
      this.surveySessionId = this.surveySessionIdToResume;
      this.surveySessionIdToResume = '';
      this.startSurveyTakingSession();
      this.recordAllQuestionsSessions(enums_1.AnalyticsActionTypeEnum.startQuestionSession);
      this.idleReset = false;
    }
  }; // handles the case when the user navigates back to a previous question


  SurveyTakerAnalyticsManager.prototype.handleActiveQuestionSessions = function (actionType) {
    if (actionType === enums_1.AnalyticsActionTypeEnum.endQuestionSession) return;
    this.revokeAllActiveEntitiesSessions();
  };

  SurveyTakerAnalyticsManager.prototype.revokeAllActiveEntitiesSessions = function () {
    var _this = this;

    var activeQuestionSessions = Object.keys(this.activeQuestionSessions);

    if (activeQuestionSessions.length) {
      this.recordAllQuestionsSessions(enums_1.AnalyticsActionTypeEnum.endQuestionSession);
      activeQuestionSessions.forEach(function (questionId) {
        _this.removeQuestionSession(questionId);
      });
    }
  };

  SurveyTakerAnalyticsManager.prototype.recordAllQuestionsSessions = function (actionType) {
    var _this = this;

    var activeQuestionSessions = Object.keys(this.activeQuestionSessions);

    if (activeQuestionSessions.length) {
      activeQuestionSessions.forEach(function (questionId) {
        var payload = {
          actionType: actionType,
          data: {
            questionId: questionId,
            questionSessionId: _this.activeQuestionSessions[questionId]
          },
          sessionId: _this.surveySessionId,
          action: enums_1.AnalyticsActionEnum.view,
          actionOn: enums_1.AnalyticsActionOnEnum.survey,
          personId: _this.personId
        };

        _this.recordData(new models_1.DynamyndAnalyticsData(payload));
      });
    }
  };

  SurveyTakerAnalyticsManager.prototype.setQuestionSessionActionType = function (questionId) {
    if (this.activeQuestionSessions[questionId]) {
      return enums_1.AnalyticsActionTypeEnum.endQuestionSession;
    }

    return enums_1.AnalyticsActionTypeEnum.startQuestionSession;
  };

  SurveyTakerAnalyticsManager.prototype.setQuestionSessionPayloadData = function (questionId, actionType) {
    if (actionType === enums_1.AnalyticsActionTypeEnum.startQuestionSession) {
      var questionSessionId = this.generateId();
      return {
        questionId: questionId,
        questionSessionId: questionSessionId
      };
    }

    var payload = {
      questionId: questionId,
      questionSessionId: this.activeQuestionSessions[questionId]
    };
    return payload;
  };

  SurveyTakerAnalyticsManager.prototype.setQuestionSession = function (_a) {
    var questionId = _a.questionId,
        questionSessionId = _a.questionSessionId;
    this.activeQuestionSessions[questionId] = questionSessionId;
  };

  SurveyTakerAnalyticsManager.prototype.removeQuestionSession = function (questionId) {
    delete this.activeQuestionSessions[questionId];
  };

  SurveyTakerAnalyticsManager.prototype.setQuestionAnswer = function (questionId, answerId) {
    var payload = {
      data: {
        questionId: questionId,
        answerId: answerId,
        questionSessionId: this.activeQuestionSessions[questionId]
      },
      actionType: enums_1.AnalyticsActionTypeEnum.setQuestionAnswer,
      sessionId: this.surveySessionId,
      action: enums_1.AnalyticsActionEnum.view,
      actionOn: enums_1.AnalyticsActionOnEnum.survey,
      personId: this.personId
    };
    this.recordData(new models_1.DynamyndAnalyticsData(payload));
  };

  SurveyTakerAnalyticsManager.prototype.setSurveySessionId = function () {
    this.surveySessionId = this.generateId();
  };

  SurveyTakerAnalyticsManager.prototype.resetSession = function () {
    this.surveySessionId = '';
  };

  return SurveyTakerAnalyticsManager;
}(models_1.AnalyticsProviderAbstract);

exports.SurveyTakerAnalyticsManager = SurveyTakerAnalyticsManager;