"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var services_1 = require("../../services");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.getAvailableModulesEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.GET_AVAILABLE_MODULES), operators_1.mergeMap(function () {
    return getAvailableModulesEpicHandler(actions$, modulesApiService);
  }), operators_1.catchError(surveyModulesErrorHandler));
};

var getAvailableModulesEpicHandler = function getAvailableModulesEpicHandler(actions$, modulesApiService) {
  return modulesApiService.getAvailableModules().pipe(operators_1.mergeMap(function (response) {
    var modules = services_1.modulesService.filterAvailableModulesPerEnv(response.data, core_1.environmentManager.getEnvironment());
    var resultModule = services_1.modulesService.setResultsModule(response.data, response.resultModule, core_1.environmentManager.getEnvironment());
    return [actions_1.setResultsModuleNameActionCreator(resultModule), actions_1.setAvailableModulesActionCreator(modules)];
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(surveyModulesErrorHandler));
};

function surveyModulesErrorHandler() {
  var error = {
    message: core_1.ApplicationMessagesEnum.ResultsNotAvailable,
    type: core_1.ApplicationErrorTypeEnum.ToastNotification
  };
  return rxjs_1.of(core_1.errorActionCreator(error));
}