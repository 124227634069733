"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_1 = require("redux");

var profile_reducer_1 = require("./profile.reducer");

var view_reducer_1 = require("./view.reducer");

var reducerMap = {
  profile: profile_reducer_1.profileReducer,
  view: view_reducer_1.viewReducer
};
exports.userReducer = redux_1.combineReducers(reducerMap);