"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var normalizr_1 = require("normalizr");

var actionTypes_1 = require("../actions/actionTypes");

var initialSurveyState_1 = require("../state/initialSurveyState"); // tslint:disable prefer-array-literal


var responsesEntity = new normalizr_1.schema.Entity('responses', {}, {
  idAttribute: 'QuestionnaireQuestionId'
});
var responsesSchema = new normalizr_1.schema.Array(responsesEntity); // todo actions

exports.responsesReducer = function (state, action) {
  if (state === void 0) {
    state = initialSurveyState_1.initialSurveyState.responses;
  }

  if (!action) return state;

  switch (action.type) {
    case actionTypes_1.SurveyActionTypes.SET_SURVEY_RESPONSES:
      return setSurveyResponsesHandler(state, action);

    case actionTypes_1.SurveyActionTypes.UPSERT_SURVEY_RESPONSE:
      return upsertSurveyResponseHandler(state, action);

    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      return __assign({}, initialSurveyState_1.initialSurveyState.responses);

    default:
      return state;
  }
};

function setSurveyResponsesHandler(state, action) {
  var payload = action.payload;
  var responses = payload.responses;
  var responsesNormalized = normalizr_1.normalize(responses, responsesSchema);

  if (!responsesNormalized.entities.responses) {
    responsesNormalized.entities.responses = {};
  }

  return __assign(__assign({}, state), responsesNormalized);
}

function upsertSurveyResponseHandler(state, action) {
  var payload = action.payload;
  var responseToUpdate = state.entities.responses[payload.QuestionnaireQuestionId];

  var updatedState = __assign({}, state);

  var questionnaireQuestionId = payload.QuestionnaireQuestionId;

  if (responseToUpdate) {
    updatedState.entities.responses[questionnaireQuestionId] = __assign({}, payload);
  } else {
    updatedState.entities.responses[questionnaireQuestionId] = __assign({}, payload);
    updatedState.result.push(questionnaireQuestionId);
  }

  return updatedState;
}