"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var ContentApiService =
/** @class */
function (_super) {
  __extends(ContentApiService, _super);

  function ContentApiService() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  ContentApiService.prototype.bootstrap = function () {
    var url = this.baseUrl + "/base.cfc";
    return this.postForm(url, {
      method: 'getBootstrap'
    });
  };

  ContentApiService.prototype.getAvatars = function () {
    var url = this.baseUrl + "/base.cfc";
    return this.postForm(url, {
      method: 'getAvatars'
    });
  };

  ContentApiService.prototype.fetchContent = function (page) {
    var url = this.baseUrl + "/content.cfc";
    return this.postForm(url, {
      method: 'page',
      siteVar: page
    });
  };

  ContentApiService.pages = {
    about: 'about-us',
    careers: 'careers',
    cellPhoneLoginInstructions: 'cell-phone-login-instructions',
    creativityCubes: 'creativity-cubes',
    faq: 'faq',
    homePage: 'home-page',
    instinctId: 'instinct-id',
    kathysEducationalBio: 'kathys-educational-bio',
    loginSignupInstructions: 'login-signup-instructions',
    ourTeam: 'our-team',
    privacy: 'privacy-policy',
    projectGoAhead: 'project-go-ahead',
    specialOffer: 'special-offer',
    terms: 'terms-of-use',
    testPage: 'test-page',
    thinkercizes: 'thinkercizes',
    home: 'Landing-Page',
    resultsIntroSection: 'result-page-1',
    resultsOverviewSection: 'result-page-2',
    resultsInfoSection: 'result-page-3',
    resultsSummarySection: 'page-end',
    sectionModuleLabels: '4-intros',
    stressModuleIntro: 'stress-intro',
    stressModuleOverview: 'stress-tube-header',
    stressModuleInfoSection: 'stress-transition1',
    stressModuleSummarySection: 'stress-end',
    sharedModulesDefaultMessage: 'share-home-page',
    sharedModulesLockMessage: 'share-not-purchased'
  };
  return ContentApiService;
}(api_1.HttpApi);

exports.ContentApiService = ContentApiService;