"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var initialState_1 = require("../state/initialState");

exports.contactPageMessageStatusReducer = function (state, action) {
  if (state === void 0) {
    state = initialState_1.initialState.contactPageMessageStatus;
  }

  if (!action) return state;
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case core_1.ActionTypes.DYNAMYND_SUBMIT_CONTACT_PAGE_MESSAGE:
      return contactPageMessageSubmitHandler(state);

    case core_1.ActionTypes.DYNAMYND_CONTACT_PAGE_MESSAGE_STATUS:
      return contactPageMessageStatusHandler(state, payload);

    default:
      return state;
  }
};

function contactPageMessageSubmitHandler(state) {
  return __assign(__assign({}, state), {
    updating: true
  });
}

function contactPageMessageStatusHandler(state, payload) {
  return __assign(__assign({}, state), payload);
}