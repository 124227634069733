"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var survey_1 = require("@dynamynd/survey");

var user_1 = require("@dynamynd/user");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

exports.getUserProfileSuccessWithRedirectEpic = function (actions$, state, _a) {
  var navigationService = _a.navigationService;
  return actions$.pipe(redux_observable_1.ofType(user_1.UserActionTypes.GET_USER_PROFILE_SUCCESS_WITH_REDIRECT), operators_1.mergeMap(function (_a) {
    var payload = _a.payload;
    return getUserProfileSuccessWithRedirectEpicHandler(payload, navigationService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

function getUserProfileSuccessWithRedirectEpicHandler(payload, navigationService) {
  var userProfile = payload.userProfile,
      activeRoute = payload.activeRoute;
  var isSurveyCompleted = survey_1.surveyService.isSurveyScored(userProfile.MO);
  var route = activeRoute;
  /*
      Getting the user profile happens on login and initially when the app is loaded.
      The logic here is responsible for navigating to the appropriate route based on the
      survey taken state
  */

  if (shouldRedirectToSurvey(activeRoute)) {
    route = isSurveyCompleted ? routes_1.RoutesKeysEnum.dashboard : routes_1.RoutesKeysEnum.take;
  }

  navigationService.navigate(route, {});
  return rxjs_1.empty();
}

function shouldRedirectToSurvey(activeRoute) {
  return core_1.locationWebService.authRoutesWithoutRedirect.indexOf(activeRoute) < 0;
}