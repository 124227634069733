"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var matchFunctions_1 = require("./matchFunctions");

var parseOrder_1 = require("./parseOrder");

function parseTextStyle(content) {
  var flattenedNodes = [];
  parseOrder_1.textStylesParseOrder.forEach(function (_a) {
    var style = _a.style,
        pattern = _a.pattern;
    var currentMatchFunction = matchFunctions_1.matchPatternFunctions[style];

    var iteratedNodes = __spreadArrays(flattenedNodes);

    if (iteratedNodes.length === 0) {
      flattenedNodes = currentMatchFunction(content, pattern, style);
    } else {
      var currentPatternFlattenedNodes_1 = [];
      iteratedNodes.forEach(function (node) {
        var textContent = node.textContent,
            parentStyles = __rest(node, ["textContent"]);

        var currentChildren = currentMatchFunction(textContent, pattern, style).map(function (currentChildItem) {
          return __assign(__assign({}, parentStyles), currentChildItem);
        });
        currentPatternFlattenedNodes_1 = currentPatternFlattenedNodes_1.concat(currentChildren);
      });
      flattenedNodes = __spreadArrays(currentPatternFlattenedNodes_1);
    }
  });
  return flattenedNodes;
}

exports.parseTextStyle = parseTextStyle;