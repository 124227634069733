"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subjectValues = {
  technicalSupport: {
    value: 'technicalSupport',
    label: 'Technical Support'
  },
  billing: {
    value: 'billing',
    label: 'Billing'
  },
  feedback: {
    value: 'feedback',
    label: 'Feedback'
  },
  other: {
    value: 'other',
    label: 'Other'
  }
};