"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var responsive_1 = require("@dynamynd/responsive");

var react_1 = __importStar(require("react"));

var index_1 = require("./index");

var Modal_1 = require("./Modal");

var SmallModal_1 = require("./SmallModal");

exports.WithModal = react_1.default.memo(function (_a) {
  var hideModal = _a.hideModal,
      modalClose = _a.modalClose,
      content = _a.content,
      label = _a.label,
      releaseButtonLabel = _a.releaseButtonLabel;

  var _b = react_1.useState(hideModal),
      isVisible = _b[0],
      setIsVisible = _b[1];

  react_1.useEffect(function () {
    if (hideModal) {
      setIsVisible(true);
    }
  }, [hideModal]);

  var handleClose = function handleClose() {
    setIsVisible(false);
    modalClose();
  };

  if (!isVisible) {
    return null;
  }

  return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
    var activeBreakpoint = _a.activeBreakpoint;
    return react_1.default.createElement(react_1.default.Fragment, null, responsive_1.isSmall(activeBreakpoint) ? react_1.default.createElement(SmallModal_1.SmallModal, {
      modalClose: modalClose,
      title: label,
      content: content,
      visible: isVisible
    }) : react_1.default.createElement(Modal_1.Modal, null, react_1.default.createElement(index_1.BigModal, {
      modalClose: handleClose,
      title: label,
      content: content,
      textButton: releaseButtonLabel,
      eventTypes: ['click']
    })));
  });
});