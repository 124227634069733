"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var components_1 = require("../../components");

var services_1 = require("../../services");

var store_1 = require("../../store");

var components_2 = require("./components");

var styled_1 = require("./styled");

var UserProfileEditScreen =
/** @class */
function (_super) {
  __extends(UserProfileEditScreen, _super);

  function UserProfileEditScreen() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.pageTitle = 'Edit Your Profile';
    _this.state = {
      loading: true
    };

    _this.onSubmit = function (withPassword) {
      return function (values, _a) {
        var setFieldValue = _a.setFieldValue;
        var userProfile = _this.props.userProfile;
        var userProfileUpdated = services_1.userService.mapValuesToUserProfile(userProfile, values, withPassword);

        _this.props.updateUserProfile({
          withPassword: withPassword,
          userProfile: userProfileUpdated
        }); // reset password fields if we are updating the password


        if (withPassword) {
          _this.resetPasswordFields(setFieldValue);
        }
      };
    };

    _this.renderContent = function () {
      var loading = _this.state.loading;
      var _a = _this.props,
          avatars = _a.avatars,
          theme = _a.theme,
          updating = _a.updating;

      if (loading) {
        return react_1.default.createElement(components_lib_1.LoadingIndicator, {
          size: "large",
          theme: theme
        });
      }

      var initialValues = _this.getFormInitialValues();

      return react_1.default.createElement(styled_1.PageContainer, null, react_1.default.createElement(styled_1.PageHeaderRow, null, react_1.default.createElement(components_lib_1.Title, null, _this.pageTitle)), react_1.default.createElement(components_2.UserProfileEditForm, {
        avatars: avatars,
        initialValues: initialValues,
        onSubmit: _this.onSubmit,
        updating: updating
      }));
    };

    return _this;
  }

  UserProfileEditScreen.prototype.componentDidMount = function () {
    /// potentially we would want to get the user profile always - there is a chance that the profile could
    // have been updated from another app instance and we want to have the latest data
    if (this.hasUser()) {
      this.setState({
        loading: false
      });
    }
  };

  UserProfileEditScreen.prototype.componentDidUpdate = function (prevProps) {
    // getting the user profile is triggered in the bootstrap phase
    var prevUserProfile = prevProps.userProfile;

    if (prevUserProfile.ID === '' && this.hasUser()) {
      this.setState({
        loading: false
      });
    }
  };

  UserProfileEditScreen.prototype.hasUser = function () {
    return this.props.userProfile.ID !== '';
  };

  UserProfileEditScreen.prototype.getFormInitialValues = function () {
    var userProfile = this.props.userProfile;
    return services_1.userService.mapEditProfileValues(userProfile);
  };

  UserProfileEditScreen.prototype.resetPasswordFields = function (setFieldValue) {
    setFieldValue(components_2.FieldNames.password, '');
    setFieldValue(components_2.FieldNames.confirmPassword, '');
    setFieldValue(components_2.FieldNames.currentPassword, '');
  };

  UserProfileEditScreen.prototype.render = function () {
    var screenProps = this.props.screenProps;
    var WebViewComponent = screenProps.components.WebViewComponent;
    return react_1.default.createElement(components_lib_1.UserProfileBackground, null, react_1.default.createElement(content_1.WithAdditionalContent, {
      includeFooter: false,
      WebViewComponent: WebViewComponent
    }, function (_a) {
      var showContent = _a.showContent;
      return react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
        color: "purple",
        version: {
          sm: 'image',
          lg: 'image'
        }
      }), react_1.default.createElement(components_1.UserProfileBox, {
        showPrivacy: function showPrivacy() {
          return showContent(content_1.ContentPagesEnum.privacy);
        },
        showTerms: function showTerms() {
          return showContent(content_1.ContentPagesEnum.terms);
        }
      }));
    }), react_1.default.createElement(components_lib_1.ContentContainerScrollable, null, this.renderContent()));
  };

  return UserProfileEditScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    userProfile: store_1.userProfileSelector(state),
    updating: store_1.viewStateFetchingSelector(state),
    avatars: content_1.avatarsRandomizedSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    updateUserProfile: redux_1.bindActionCreators(store_1.updateUserProfileActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme);
exports.default = hoc(UserProfileEditScreen);