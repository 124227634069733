"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var validation_1 = require("../../../validation");

var regEx_1 = require("../../../validation/regEx");

function validateAndPrepareResetPasswordModel(value) {
  var isEmail = validation_1.emailRegEx.test(value);

  if (isEmail) {
    return {
      email: value
    };
  }

  var isPhone = regEx_1.phoneNumberWithoutParenthesesRegEx.test(value);

  if (isPhone) {
    return {
      phone: value
    };
  }

  return null;
}

exports.validateAndPrepareResetPasswordModel = validateAndPrepareResetPasswordModel;