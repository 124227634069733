"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var features_1 = require("@dynamynd/features");

var routes_1 = require("@dynamynd/routes");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var index_1 = require("../index");

var purchasedProp = 'purchased';

var AvailableModules =
/** @class */
function (_super) {
  __extends(AvailableModules, _super);

  function AvailableModules() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.filters = [{
      name: 'Purchased',
      filterProp: purchasedProp,
      value: true
    }, {
      name: 'Locked',
      filterProp: purchasedProp,
      value: false
    }];
    _this.fadeDuration = 800;

    _this.renderAddOns = function (data) {
      if (window.location.pathname === '/survey/sampleresults' || window.location.pathname === '/modules/sampleview') {
        var TestsTestArray = {
          name: 'Tests',
          title: 'Tests',
          type: enums_1.ModuleTypeEnum.purchase,
          link: '',
          priceTag: '$5',
          outputGroupId: '41',
          purchased: true,
          description: "=$20 **Do Your Best on Every Test** = []You’ll do your best on tests when you study and take it your way. Only 20% of students are given test-taking advice that actually works for them. Unlock this content for individualized insights on how you can ace your exams."
        };
        var stressTestArray = {
          name: 'Stress',
          title: 'Stress',
          type: enums_1.ModuleTypeEnum.purchase,
          link: '',
          priceTag: '$5',
          outputGroupId: '32',
          purchased: true,
          description: '=$20 **Student Stress Reducer** = []We get it. Students have a ton of stress. Most of it comes from people trying to make you do it their way. Unlock this content for 20+ Tricks that help you take charge of you.'
        };
        var parentGuideArray = {
          name: 'ParentGuide',
          title: 'ParentGuide',
          type: enums_1.ModuleTypeEnum.link,
          link: 'https://app.dynamynd.com/printParentGuide.cfm?student_id=fe306a51-f031-43f1-805a-88b276bbc58c',
          priceTag: '$22',
          outputGroupId: '121',
          purchased: true,
          description: "an easy-to-follow online resource that aids parents and caregivers in supporting their child's Authentic Abilities. Parents learn how to recognize their child’s strengths so that they can provide support with solving problems, communication skills, and increasing success in school!"
        };
        var merchandiseArray = {
          name: 'Official Merchandise Store',
          title: 'Official Merchandise Store',
          type: enums_1.ModuleTypeEnum.link,
          link: 'https://www.bonfire.com/store/kolbe-youth/',
          priceTag: '$0',
          outputGroupId: '144',
          purchased: true,
          description: 'We have shirts for all Authentic Abilities! Now that you’ve discovered yours, show it off!'
        };
        var careersArray = {
          name: 'Careers',
          title: 'Careers',
          type: enums_1.ModuleTypeEnum.link,
          link: 'https://www.opgig.com/?dynamynd=yes',
          priceTag: '$0',
          outputGroupId: '57',
          purchased: true,
          description: '=$20 **OPgig Career Program** =[]We’ve partnered with OPgig® career program so you can discover your probability of success in 1,200+ careers. Use the link below to visit OPgig.com and receive an 60% discount.'
        };
        var newData = [TestsTestArray, stressTestArray, parentGuideArray, merchandiseArray, careersArray];
        data = newData;
      } // if (!data.length) return <Text>No data.</Text>;


      var paymentsFeature = _this.props.paymentsFeature;
      return data.map(function (module, idx) {
        var description = module.description,
            title = module.title,
            purchased = module.purchased;

        var cardType = _this.getCardType(purchased, paymentsFeature);

        var pressHandler = paymentsFeature ? _this.cardPressHandler(module) : utilities_1.noop;
        return react_1.default.createElement(components_lib_1.Fade, {
          key: idx,
          duration: _this.fadeDuration,
          type: components_lib_1.Fade.type.fadeIn
        }, react_1.default.createElement(components_lib_1.Card, {
          pressHandler: pressHandler,
          title: title,
          content: description,
          type: cardType,
          renderActions: _this.renderActions(module, pressHandler)
        }));
      });
    };

    _this.getCardType = function (purchased, paymentsFeature) {
      if (!paymentsFeature) {
        return components_lib_1.Card.type.locked;
      }

      return purchased ? components_lib_1.Card.type.default : components_lib_1.Card.type.locked;
    };

    _this.renderActions = function (module, pressHandler) {
      return function () {
        var _a = _this.props,
            theme = _a.theme,
            paymentsFeature = _a.paymentsFeature;

        if (paymentsFeature) {
          return _this.renderPaymentsActions(module, pressHandler);
        }

        return react_1.default.createElement(components_lib_1.Button, {
          text: "Coming soon...",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: utilities_1.noop,
          customColor: theme.colors.secondary.orange
        });
      };
    };

    _this.renderPaymentsActions = function (module, pressHandler) {
      var theme = _this.props.theme;
      var priceTag = module.priceTag,
          type = module.type,
          purchased = module.purchased;

      if (type === enums_1.ModuleTypeEnum.link) {
        if (module.name === 'ParentGuide' && !purchased) {
          return react_1.default.createElement(components_lib_1.ButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
            text: "Unlock for " + priceTag,
            uppercase: true,
            type: components_lib_1.ButtonTypeEnum.secondary,
            size: components_lib_1.ButtonSizeEnum.small,
            onPress: _this.cardPressHandler(module),
            accessibilityLabel: "Unlock for " + priceTag
          }));
        }

        return react_1.default.createElement(components_lib_1.Button, {
          text: "Read More",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: pressHandler,
          customColor: theme.colors.secondary.orange,
          accessibilityLabel: "Read More",
          icon: {
            name: 'open-in-new',
            position: components_lib_1.ButtonIconPositionEnum.right,
            size: components_lib_1.IconSizeEnum.small,
            color: theme.colors.secondary.orange
          }
        });
      }

      if (purchased) {
        return react_1.default.createElement(components_lib_1.Button, {
          text: "See Results",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: pressHandler,
          customColor: theme.colors.secondary.orange,
          accessibilityLabel: "See Results"
        });
      }

      return react_1.default.createElement(components_lib_1.ButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
        text: "Unlock for " + priceTag,
        uppercase: true,
        type: components_lib_1.ButtonTypeEnum.secondary,
        size: components_lib_1.ButtonSizeEnum.small,
        onPress: _this.cardPressHandler(module),
        accessibilityLabel: "Unlock for " + priceTag
      }));
    };

    _this.cardPressHandler = function (module) {
      return function () {
        var type = module.type,
            purchased = module.purchased;
        var _a = _this.props,
            showPaymentModal = _a.showPaymentModal,
            personId = _a.personId;
        var purchaseData = {
          personId: personId,
          outputGroupId: module.outputGroupId
        };

        if (type === enums_1.ModuleTypeEnum.link) {
          if (module.name === 'ParentGuide') {
            if (purchased) {
              var personUUID = _this.props.userId;

              if (personUUID.length > 1) {
                var link = "https://app.dynamynd.com/printParentGuide.cfm?student_id=" + personUUID;

                _this.handleLinkAddOnPress(link);
              } else {
                var link = 'https://app.dynamynd.com/printParentGuide.cfm?student_id=fe306a51-f031-43f1-805a-88b276bbc58c';

                _this.handleLinkAddOnPress(link);
              }
            } else {
              showPaymentModal(content_1.ContentPagesEnum.payment, purchaseData, _this.purchaseCloseActionHandler);
            }
          } else {
            _this.handleLinkAddOnPress(module.link);
          }

          return;
        }

        if (purchased) {
          if (module.name === 'ParentGuide') {
            var personUUID = _this.props.userId;
            var link = "https://app.dynamynd.com/printParentGuide.cfm?student_id=" + personUUID;

            _this.handleLinkAddOnPress(link);
          } else if (module.name === "Stress" && window.location.pathname === "/survey/sampledashboard") {
            _this.props.navigateTo(routes_1.RoutesKeysEnum.sampleModuleView, {
              module: "32"
            });

            _this.props.setActiveModule(module.name);
          } else if (module.name === "Tests" && window.location.pathname === "/survey/sampledashboard") {
            _this.props.navigateTo(routes_1.RoutesKeysEnum.sampleModuleView, {
              module: "41"
            });

            _this.props.setActiveModule(module.name);
          } else {
            // the order of actions is important - in AddOn module screen when changing the query string param
            // no event is triggered, so we need to rely on tracking the updates of the activeModule.
            _this.props.navigateTo(routes_1.RoutesKeysEnum.moduleView, {
              module: module.outputGroupId
            });

            _this.props.setActiveModule(module.name);
          }

          return;
        } // opens the overlay with the purchase module view


        showPaymentModal(content_1.ContentPagesEnum.payment, purchaseData, _this.purchaseCloseActionHandler);
      };
    };

    _this.handleLinkAddOnPress = function (link) {
      if (react_native_1.Platform.OS === 'web') {
        window.open(_this.prepareLink(link), '_blank');
      }
    };

    _this.prepareLink = function (link) {
      var test = /^http/g;

      if (link.search(test) < 0) {
        return "https://" + link;
      }

      return link;
    };

    _this.purchaseCloseActionHandler = function (moduleId) {
      _this.props.getAvailableModulesWithPurchase(moduleId);
    };

    return _this;
  }

  AvailableModules.prototype.render = function () {
    var _a = this.props,
        label = _a.label,
        data = _a.data,
        updatingAvailableModules = _a.updatingAvailableModules,
        displayFilters = _a.displayFilters,
        paymentsFeature = _a.paymentsFeature;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(react_1.default.Fragment, null, label && react_1.default.createElement(components_lib_1.Label, {
      uppercase: true
    }, label), updatingAvailableModules ? react_1.default.createElement(index_1.LoadingIndicatorModules, null) : react_1.default.createElement(components_lib_1.WithBoolFilters, {
      displayFilters: paymentsFeature && displayFilters,
      filters: this.filters,
      data: data,
      renderFiltered: this.renderAddOns
    })));
  };

  return AvailableModules;
}(react_1.PureComponent);

var mapStateToProps = function mapStateToProps(state) {
  return {
    updatingAvailableModules: store_1.updatingAvailableModulesSelector(state),
    personId: user_1.userIdSelector(state),
    userId: user_1.userUUIIdSelector(state),
    paymentsFeature: features_1.paymentsFeatureSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getAvailableModulesWithPurchase: redux_1.bindActionCreators(store_1.getAvailableModulesWithPurchaseActionCreator, dispatch),
    setActiveModule: redux_1.bindActionCreators(store_1.setActiveModuleActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme, core_1.withNavigateTo);
exports.default = hoc(AvailableModules);