"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var AuthServiceAbstract =
/** @class */
function () {
  function AuthServiceAbstract() {}

  return AuthServiceAbstract;
}();

exports.AuthServiceAbstract = AuthServiceAbstract;