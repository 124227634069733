import {IWithScreenProps} from '@dynamynd/core';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {SceneView} from '@react-navigation/core';
import * as React from 'react';
import {NavigationDescriptor, NavigationParams, NavigationScreenProp, NavigationState} from 'react-navigation';

interface IAppViewProps extends IWithScreenProps {
    navigation: NavigationScreenProp<NavigationState, NavigationParams>;
    descriptors: NavigationDescriptor[];
}

let firstRender = true;

export function appView(Layout: React.ComponentType<any>) {
    return class AppView extends React.Component<IAppViewProps> {
        private setDescriptor(): NavigationDescriptor {
            let descriptor: NavigationDescriptor;
            const {descriptors, navigation} = this.props;
            const activeKey = navigation.state.routes[navigation.state.index].key;

            /*
             * redirect to error page if for some reason there is no active key
             */
            if (!activeKey) {
                descriptor = descriptors[RoutesKeysEnum.error];
                return descriptor;
            }

            /*
             * If this is the first render and the route exists, redirect through bootstrap
             */
            if (firstRender) {
                descriptor = descriptors[RoutesKeysEnum.bootstrap];
                firstRender = false;
                return descriptor;
            }

            descriptor = descriptors[activeKey];

            return descriptor;
        }

        render() {
            const {screenProps} = this.props;
            const descriptor = this.setDescriptor();
            return (
                <Layout>
                    <SceneView
                        navigation={descriptor.navigation}
                        component={descriptor.getComponent()}
                        screenProps={screenProps}
                    />
                </Layout>
            );
        }
    };
}
