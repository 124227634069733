"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var routes_1 = require("@dynamynd/routes");

var user_1 = require("@dynamynd/user");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var services_1 = require("../../services");

var store_1 = require("../../store");

var components_1 = require("./components");

var PurchaseModuleScreen =
/** @class */
function (_super) {
  __extends(PurchaseModuleScreen, _super);

  function PurchaseModuleScreen() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.getModuleId = function () {
      var navigation = _this.props.navigation;
      return navigation.getParam(services_1.ModulesService.moduleParam);
    };

    return _this;
  }

  PurchaseModuleScreen.prototype.componentDidMount = function () {
    var _a = this.props,
        purchasingContactInfo = _a.purchasingContactInfo,
        getPurchasingContactInfo = _a.getPurchasingContactInfo;

    if (!purchasingContactInfo) {
      getPurchasingContactInfo();
    }
  };

  PurchaseModuleScreen.prototype.render = function () {
    var _a = this.props,
        _b = _a.screenProps.components,
        WebViewComponent = _b.WebViewComponent,
        IFrameComponent = _b.IFrameComponent,
        navigateTo = _a.navigateTo,
        purchasingModulesViewState = _a.purchasingModulesViewState;
    var moduleId = this.getModuleId();

    if (!moduleId) {
      navigateTo(routes_1.RoutesKeysEnum.error, {});
    }

    return react_1.default.createElement(content_1.WithAdditionalContent, {
      WebViewComponent: WebViewComponent,
      IFrameComponent: IFrameComponent,
      includeFooter: true
    }, function (_a) {
      var showContent = _a.showContent;
      return react_1.default.createElement(components_lib_1.BackgroundWithLeftFloatingImage, null, react_1.default.createElement(components_lib_1.WithTouchDisabled, {
        disabled: purchasingModulesViewState
      }, react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
        color: "purple",
        version: {
          sm: 'image',
          lg: 'image'
        }
      }), react_1.default.createElement(user_1.UserProfileBox, {
        showPrivacy: function showPrivacy() {
          return showContent(content_1.ContentPagesEnum.privacy);
        },
        showTerms: function showTerms() {
          return showContent(content_1.ContentPagesEnum.terms);
        }
      })), react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_1.PurchaseMethodPicker, {
        moduleId: moduleId,
        showPaymentModal: showContent
      })))));
    });
  };

  return PurchaseModuleScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    purchasingModulesViewState: store_1.purchasingModulesViewStateSelector(state),
    purchasingContactInfo: store_1.getPurchasingContactInfoSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getPurchasingContactInfo: redux_1.bindActionCreators(store_1.getPurchasingContactInfoActionCreator, dispatch)
  };
};

var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
exports.default = hoc(PurchaseModuleScreen);