"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SurveyTypeEnum;

(function (SurveyTypeEnum) {
  SurveyTypeEnum["mostLeast"] = "Most - Least";
  SurveyTypeEnum["scale"] = "Scale - Tablular\"";
})(SurveyTypeEnum = exports.SurveyTypeEnum || (exports.SurveyTypeEnum = {}));