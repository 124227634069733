import {
    ILoginProps,
    LoginScreen,
    PasswordResetScreen,
    RegistrationScreen,
    TPasswordResetScreenProps
} from '@dynamynd/authentication';
import {withRedirect} from '@dynamynd/core';
import {AuthLayout, shouldRedirectAuth} from '@dynamynd/dynamynd';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';

const EnhancedLoginScreen = compose(
    withAnalyticsTrackerWeb,
    withRedirect<ILoginProps>(shouldRedirectAuth, RoutesKeysEnum.home)
)(LoginScreen);
const EnhancedRegistrationScreen = compose(
    withAnalyticsTrackerWeb,
    withRedirect<any>(shouldRedirectAuth, RoutesKeysEnum.home)
)(RegistrationScreen);
const EnhancedPasswordResetScreen = compose(
    withAnalyticsTrackerWeb,
    withRedirect<TPasswordResetScreenProps>(shouldRedirectAuth, RoutesKeysEnum.home)
)(PasswordResetScreen);

export const authNavigator = createNavigator(
    appView(AuthLayout),
    SwitchRouter({
        [RoutesKeysEnum.login]: EnhancedLoginScreen,
        [RoutesKeysEnum.signup]: EnhancedRegistrationScreen,
        [RoutesKeysEnum.passwordReset]: EnhancedPasswordResetScreen
    }),
    {}
);
