"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

exports.surveyResultsErrorStateEpic = function (actions$, state$) {
  return actions$.pipe(redux_observable_1.ofType(modules_1.ModulesActionTypes.GET_MODULE_DETAILS), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return surveyResultsErrorStateEpicHandler(payload, state$);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var surveyResultsErrorStateEpicHandler = function surveyResultsErrorStateEpicHandler(payload, state$) {
  var resultsModule = state$.value.modules.resultsModule;

  if (resultsModule === payload) {
    return rxjs_1.of(actions_1.updateSurveyResultsErrorStateActionCreator(core_1.ErrorStateEnum.noError));
  }

  return rxjs_1.empty();
};