"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var normalizr_1 = require("normalizr");

var actions_1 = require("../actions");

var state_1 = require("../state"); // tslint:disable prefer-array-literal


var instructionsEntity = new normalizr_1.schema.Entity('instructions', {}, {
  idAttribute: 'id'
});
var instructionsSchema = new normalizr_1.schema.Array(instructionsEntity);
var avatarsEntity = new normalizr_1.schema.Entity('avatars', {}, {
  idAttribute: 'id'
});
var avatarsSchema = new normalizr_1.schema.Array(avatarsEntity);

exports.contentReducer = function (state, action) {
  if (state === void 0) {
    state = state_1.initialContentState;
  }

  if (!action) return state;

  switch (action.type) {
    case actions_1.ContentActionTypes.DYNAMYND_SET_INSTRUCTIONS:
      return setInstructionsHandler(state, action);

    case actions_1.ContentActionTypes.DYNAMYND_SET_PAGE_CONTENT:
      return setPagesContentHandler(state, action);

    case actions_1.ContentActionTypes.DYNAMYND_SET_AVATARS:
      return setAvatarsHandler(state, action);

    case actions_1.ContentActionTypes.SET_CONTENT_FOR_SHARING:
      return setContentForSharingHandler(state, action);

    default:
      return state;
  }
}; /////


function setInstructionsHandler(state, action) {
  var instructions = action.payload.instructions;
  if (!instructions.length) return state;
  var instructionsNormalized = normalizr_1.normalize(instructions, instructionsSchema);
  return __assign(__assign({}, state), {
    instructions: instructionsNormalized
  });
}

function setPagesContentHandler(state, action) {
  var _a;

  var _b = action.payload,
      key = _b.key,
      content = _b.content;
  return __assign(__assign({}, state), {
    pages: __assign(__assign({}, state.pages), {
      content: __assign(__assign({}, state.pages.content), (_a = {}, _a[key] = content, _a))
    })
  });
}

function setAvatarsHandler(state, action) {
  var payload = action.payload;
  var avatarsNormalized = normalizr_1.normalize(payload, avatarsSchema);
  return __assign(__assign({}, state), {
    avatars: avatarsNormalized
  });
}

function setContentForSharingHandler(state, action) {
  return __assign(__assign({}, state), {
    sharing: __assign({}, action.payload)
  });
}