"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

__export(require("./UserProfileEditForm/FieldLabels.enum"));

__export(require("./UserProfileEditForm/FieldNames.enum"));

var UserProfileEditForm_1 = require("./UserProfileEditForm/UserProfileEditForm");

exports.UserProfileEditForm = UserProfileEditForm_1.default;