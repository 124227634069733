"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var routes_1 = require("@dynamynd/routes");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions"); // tslint:disable prefer-array-literal


exports.getSharedModuleDataEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.GET_SHARED_MODULE_DATA), operators_1.map(function (action) {
    return action.payload;
  }), operators_1.mergeMap(function (payload) {
    return getSharedModuleDataEpicHandler(payload, modulesApiService);
  }), operators_1.catchError(getSharedModuleDataErrorHandler));
};

var getSharedModuleDataEpicHandler = function getSharedModuleDataEpicHandler(payload, modulesApiService) {
  var moduleId = payload.moduleId,
      personId = payload.personId;
  return modulesApiService.getSharedModuleData(moduleId, personId).pipe(operators_1.flatMap(function (response) {
    var data = response.data,
        success = response.success;

    if (!success) {
      return rxjs_1.of(core_1.navigateActionCreator(routes_1.RoutesKeysEnum.error, {}));
    }

    return rxjs_1.of(actions_1.setSharedModuleDataActionCreator(__assign(__assign({}, data), {
      moduleId: moduleId,
      personId: personId
    })));
  }), operators_1.catchError(getSharedModuleDataErrorHandler));
};

function getSharedModuleDataErrorHandler(error) {
  var message = error.message;

  if (/Module Locked/.test(message)) {
    return rxjs_1.of(actions_1.setSharedModuleErrorStateActionCreator({
      error: true
    }));
  }

  return __spreadArrays([core_1.navigateActionCreator(routes_1.RoutesKeysEnum.error, {})], core_1.defaultErrorHandler(error));
}