"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var redux_observable_1 = require("redux-observable");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var actions_1 = require("../actions");

exports.getPurchasingContactInfoEpic = function (actions$, state$, _a) {
  var modulesApiService = _a.modulesApiService;
  return actions$.pipe(redux_observable_1.ofType(actions_1.ModulesActionTypes.GET_PURCHASING_CONTACT_INFO), operators_1.mergeMap(function () {
    return getPurchaseContactInfoEpicHandler(actions$, modulesApiService);
  }), operators_1.catchError(core_1.defaultErrorHandler));
};

var getPurchaseContactInfoEpicHandler = function getPurchaseContactInfoEpicHandler(actions$, modulesApiService) {
  return modulesApiService.getPurchasingContactInfo().pipe(operators_1.mergeMap(function (response) {
    return rxjs_1.of(actions_1.setPurchasingContactInfoActionCreator(response.data.content));
  }), operators_1.takeUntil(actions$.pipe(redux_observable_1.ofType(core_1.ActionTypes.DYNAMYND_CANCEL_PENDING_AUTH_REQUESTS))), operators_1.catchError(core_1.defaultErrorHandler));
};