"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var getAvailableModules_epic_1 = require("./getAvailableModules.epic");

var getAvailableModulesWithPurchase_epic_1 = require("./getAvailableModulesWithPurchase.epic");

var getModuleDetails_epic_1 = require("./getModuleDetails.epic");

var getPurchasingContactInfo_epic_1 = require("./getPurchasingContactInfo.epic");

var getSharedModuleData_epic_1 = require("./getSharedModuleData.epic");

var unlockModuleWithAccessCode_epic_1 = require("./unlockModuleWithAccessCode.epic");

exports.modulesEpic = redux_observable_1.combineEpics(getAvailableModules_epic_1.getAvailableModulesEpic, getAvailableModulesWithPurchase_epic_1.getAvailableModulesWithPurchaseEpic, getModuleDetails_epic_1.getModuleDetailsEpic, unlockModuleWithAccessCode_epic_1.unlockModuleWithAccessCodeEpic, getPurchasingContactInfo_epic_1.getPurchasingContactInfoEpic, getSharedModuleData_epic_1.getSharedModuleDataEpic);